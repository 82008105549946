import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class GridService {
	sortColumnSubject$ = new BehaviorSubject<{ headerName: string; sortOrder: string }>({
		headerName: '',
		sortOrder: ''
	});
	public isExpanded = new BehaviorSubject<boolean>(true);
	updateSortColumn(columnConfig) {
		this.sortColumnSubject$.next(columnConfig);
	}
	treeExpendedStatus(isExpandStatus) {
		this.isExpanded.next(isExpandStatus);
	}
	expandOrCollapse(params): void {
		if (params.node.data.group) {
			params.api.getRowNode(params.node.id).setExpanded(!params.node.expanded);
			this.isExpanded.next(params.node.expanded);
		}
	}

	onSortChanged(params) {
		let sortColumn = params?.columns[params?.columns?.length - 1];
		let sortColumnConfig = {
			headerName: sortColumn.colDef?.headerName,
			sortOrder: sortColumn.sort === undefined ? 'asc' : sortColumn.sort
		};
		this.updateSortColumn(sortColumnConfig);
	}

	resetColumnConfig() {
		this.sortColumnSubject$.next({ headerName: '', sortOrder: '' });
	}
}
