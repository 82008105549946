<div class="card p-3">
	<crf-ca-save-default
		[selectorType]="6"
		[configId]="configId"
		(menuSelector)="getMenuResponse($event)"
		[disableMenuOptions]="disableMenuOptions"
		[menu]="menu"
	></crf-ca-save-default>
	<niq-search-bar
		variant="expandable"
		size="medium"
		onWhite="true"
		placeholder="Search facts"
		class="searchbar"
		(inputValueChange)="search($event)"
		(clear)="clear($event)"
	>
	</niq-search-bar>
	<div class="d-flex justify-content-between p-3 prompt-header">
		<p class="mb-0">Fact name</p>
		<p class="mb-0">Selected</p>
	</div>
	<niq-accordion-group variant="single" size="medium" [expandedItem]="accordionExpandedItem">
		<niq-accordion
			size="medium"
			[isExpanded]="accordionExpandedItem === index"
			expandIcon="chevron-up"
			collapseIcon="chevron-down"
			[border]="'none'"
			class="accordion mb-2"
			*ngFor="let factHeader of factHeaders; let index = index"
			(toggle)="toggle(index)"
		>
			<div accordion-actions class="d-flex justify-content-between">
				<niq-checkbox
					*ngIf="isHeaderMultiSelect && accordionExpandedItem == index"
					[disabled]="
						isHeaderMultiSelect &&
						accordionExpandedItem == index &&
						isValueMultiSelect &&
						factHeader.value === 'distribution'
					"
					(change)="checkUncheckAll(factHeader.value)"
					[checked]="isAllChecked[factHeader.value]"
					size="small"
				>
					<p>
						{{ factHeader.title }}
					</p>
				</niq-checkbox>
				<niq-radio-button
					*ngIf="
						!isHeaderMultiSelect && factHeader.value != 'others' && accordionExpandedItem == index
					"
					(select)="changeHeaderValue(factHeader.value)"
					[checked]="headerValue === factHeader.value"
					[disabled]="nonModifiableColumns.includes(factHeader.title)"
					size="small"
				>
					<p>{{ factHeader.title }}</p>
				</niq-radio-button>
				<p
					(click)="toggle(index)"
					*ngIf="
						(!isHeaderMultiSelect && factHeader.value == 'others') || accordionExpandedItem != index
					"
				>
					{{ factHeader.title }}
				</p>
				<span class="ml-1 m1-1" *ngIf="factHeader.value === 'distribution' && isValueMultiSelect">
					<niq-icon
						title="Distribution facts cannot be selected if multiple CMAs are selected"
						state="primary"
						size="small"
						iconName="info"
						style="margin-top: 0.5rem"
						colorTheme="light"
					></niq-icon>
				</span>
				<p style="position: absolute; right: 50px" (click)="toggle(index)">
					{{ getCheckedCount(factHeader.value) }} of {{ getTotalCount(factHeader.value) }}
				</p>
			</div>
			<div accordion-content class="accordion-content-container p-3">
				<niq-checkbox-group orientation="vertical">
					<niq-checkbox
						*ngFor="let fact of factData | filter: searchText:'searchString'"
						[hidden]="!isVisibile(factHeader.value, fact)"
						(change)="checkUncheckFact(factHeader.value, fact)"
						[checked]="isChecked(factHeader.value, fact)"
						[disabled]="isDisabled(factHeader.value, fact)"
						size="small"
					>
						<span
							*ngIf="factHeader.value === 'dollar' && configId !== 13"
							[innerHTML]="searchText ? fact.highlightedDollars : fact.nameDollars"
						></span>
						<span
							*ngIf="factHeader.value === 'units' && configId !== 13"
							[innerHTML]="searchText ? fact.highlightedUnits : fact.nameUnits"
						></span>
						<span
							*ngIf="factHeader.value === 'EQ' && configId !== 13"
							[innerHTML]="searchText ? fact.highlightedEQ : fact.nameEQ"
						></span>
						<span
							*ngIf="factHeader.value === 'others' && configId !== 13"
							[innerHTML]="searchText ? fact.highlightedOthers : fact.nameOthers"
						></span>
						<span
							*ngIf="factHeader.value === 'distribution' && configId !== 13"
							[innerHTML]="searchText ? fact.highlightedDistribution : fact.nameDistribution"
						></span>

						<span
							*ngIf="configId === 13"
							[innerHTML]="searchText ? fact.highlightedValue : fact.value"
						></span>
					</niq-checkbox>
				</niq-checkbox-group>
			</div>
		</niq-accordion>
	</niq-accordion-group>
	<!--Footer-->
	<div class="button-container py-3 d-flex justify-content-between align-items-center">
		<niq-button variant="ghost" size="small" (click)="clearSelection()"> Clear all </niq-button>
		<div>
			<niq-button variant="ghost" size="medium" class="mr-2" (click)="cancel()">
				Cancel
			</niq-button>
			<niq-button variant="primary" size="medium" (click)="apply()"> Apply </niq-button>
		</div>
	</div>
</div>
