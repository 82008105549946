import { AfterViewInit, Component, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import * as _ from 'lodash';
import { selectorConfigurations } from 'src/app/shared/constants/header-configuration/trended-weekly.constant';
import { SelectorConfig } from 'src/app/shared/model/report/report-header.type';
import { HeaderComponent } from 'src/app/shared/components/report/header/header.component';
import { FooterComponent } from 'src/app/shared/components/report/footer/footer.component';
import { TrendedWeeklyService } from './service/trended-weekly.service';
import { TrendedWeeklyUtility } from './service/trended-weekly.utility';
import { Subscription } from 'rxjs';
import { ReportFooterContent } from 'src/app/models/report.model';
import { SELECTOR } from 'src/app/shared/constants/selector.constant';
import { DoubleSliderComponent } from '@nielseniq/athena-core';
import { EXPORT_TYPE } from 'src/app/constants/report.constant';
import { UtilityService } from 'src/app/reports/services/utility.service';
import { ReportService } from 'src/app/service/report.service';

@Component({
	selector: 'crf-ca-trended-weekly-comparision-page',
	templateUrl: './trended-weekly-comparision-page.component.html',
	styleUrl: './trended-weekly-comparision-page.component.scss'
})
export class TrendedWeeklyComparisionPageComponent implements AfterViewInit {
	@ViewChild(HeaderComponent)
	headerComponent: HeaderComponent;
	@ViewChild(FooterComponent) footerComponent: FooterComponent;

	@ViewChild(DoubleSliderComponent) slider: DoubleSliderComponent;

	displaySpinner: boolean = false;
	selectorValues: any;
	selectedProducts: any;
	questionId = 11;
	headerComponentSelectorConfiguration: SelectorConfig[] = selectorConfigurations;

	data = {
		TrendedWeeklysSales: []
	};

	ui = {
		periodEndDate: ''
	};

	sliderWidth = 900;

	constructor(
		private service: TrendedWeeklyService,
		private utility: TrendedWeeklyUtility,
		private exportService: UtilityService,
		private reportService: ReportService
	) {}

	ngAfterViewInit(): void {
		//Load header section
		this.headerComponent?.loadProgress$.subscribe({
			complete: () => {
				//console.log('Completed the initialization of the header');
				//Load main section
				this.loadReportData();

				//Load footer section
				this.loadFooter();
			}
		});

		let payload: any = this.utility.getReportPayload(this.headerComponent?.getSelectorValues());
		let subscription2: Subscription = this.service?.getPeriodEndDate('52', payload)?.subscribe({
			next: (res: any) => {
				if (res.responseData) this.ui.periodEndDate = res.responseData.periodEndDate;
			},
			error: err => console.log(err),
			complete: () => console.log('completed')
		});
	}

	loadReportData(): void {
		this.displaySpinner = true;
		d3.select(this.TREND_SVG).remove();
		d3.select('#legends').remove();

		//NEED TO BIND PAYLOAD FROM HEADER
		let payload: any = this.utility.getReportPayload(this.headerComponent?.getSelectorValues());
		let subscription: Subscription;
		subscription = this.service.getReportData(payload)?.subscribe({
			next: (res: any) => {
				this.data = res.responseData;
				this.init();
			},
			error: err => {
				//dispatch error message
				this.displaySpinner = false;
				subscription?.unsubscribe();
			},
			complete: () => {
				this.displaySpinner = false;
				subscription?.unsubscribe();
			}
		});
	}

	loadFooter() {
		let filterContent = this.utility.getFooterItems(this.headerComponent.getSelectorValues());
		filterContent.push({
			filter: 'Period End Date',
			value: '52 Individual Weeks Ending ' + this.ui.periodEndDate
		});
		this.selectorValues = this.headerComponent.getSelectorValues();
		this.selectedProducts = filterContent.filter(item => item.filter === 'Product')[0].value;

		let reportFilterContent: ReportFooterContent = {
			dashboard: 'Trended Weekly Comparison (Scan)',
			filters: filterContent,
			note: ''
		};

		if (this.footerComponent) this.footerComponent.footerContent = reportFilterContent;
	}

	setSelectedProduct(selectedProduct) {
		return this.reportService.setSelectedProduct(selectedProduct, true);
	}

	setMarketArray(selectedMarket) {
		return this.reportService.setMarketArray(selectedMarket);
	}

	selectorChangeHandler(event) {
		if (event == SELECTOR.FACT.ID) this.init();
		else this.loadReportData();

		this.loadFooter();
	}

	exportReport(event: any) {
		let periods = this.headerComponent?.getDropdownItems('period');

		let payload = this.utility.getTrendedWeeklyExportReportPayload(
			this.headerComponent?.getSelectorValues(),
			periods?.find(period => period.key == 'Latest 52 Weeks'),
			this.minRangeSlider,
			this.chartBounds,
			this.filteredFactToShow
		);

		let type: 'excel' | 'ppt' = 'excel';
		let exportType = EXPORT_TYPE.EXCEL;
		if (event === 'ppt') {
			type = 'ppt';
			exportType = EXPORT_TYPE.PPT;
		}

		this.exportService.exportFile(payload, type);
	}

	chartData: any = {};
	dataArray = [];
	factSelected: any[] = [];
	dollarUnits = { value: 'units' };
	TREND_SVG = '#Trendedsvg';
	TXT_ANCH = 'text-anchor';
	STR_WIDTH = 'stroke-width';
	filteredFactToShow = [];
	minRangeSlider = { minValue: 1, maxValue: 52 };
	chartBounds = { min: null, max: null, diff: null };

	rangeChange(event) {
		this.minRangeSlider.minValue = this.slider?.leftValue;
		this.minRangeSlider.maxValue = this.slider?.rightValue;

		this.init();
	}

	init() {
		let selectorValues = this.headerComponent?.getSelectorValues();
		this.dollarUnits.value = selectorValues?.fact?.factType[0]?.toLowerCase();
		this.factSelected = [];

		selectorValues?.fact.facts.forEach(fact => {
			if (fact.valuebase === this.dollarUnits?.value) {
				this.factSelected.push(fact);
			}
		});

		this.filteredFactToShow = structuredClone(this.factSelected).filter(e => {
			return e.valuebase === this.dollarUnits?.value;
		});

		this.getTrendedSharesData();
	}

	getTrendedSharesData() {
		d3.select(this.TREND_SVG).remove();
		d3.select('#legends').remove();

		const originalDataCopy = structuredClone(this.data);
		const weekTrimmedData = originalDataCopy.TrendedWeeklysSales.filter(e => {
			return e.weeks >= this.minRangeSlider.minValue && e.weeks <= this.minRangeSlider.maxValue;
		});

		if (weekTrimmedData) {
			this.chartData = { TrendedWeeklysSales: weekTrimmedData };
			this.creatChart();
			return this.chartData;
		}
		return null;
	}

	creatChart() {
		let dataArray = [];
		this.chartData?.TrendedWeeklysSales?.sort((data1, data2) => data2.weeks - data1.weeks);
		_.forEach(this.chartData, (item, itemindex) => {
			const dataObj = [];
			_.forEach(this.filteredFactToShow, (fact, findex) => {
				_.forEach(item, (mappedItem, index) => {
					if (
						mappedItem.salesFactMapping &&
						mappedItem.salesFactMapping.length &&
						mappedItem.weekString === item[index].weekString &&
						fact.valuebase === this.dollarUnits.value
					) {
						const coordinate = {
							x: '',
							y: '',
							z: '',
							cc: '',
							weekString: '',
							weekStringYA: '',
							weekStringTwoYA: '',
							weeks: '',
							color: '',
							unitDesc: '',
							lineId: ''
						};
						coordinate.x = mappedItem.weeks;
						coordinate.y = mappedItem.salesFactMapping[0][fact.valuefromapi];
						//coordinate.z = fact.key;
						coordinate.z = fact.gkey;
						coordinate.cc = fact.colorcode;
						coordinate.weekString = mappedItem.weekString;
						coordinate.weekStringYA = mappedItem.weekStringYA;
						coordinate.weekStringTwoYA = mappedItem.weekStringTwoYA;
						coordinate.weeks = mappedItem.weeks;
						coordinate.color = fact.color;
						coordinate.unitDesc = fact.unitDesc;

						dataObj.push(coordinate);
					}
				});
			});

			//});
			if (dataObj.length) {
				const allOtherArr = JSON.parse(JSON.stringify(dataObj));
				if (dataObj[0].z === 'ALL OTHER') {
					_.remove(allOtherArr, function (item1) {
						return parseFloat(item1.y) === 0;
					});
				}

				const obj1 = {
					showTrendedLines: true,
					arrData: dataObj
				};
				if (dataObj.length > 0) {
					dataArray.push(obj1);
				}
			} else {
				const obj = {
					showTrendedLines: true,
					arrData: dataObj
				};
				dataArray.push(obj);
			}
		});
		this.dataArray = dataArray;
		this.drawGraph(dataArray, true);
	}

	checkColor(value) {
		value = parseFloat(value);
		if (value < 0) {
			return 'font-red-value';
		} else if (value > 0) {
			return 'font-green-value';
		} else {
			return 'zero-text';
		}
	}

	drawGraph(trendedChartArr, legendCreation) {
		d3.select(this.TREND_SVG).remove();

		if (legendCreation) {
			d3.select('#legends').remove();
		}

		const svgWidth: number = document.getElementsByClassName('trended-weekly-dashboard')[0]
			?.clientWidth;
		const margin = { top: 20, right: 150, bottom: 120, left: 100, legend: 12 },
			tempWidth = 65,
			width =
				svgWidth -
				margin.left -
				margin.right -
				(svgWidth > 1550
					? svgWidth > 1900
						? svgWidth > 2250
							? tempWidth * 4
							: tempWidth * 3 + 10
						: tempWidth * 2 + 10
					: tempWidth), //#255
			height = 485 - margin.top - margin.bottom;
		const chartWidth = width - (margin.left + margin.right);

		this.sliderWidth = width + 130;

		//--X -- Axis
		const tooltip = d3.select('body').append('div').attr('class', 'line-chart-toolTip');
		const x = d3.scaleBand().range([0, width - margin.right], 0.1);
		const xAxis = d3.axisBottom(x);
		//--Y -- Axis
		const y = d3.scaleLinear().range([height, 0]);
		//Find Max
		let yValues = [];
		let yMin = 0;
		let yMax = 0;

		let getYminYmax = dataArray1 => {
			yValues = [];
			_.forEach(dataArray1, dataItem => {
				if (dataItem.showTrendedLines) {
					_.forEach(dataItem.arrData, item => {
						this.factSelected.map(t => {
							//t.key
							if (t.gkey === item.z && t.valuebase === this.dollarUnits.value) {
								yValues.push(parseFloat(item.y));
							}
						});
					});
				}
			});
			yMin = d3.min(yValues);
			yMax = d3.max(yValues);
			y.domain([yMin, yMax]).nice();
		};

		let findNewYminYmax = () => {
			let ticks = y.ticks(),
				lastTick = ticks[ticks.length - 1],
				firstTick = ticks[0],
				diff = ticks[1] - ticks[0],
				newLastTick = lastTick < yMax ? yMax + diff / 2 : lastTick + diff / 2,
				newFirstTick = firstTick > yMin ? yMin - diff / 2 : firstTick - diff / 2;
			if (!ticks.length) {
				newLastTick = 10;
				newFirstTick = 0;
			}
			return {
				newFirstTick: newFirstTick,
				newLastTick: newLastTick
			};
		};

		let getYAxisMinMaxValues = () => {
			const ticks = y.ticks();
			const diff = ticks[1] - ticks[0];
			this.chartBounds.min = ticks[0];
			this.chartBounds.max = ticks[ticks.length - 1];
			this.chartBounds.diff = diff;
		};

		getYminYmax(this.dataArray);
		const yminYmax = findNewYminYmax();
		let factUnitDesc;
		y.domain([yminYmax.newFirstTick, yminYmax.newLastTick]).nice(); //<-- trended adjust domain for further value
		const yAxis = d3
			.axisLeft(y)
			.ticks(Math.round(y.ticks().length) / 2)
			.tickFormat(d => {
				if (this.dollarUnits.value === 'dollar') {
					factUnitDesc = this.factSelected[0]?.unitDesc;
				} else {
					factUnitDesc = this.factSelected[1]?.unitDesc;
				}
				let temp1 = this.unitsConvertion(d).toString();
				temp1 = d < 0 ? '-' + temp1 : temp1;
				temp1 =
					temp1.indexOf('-') !== -1
						? '-' + factUnitDesc?.replace('value', temp1.replace('-', ''))
						: factUnitDesc?.replace('value', temp1);

				return temp1;
			})
			.tickSizeInner(-chartWidth)
			.tickPadding(10)
			.tickSizeOuter(0);

		getYAxisMinMaxValues();

		d3.select('.trended-weekly-dashboard')
			.append('div')
			.attr('id', 'trendedWeeklyChart')
			.attr('width', '100%')
			.attr('height', '500px');

		const svg = d3
			.select('#trendedWeeklyChart')
			.append('svg')
			.attr('id', 'Trendedsvg')
			.attr('width', width + margin.left + margin.right + 30)
			.attr('height', height + margin.top + margin.bottom)
			.style('margin-top', '0')
			.style('padding-left', '1px')
			.style('padding-top', '10px')
			.style('padding-right', '0px')
			.append('g')
			.attr('transform', 'translate(' + (margin.left + 40) + ',' + margin.top + ')');

		/*x.domain(chartData.TrendedWeeklysSales.map(function (d) {
			return d.weekString;
		})).paddingInner(10).paddingOuter(0.5);*/
		x.domain(
			this.chartData?.TrendedWeeklysSales?.map(d => {
				return d.weekString;
			})
		)
			.paddingInner(10)
			.paddingOuter(0.5);
		//str: d3.AxisScale<d3.AxisDomain>;
		//  var str d3.AxisScale;

		//X axis line
		svg
			.append('g')
			.attr('class', 'trended-x_axis')
			.attr('transform', 'translate(0,' + height + ')')
			.call(xAxis)
			.selectAll('text')
			.style(this.TXT_ANCH, 'end')
			.attr('dx', '-.8em')
			.attr('dy', '.15em')
			.attr('font-size', '11px')
			.attr('transform', 'rotate(-60)');

		svg
			.append('g')
			.attr('class', 'y_axis')
			.attr('transform', 'translate(0,0)')
			.call(yAxis)
			.append('text')
			.attr('class', 'trended-weekly-label')
			.attr('transform', 'rotate(-90),translate(' + -105 + ',' + -80 + ')')
			.attr('fill', 'currentColor')
			// .attr("y", 6)
			// .attr("dy", ".71em")
			.style(this.TXT_ANCH, 'end')
			.attr('font-size', '14px')
			.text(
				(this.dollarUnits.value == 'dollar'
					? '$'
					: this.dollarUnits.value == 'eq'
					? 'EQ'
					: 'Units') +
					' ' +
					this.factSelected[0]?.factGroup
			); // Y-Axis Label

		let centerLine = 5;
		const _marginVal = 0;
		let _ALL_POSITIVE = true;
		let _CENTER_TICK_VAL = 0;
		let formatTicks = () => {
			d3.selectAll('.y_axis g.tick text').each(function (d, i) {
				if (d === -_marginVal || d === _marginVal) {
					centerLine = d;
				}
				_ALL_POSITIVE = d >= _marginVal && _ALL_POSITIVE ? true : false;

				if (i === 0) {
					_CENTER_TICK_VAL = d;
				}
			});
			d3.selectAll('.grid .tick line').attr('class', function (d, i) {
				centerLine = _ALL_POSITIVE ? 0 : centerLine;
				return d === centerLine ? 'trended_center_grid_line' : 'line_color';
			});
		};
		formatTicks();

		const line = [];
		const legendID = [
			'line1',
			'line2',
			'line3',
			'line4',
			'line5',
			'line6',
			'line7',
			'line8',
			'line9',
			'line10',
			'line11',
			'line12'
		];

		let factClicked;
		let createLegend = (
			legendColor,
			lineId,
			legendText,
			index,
			xaxisposition,
			yaxisposition,
			legendLength
		) => {
			let objectRef = this;
			const legendGroup = svg
				.append('g')
				.attr('id', lineId)
				.attr('d', legendText)
				.attr('transform', 'translate(' + (width - 85) + ',' + index * 20 + ')')
				.style('cursor', 'pointer')
				.style('opacity', '1')
				.on('click', function (d, i) {
					/*****************Approach 2******************/
					const text = this.childNodes[1].getAttribute('value');
					_.filter(structuredClone(objectRef.factSelected), fact => {
						if (fact.gkey === text && fact.valuebase === objectRef.dollarUnits.value) {
							factClicked = fact;
						}
						return fact.gkey === text;
					});

					const isFactItemExists = objectRef.filteredFactToShow.find(e => {
						return e.gkey === text && e.valuebase === objectRef.dollarUnits.value;
					});

					if (isFactItemExists) {
						//if item exists then remove
						objectRef.filteredFactToShow = _.remove(objectRef.filteredFactToShow, o => {
							return o.gkey !== text && o.valuebase === objectRef.dollarUnits.value;
						});
					} else {
						//if item not exists then add
						objectRef.filteredFactToShow.push(factClicked);
					}
					objectRef.creatChart();
					objectRef.updateLegendsOpacity();
				});

			legendGroup
				.append('rect')
				.attr('width', '15')
				.attr('height', '15')
				.attr('stroke', legendColor)
				.attr('fill', legendColor)
				.attr(this.STR_WIDTH, 1)
				.style('opacity', 0)
				.transition()
				//.attr("transform", "translate(" + (margin.left + 28) + "," + (margin.top / 2) + ")")
				//.attr("transform", "translate(" + 80 + ",0)")
				// .attr("transform", "translate(" + width / (2 + legendLength) + ", 50)")
				.duration(600)
				.style('opacity', 1);
			legendGroup
				.append('text')
				.attr('text-anchor', 'left')
				.attr('transform', 'translate(' + (margin.left - 78) + ',' + margin.top / 2 + ')')
				//.attr("transform", "translate(" + (margin.left + 32) + "," + (margin.top / 2) + ")")
				//.attr("transform", "translate(" + (width / (2 + legendLength) + 20) + "," + ((margin.top / 2) + 50) + ")")
				.attr('value', legendText)
				.text(legendText);
		};

		let drawPoints = (pointData, pointColor, lineData, lineColor, lineId) => {
			// create points for line
			const group = svg.append('g').attr('class', lineId);
			group
				.append('svg:path')
				// .attr('d', lineSelection(lineData))
				.attr('class', 'line-path')
				.transition()
				.duration(1000)

				.on('start', function (d, i, n) {
					d3.active(n[i])
						//  .attrTween("d", pathTween(lineSelection(lineData), 4))
						.transition();
				})
				.attr('stroke', lineColor)
				.attr(this.STR_WIDTH, 3)
				.attr('fill', 'none');
			// return group;
			const points = group
				.selectAll('.points')

				.data(pointData)
				.enter()
				.append('svg:circle');
			points
				.style('cursor', 'pointer')
				.attr('stroke', pointColor)
				.transition()
				.duration(500)
				.delay(function (d, i) {
					return i * 10;
				})
				.attr('fill', function (d, i) {
					return pointColor;
				})
				.attr('cx', function (d, i) {
					return x(d.weekString);
				}) //weekString
				.attr('cy', function (d, i) {
					return y(d.y);
				})
				.attr('r', function (d, i) {
					return 3;
				});
			/************************tooltip start ***************** */

			points
				.on('mouseover', (d, index) => {
					d3.select(this).transition().duration(300).attr('r', 6);
					tooltip
						.style('left', d.pageX - 48 + 'px') //d3.event.pageX
						.style('top', d.pageY - 72 + 'px') //d3.event.pageY
						.style('display', 'inline-block')
						.html(() => {
							let data = d.target.__data__;
							let temp = structuredClone(data.y);
							let checkColorValue;

							if (data.color === 'hasColor') {
								checkColorValue = this.checkColor(temp);
							} else {
								checkColorValue = 'zero-text';
							}

							if (data.unitDesc === 'value' || data.unitDesc === '$value') {
								temp = this.getCommaSeperatedNumbers(temp, 0);
							} else if (data.unitDesc === 'value%') {
								temp = this.getCommaSeperatedNumbers(temp, 1);
							}

							//temp = temp.indexOf("-") !== -1 ? "-" + data.unitDesc.replace("value", temp.replace("-", "")) : data.unitDesc.replace("value", temp);

							const tooltipWeekString =
								data.z.indexOf('2YA') !== -1
									? data.weekStringTwoYA
									: data.z.indexOf('YA') !== -1
									? data.weekStringYA
									: data.weekString;
							return (
								'<b>' +
								data.z +
								" : </b><span class='arrow'></span>" +
								"<span class='" +
								checkColorValue +
								"'>" +
								temp +
								'</span>' +
								"</br><b>Week:</b><span class='arrow'></span>" +
								"<span class='" +
								"'>" +
								tooltipWeekString +
								'</span>'
							);
						});
				})
				.on('mouseout', d => {
					d3.select(this).transition().duration(300).attr('r', 3);
					tooltip.style('display', 'none');
				});

			/************************tooltip End ***************** */
		};

		const lineSelection = d3
			.line()
			.defined(function (d) {
				return d.y !== null;
			})
			//  .interpolate("basis")
			.x(function (d) {
				return x(d.weekString); //weekString
			})
			.y(function (d) {
				return y(d.y);
			});

		let drawLine = (lineData, lineColor, lineId) => {
			const group = svg.append('g').attr('class', lineId);
			group
				.append('svg:path')
				.attr('d', lineSelection(lineData))
				.attr('stroke', lineColor)
				.attr(this.STR_WIDTH, 3)
				.attr('fill', 'none');
			return group;
		};
		_.forEach(this.factSelected, (d, i) => {
			const tempLineData = _.filter(trendedChartArr[0]?.arrData, o => {
				return d.valuebase === this.dollarUnits.value && o.z === d.gkey; //this.dollarUnits.value
			});
			const tempWithoutNullData = _.remove(structuredClone(tempLineData), item => {
				return item.y !== null;
			});
			line[i] = drawLine(tempLineData, d.colorcode, legendID[i]);
			drawPoints(tempWithoutNullData, d.colorcode, line[i], d.color, legendID[i]);
		});
		if (legendCreation && this.factSelected.length) {
			let yVal = 0;
			_.forEach(this.factSelected, (d, i) => {
				const j = i;
				const xAxisP = j * 60,
					yAxisP = 600;
				if (this.dollarUnits.value === d.valuebase) {
					yVal = yVal + 2;
					d.lineId = legendID[i];
					createLegend(
						d.colorcode,
						legendID[i],
						d.gkey,
						yVal,
						xAxisP,
						yAxisP,
						this.factSelected.length
					);
				}
			});
		}
	}

	unitsConvertion(labelValue) {
		// Nine Zeroes for Billions
		return Math.abs(Number(labelValue)) >= 1.0e9
			? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + 'B'
			: // Six Zeroes for Millions
			Math.abs(Number(labelValue)) >= 1.0e6
			? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + 'M'
			: // Three Zeroes for Thousands
			Math.abs(Number(labelValue)) >= 1.0e3
			? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + 'K'
			: Math.abs(Number(labelValue));
	}

	updateLegendsOpacity() {
		setTimeout(() => {
			this.factSelected
				.filter(e => {
					return e.valuebase === this.dollarUnits.value;
				})
				.forEach(function (fact) {
					document.getElementById(fact?.lineId).style.opacity = '0.3';
				});
			this.filteredFactToShow.forEach(fact => {
				const lineId = this.factSelected.find(e => {
					return e.gkey === fact.gkey;
				})?.lineId;
				document.getElementById(lineId).style.opacity = '1';
			});
		});
	}

	getCommaSeperatedNumbers(value, decimal) {
		return Number(parseFloat(value).toFixed(decimal)).toLocaleString('en', {
			minimumFractionDigits: decimal || 0
		});
	}
}
