import { Injectable } from '@angular/core';
import { optedSelection } from '../../constants/dashboard-ui.constant';
import { BASE_FACT_LIST, PERIOD_LIST } from 'src/app/models/share-prompt.constant';

import * as _ from 'lodash';
import { UtilityService } from './utility.service';

@Injectable({
	providedIn: 'root'
})
export class ShareReportService {
	defaultSelectors: any;
	constructor(private utilityService: UtilityService) {}

	selectedProductOptedvaluesSet(obj) {
		let defaultOpt = optedSelection;
		let selectedData = [];

		/**Update response value to defaultobj */
		defaultOpt.filter(item => {
			let keyName = this.dashedToCamelCase(item.key);
			Object.entries(obj.responseData).map((element: any) => {
				if (
					keyName.toLowerCase() === element[0].toLowerCase() &&
					this.utilityService.isValid(element[1])
				) {
					item.value.push(element[1]);
					selectedData.push({
						displayName: item.key,
						displayValue: element[1],
						aliasName: item.alias_name
					});
				}
			});
		});
		return selectedData;
	}

	selectedProductOptedvaluesData(obj) {
		let defaultOpt = optedSelection;
		let selectedData = [];
		/**Update response value to defaultobj */
		defaultOpt.filter(item => {
			let keyName = this.dashedToCamelCase(item.key);
			Object.entries(obj.responseData).map((element: any) => {
				if (
					keyName.toLowerCase() === element[0].toLowerCase() &&
					this.utilityService.isValid(element[1])
				) {
					item.value.push(element[1]);
				}
			});
		});
		return defaultOpt;
	}

	dashedToCamelCase(str) {
		if (str !== 'SUBCATEGORY') {
			return str.toLowerCase().replace(/_(.)/g, function (match, chr) {
				return chr.toUpperCase();
			});
		} else {
			return 'subCategory';
		}
	}

	expandPeriodFact(periodList, factList) {
		let expandedBaseFacts = [];
		const PERIOD_PLACEHOLDER = '{period}';
		const GLOBAL_REG_EXP_MODIFIER = 'g';
		periodList.forEach(periods => {
			factList.map(fact => {
				let name = _.replace(
					fact,
					new RegExp(PERIOD_PLACEHOLDER, GLOBAL_REG_EXP_MODIFIER),
					periods.gkey
				);
				expandedBaseFacts.push(name);
			});
		});

		return expandedBaseFacts;
	}

	getFactData(factDataOnSelection) {
		let selectedFactName: any = factDataOnSelection.facts;
		let factData = BASE_FACT_LIST;
		factData.map(fact => {
			if (selectedFactName.indexOf(fact[0].name) > -1 || fact[0]?.isPinned)
				fact[0].isSelected = true;
			else fact[0].isSelected = false;

			if (selectedFactName.indexOf(fact[1].name) > -1 || fact[1]?.isPinned)
				fact[1].isSelected = true;
			else fact[1].isSelected = false;

			if (selectedFactName.indexOf(fact[2].name) > -1 || fact[2]?.isPinned)
				fact[2].isSelected = true;
			else fact[2].isSelected = false;
		});

		let factList = [];
		factData.map(fact => {
			let keyname = {
				value: fact[0].gkey,
				nameDollars: fact[0].name,
				nameUnits: fact[1].name,
				nameEQ: fact[2].name,
				isCheckedDollar: fact[0].isSelected,
				isCheckedUnit: fact[1].isSelected,
				isCheckedEQ: fact[2].isSelected,
				isDollarPinned: fact[0]?.isPinned,
				isUnitPinned: fact[1]?.isPinned,
				isEQPinned: fact[2]?.isPinned
			};
			factList.push(keyname);
		});

		//Take all coloumn as per period selected
		const PERIOD_PLACEHOLDER = '{period}';
		const GLOBAL_REG_EXP_MODIFIER = 'g';
		let finalFactData = [];

		factDataOnSelection.periods.forEach(selPeriod => {
			factList.map(selFact => {
				let nameDoller = _.replace(
					selFact.nameDollars,
					new RegExp(PERIOD_PLACEHOLDER, GLOBAL_REG_EXP_MODIFIER),
					selPeriod.gkey
				);
				let nameUnit = _.replace(
					selFact.nameUnits,
					new RegExp(PERIOD_PLACEHOLDER, GLOBAL_REG_EXP_MODIFIER),
					selPeriod.gkey
				);
				let nameEQ = _.replace(
					selFact.nameEQ,
					new RegExp(PERIOD_PLACEHOLDER, GLOBAL_REG_EXP_MODIFIER),
					selPeriod.gkey
				);

				let finalData = {
					value: selFact.value,
					nameDollars: nameDoller,
					nameUnits: nameUnit,
					nameEQ: nameEQ,
					isCheckedDollar: selFact.isCheckedDollar,
					isCheckedUnit: selFact.isCheckedUnit,
					isCheckedEQ: selFact.isCheckedEQ,
					isDollarPinned: selFact?.isDollarPinned,
					period: selPeriod.gkey
				};

				finalFactData.push(finalData);
			});
		});
		let result = { finalFactData: finalFactData };
		return result;
	}

	getPeriodData(data) {
		let selectedFactName: any = this.expandBaseFactList(data);

		let factData = BASE_FACT_LIST;
		factData.map(fact => {
			if (selectedFactName.indexOf(fact[0].name) > -1 || fact[0]?.isPinned)
				fact[0].isSelected = true;
			else fact[0].isSelected = false;

			if (selectedFactName.indexOf(fact[1].name) > -1 || fact[1]?.isPinned)
				fact[1].isSelected = true;
			else fact[1].isSelected = false;

			if (selectedFactName.indexOf(fact[2].name) > -1 || fact[2]?.isPinned)
				fact[2].isSelected = true;
			else fact[2].isSelected = false;
		});

		let factList = [];
		factData.map(fact => {
			let keyname = {
				value: fact[0].gkey,
				nameDollars: fact[0].name,
				nameUnits: fact[1].name,
				nameEQ: fact[2].name,
				isCheckedDollar: fact[0].isSelected,
				isCheckedUnit: fact[1].isSelected,
				isDollarPinned: fact[0]?.isPinned,
				isUnitPinned: fact[1]?.isPinned,
				isCheckedEQ: fact[2]?.isSelected,
				isEQPinned: fact[2]?.isPinned
			};
			factList.push(keyname);
		});

		//Take all coloumn as per period selected
		const PERIOD_PLACEHOLDER = '{period}';
		const GLOBAL_REG_EXP_MODIFIER = 'g';
		let finalFactData = [];
		data.periods.forEach(selPeriod => {
			factList.map(selFact => {
				let nameDoller = _.replace(
					selFact.nameDollars,
					new RegExp(PERIOD_PLACEHOLDER, GLOBAL_REG_EXP_MODIFIER),
					selPeriod.gkey
				);
				let nameUnit = _.replace(
					selFact.nameUnits,
					new RegExp(PERIOD_PLACEHOLDER, GLOBAL_REG_EXP_MODIFIER),
					selPeriod.gkey
				);

				let nameEQ = _.replace(
					selFact.nameEQ,
					new RegExp(PERIOD_PLACEHOLDER, GLOBAL_REG_EXP_MODIFIER),
					selPeriod.gkey
				);

				let finalData = {
					value: selFact.value,
					nameDollars: nameDoller,
					nameUnits: nameUnit,
					nameEQ: nameEQ,
					isCheckedDollar: selFact.isCheckedDollar,
					isCheckedUnit: selFact.isCheckedUnit,
					isCheckedEQ: selFact.isCheckedEQ,
					isDollarPinned: selFact?.isDollarPinned,
					period: selPeriod.gkey
				};

				finalFactData.push(finalData);
			});
		});
		let result = { factData: factData, finalFactData: finalFactData };
		return result;
	}

	expandBaseFactList(data) {
		const PERIOD_PLACEHOLDER = '{period}';
		const GLOBAL_REG_EXP_MODIFIER = 'g';

		let expandedBaseFacts = [];
		const periodList = PERIOD_LIST;
		const factList = BASE_FACT_LIST;
		let selectedFactName: any = this.utilityService.defaultSelectorSplit(data.facts);
		data.periods.forEach(element => {
			selectedFactName.forEach(name => {
				if (name.indexOf(element.gkey) > -1) {
					name = _.replace(
						name,
						new RegExp(`${element.gkey}`, GLOBAL_REG_EXP_MODIFIER),
						PERIOD_PLACEHOLDER
					);
					expandedBaseFacts.push(name);
				} else {
					name = '';
				}
			});
		});
		return expandedBaseFacts;
	}
}
