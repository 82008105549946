import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import WebMap from '@arcgis/core/WebMap';
import MapView from '@arcgis/core/views/MapView';
import Legend from '@arcgis/core/widgets/Legend';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import SimpleLineSymbol from '@arcgis/core/symbols/SimpleLineSymbol';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol.js';
import ClassBreaksRenderer from '@arcgis/core/renderers/ClassBreaksRenderer.js';
import Zoom from '@arcgis/core/widgets/Zoom.js';
import ZoomVM from '@arcgis/core/widgets/Zoom/ZoomViewModel.js';
import Print from '@arcgis/core/widgets/Print';
import Expand from '@arcgis/core/widgets/Expand';
import { MergeImageService } from './merge-image.service';
import html2canvas from 'html2canvas';

@Component({
	selector: 'crf-ca-gis-map',
	templateUrl: './gis-map.component.html',
	styleUrl: './gis-map.component.scss'
})
export class GisMapComponent implements OnInit, OnDestroy {
	public mapView!: MapView;
	public map!: WebMap;
	public legend!: Legend;
	public mapReady: boolean = false;

	//Based on this layer dynamic layer will be created
	public featureLayer: FeatureLayer = new FeatureLayer({
		title: 'Walmart CMA Market',
		url: 'https://services7.arcgis.com/qHAgwIxldkwnmCm4/arcgis/rest/services/WalmartCMA/FeatureServer/0',
		opacity: 0.9,
		outFields: ['*']
	});

	public updatedFeatureLayer!: FeatureLayer;

	@Output() gisClick = new EventEmitter();
	@Output() gisMouseEnter = new EventEmitter();

	constructor(private mergeImageService: MergeImageService) {}

	ngOnInit() {
		this.createMap();

		this.initFeatureLayer('Walmart CMA');
	}

	//Creates map and mapview
	createMap() {
		console.log('Create map');

		//Configuring Map
		const map = new WebMap({
			basemap: 'streets'
		});

		this.map = map;

		//Configuring Mapview
		this.mapView = new MapView({
			container: 'mapView',
			map: map,
			center: [-94.5785667, 39.0997265],
			zoom: 3,
			// Disable mouse-wheel and single-touch map navigation.
			navigation: {
				mouseWheelZoomEnabled: false,
				browserTouchPanEnabled: false
			},
			ui: {
				components: ['attribution']
			},
			popup: {
				defaultPopupTemplateEnabled: false,
				dockOptions: { buttonEnabled: false },
				visibleElements: {
					//actionBar: false,
					closeButton: false,
					collapseButton: false
				}
			},
			graphics: [{ symbol: new SimpleLineSymbol({ color: [255, 0, 0, 0.5] }) }]
		});

		//Configuring zoom functionality
		const zoom = new Zoom({
			view: this.mapView,
			layout: 'vertical',
			viewModel: new ZoomVM({
				view: this.mapView,
				canZoomIn: true,
				canZoomOut: true
			})
		});

		this.mapView.ui.add(zoom, 'top-left');
		return this.mapView;
	}

	//Create feature layer
	initFeatureLayer(title: string) {
		console.log('Create feature layer');

		let fields: any[] = [
			{ name: 'CMA', type: 'integer' },
			{ name: 'CMA_NAME', type: 'string' },
			{ name: 'FID', type: 'oid' },
			{ name: 'GlobalID', type: 'global-id' },
			{ name: 'Index', type: 'integer' }
		];

		//Query the feature layer
		this.featureLayer.queryFeatures().then(featureLayer => {
			let graphics = [];

			featureLayer.features.forEach(feature => {
				graphics.push(
					new Graphic({
						geometry: feature.geometry,
						attributes: {
							...feature.attributes
						},
						symbol: new SimpleFillSymbol({
							color: '#F7F8F750',
							style: 'solid',
							outline: { width: 0.2, color: '#000000A0' }
						})
					})
				);
			});

			//Create feature layer
			this.updatedFeatureLayer = new FeatureLayer({
				source: graphics,
				title: title,
				outFields: ['*'],
				objectIdField: 'GlobalID',
				opacity: 0.4,
				fields: fields,
				renderer: new ClassBreaksRenderer({
					field: 'Index',
					defaultSymbol: new SimpleFillSymbol({
						color: '#F7F8F7',
						style: 'solid'
					}),
					defaultLabel: 'Not available',
					classBreakInfos: []
				})
			});

			//Add legend
			this.createLegend();

			this.createPrintWidget();

			//Adding feature layer to map
			this.map.layers.add(this.updatedFeatureLayer);

			//Adding click and mouse-enter event
			this.addEventHandlers();
		});
	}

	//Create legend
	createLegend() {
		console.log('Create legend');

		const legend = new Legend({
			view: this.mapView,
			container: 'infoView',
			layerInfos: [{ layer: this.updatedFeatureLayer }],
			hideLayersNotInCurrentView: true
		});

		this.legend = legend;
		this.mapView?.ui.add(this.legend, 'bottom-right');
	}

	//Create print widget
	createPrintWidget() {
		const printNIQ = new Print({
			view: this.mapView,
			printServiceUrl:
				'https://utility.arcgisonline.com/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task'
		});
		this.mapView.ui.add(
			new Expand({
				view: this.mapView,
				content: printNIQ,
				expandTooltip: 'Print Map Document',
				group: 'map-widget',
				autoCollapse: true
			}),
			'top-left'
		);
	}

	//Event handlers
	addEventHandlers() {
		console.log('Add Event Handlers');
		this.mapView.on('click', (event: any) => {
			this.mapView.hitTest(event, { include: [this.updatedFeatureLayer] }).then((response: any) => {
				if (
					response.results[0]?.graphic &&
					response.results[0]?.graphic?.attributes?.CMA_NAME !== null &&
					response.results[0]?.graphic?.attributes?.CMA_NAME !== undefined
				) {
					//Removes existing popups and highlight
					this.mapView.graphics.removeAll();

					//Add highlight
					this.mapView.graphics.add(
						new Graphic({
							geometry: response.results[0]?.graphic?.geometry,
							symbol: new SimpleLineSymbol({
								color: [255, 0, 0, 0.5]
							})
						})
					);

					//Triggering event to the parent
					//To get market name use --> response.results[0]?.graphic.attributes?.CMA_NAME
					this.gisClick.emit({
						mapView: this.mapView,
						layer: this.updatedFeatureLayer,
						event: response
					});

					//Example code to handle popup on the parent
					/*this.updatedFeatureLayer.popupTemplate = new PopupTemplate({
						title: '{ CMA_NAME }',
						content: '{ Index }' //Map the dynamic data
					});

					this.mapView.openPopup();*/
				}
			});
		});

		this.mapView.on('pointer-leave', event => {
			this.mapView.closePopup();
		});

		this.mapView.on('pointer-move', events => {
			this.mapView.hitTest(events, { include: [this.updatedFeatureLayer] }).then((event: any) => {
				if (
					event.results[0]?.graphic?.attributes?.CMA_NAME &&
					event.results[0]?.graphic.attributes?.Index !== null &&
					event.results[0]?.graphic.attributes?.Index !== undefined
				)
					this.gisMouseEnter.emit({ mapView: this.mapView, event: event });
				/*this.mapView.openPopup({
						location: event.results[0]?.graphic?.geometry.centroid,
						title: event.results[0]?.graphic?.attributes?.CMA_NAME,
						content: 'Content ' + event.results[0]?.graphic?.attributes?.Index
					});*/ else this.mapView.closePopup();
			});
		});
	}

	whenPromise(): Promise<void> {
		return new Promise(resolve => {
			this.mapView.when(() => {
				this.mapReady = true;
				this.mapView.watch('updating', isUpdating => {
					if (!isUpdating && this.mapReady) {
						setTimeout(() => {
							resolve();
						}, 2000);
					}
				});
			});
		});
	}

	//Update feature layer
	//Ex: this.updateFeatureLayer('Title', { 'Los Angeles': { Index: 20 }, 'Country 2': { Index: -20 } }, geoGisRenderer);
	updateFeatureLayer(title: string, attributeValues: { [key: string]: any }, renderer: any) {
		console.log('Update feature layer');
		let fields: any[] = [
			{ name: 'CMA', type: 'integer' },
			{ name: 'CMA_NAME', type: 'string' },
			{ name: 'Index', type: 'string' },
			{ name: 'IndexUB', type: 'string' },
			{ name: 'FID', type: 'oid' },
			{ name: 'GlobalID', type: 'global-id' }
		];

		this.updatedFeatureLayer?.queryFeatures().then(featureLayer => {
			let graphics = [];

			featureLayer?.features.forEach(feature => {
				let attributes = {
					...feature.attributes
				};

				delete attributes.Index;

				//Logic to Dynamically map the attribute values
				if (attributeValues && attributeValues[feature.attributes.CMA_NAME])
					attributes = {
						...attributes,
						...attributeValues[feature.attributes.CMA_NAME]
					};
				else attributes = { ...attributes };

				//Adding the graphics
				graphics.push(
					new Graphic({
						geometry: feature.geometry,
						attributes: attributes,
						symbol: new SimpleFillSymbol({
							color: '#F7F8F750',
							style: 'solid',
							outline: { width: 0.2, color: '#000000A0' }
						})
					})
				);
			});

			//Create feature layer
			this.updatedFeatureLayer = new FeatureLayer({
				source: graphics,
				title: title,
				outFields: ['*'],
				objectIdField: 'GlobalID',
				opacity: 0.9,
				fields: fields,
				renderer: renderer
			});

			//Remove existing layers
			this.map?.layers.forEach(layer => layer.destroy());
			if (this.map?.layers.length > 0) this.map?.layers.removeAll();

			//Adding feature layer to map
			this.map?.layers.add(this.updatedFeatureLayer);

			//Removing existing legend
			this.legend.destroy();
			if (this.legend) this.mapView?.ui.remove(this.legend);

			//Remove all highlights
			//this.mapView.graphics.removeAll();

			//Add legend
			this.createLegend();

			//Adding content to legend example
			if (title === 'Units Beat by CMA') {
				this.legend.when(() => {
					let legendHtmlElement: any = this.legend.container;
					legendHtmlElement.innerHTML =
						'<p style="height: 80%; position: absolute; top: 52%; left: 40%; font-size: 110px; animation-delay: 3s;">&#8597;</p>';
				});
			}

			//Adding click and mouse-enter event
			this.addEventHandlers();
		});
	}

	removeAllSelection() {
		//Remove all graphics
		this.mapView.graphics.removeAll();
	}

	//Remove all graphics
	removeAll() {
		console.log('Removing layers and legend');

		//Remove layer
		this.map?.layers.removeAll();

		//Remove legend
		this.mapView?.ui.remove(this.legend);
	}

	getLegend() {
		return this.legend;
	}

	captureScreenshot() {
		const mapContainer = document.getElementsByClassName('esri-legend__service')[0] as HTMLElement;

		if (this.mapView) {
			return this.mapView
				.takeScreenshot({ format: 'png' })
				.then(screenshot => {
					const base64Image = screenshot.dataUrl;
					if (mapContainer) {
						return html2canvas(mapContainer)
							.then(canvas1 => {
								const base64ImageMap = canvas1.toDataURL('image/png');
								return this.mergeImages(base64Image, base64ImageMap);
							})
							.catch(error => {
								console.error('Error capturing screenshot', error);
							});
					} else {
						console.error('Map view is not initialized');
						return null;
					}
				})
				.catch(error => {
					console.error('Error capturing screenshot', error);
				});
		} else {
			console.error('Map view is not initialized');
			return null;
		}
	}

	async mergeImages(base64Image: any, base64Image2: any) {
		if (this.mapView) {
			const base64ImageMerged = await this.mergeImageService.merge(base64Image, base64Image2);
			return base64ImageMerged;
		}
	}

	ngOnDestroy(): void {
		this.mapView?.destroy();
	}
}
