import { Injectable } from '@angular/core';
import { ReportService } from './report.service';
import { HttpClient } from '@angular/common/http';
import { NlsnDataStoreService } from '../core/service/store/nlsn-data-store.service';
import { BASE_URL } from 'src/environments/env.config.constants';
import { REST_API_CONSTANTS } from '../constants/rest-api.constant';
import { Observable, map } from 'rxjs';
import { NlsnGlobalsContextExtract } from '../core/service/auth/nlsn-globals.service';

@Injectable({
	providedIn: 'root'
})
export class ConsumerDecisionTreeService extends ReportService {
	constructor(
		http: HttpClient,
		nlsnDataStoreService: NlsnDataStoreService,
		nlsnGlobalsContextExtract: NlsnGlobalsContextExtract
	) {
		super(http, nlsnDataStoreService, nlsnGlobalsContextExtract);
	}

	getTreeData(payload: any): Observable<any> {
		const url =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			REST_API_CONSTANTS.REPORT.CONSUMER_DECISION_TREE.TREE_DATA.URL;

		return this.http.post<any>(url, payload, this.options).pipe(
			map(result => {
				return result;
			})
		);
	}

	getTreeTableData(payload: any): Observable<any> {
		const url =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			REST_API_CONSTANTS.REPORT.CONSUMER_DECISION_TREE.TREE_TABLE_DATA.URL;

		return this.http.post<any>(url, payload, this.options).pipe(
			map(result => {
				return result;
			})
		);
	}

	getUpcTableData(payload: any): Observable<any> {
		const url =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			REST_API_CONSTANTS.REPORT.CONSUMER_DECISION_TREE.UPC_TABLE_DATA.URL;

		return this.http.post<any>(url, payload, this.options).pipe(
			map(result => {
				return result;
			})
		);
	}
}
