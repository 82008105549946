<div class="container px-3 pt-3">
	<!--Search-->
	<crf-ca-save-default
		[selectorType]="selectorType"
		[configId]="configId"
		(menuSelector)="getMenuResponse($event)"
		[disable]="disabledDefaultOptions"
		[disableMenuOptions]="disableMenuOptions"
		[menu]="menu"
	></crf-ca-save-default>
	<niq-search-bar
		variant="expandable"
		size="medium"
		onWhite="true"
		[placeholder]="placeholder"
		class="searchbar mb-2"
		(inputValueChange)="onSearchChange($event)"
		(clear)="searchKey = ''"
	></niq-search-bar>
	<niq-accordion-group variant="single" size="medium" [expandedItem]="accordionExpandedItem">
		<niq-accordion
			size="medium"
			[isExpanded]="accordionExpandedItem === index"
			expandIcon="chevron-up"
			collapseIcon="chevron-down"
			[border]="'none'"
			*ngFor="let section of marketSections; let index = index"
			(toggle)="sectionToggle(index)"
			class="accordion mb-2"
		>
			<div accordion-actions class="d-flex justify-content-between">
				@if (accordionExpandedItem === index && section.title !== 'Total US' && multiSelect) {
				<niq-checkbox (change)="selectAllCMA()" [checked]="selectAll" size="small" class="pt-1">
				</niq-checkbox
				>&nbsp; }
				<p (click)="sectionToggle(index)" class="cursor-pointer">
					{{ section.title }}
				</p>
				<span
					class="ml-1"
					style="margin-top: 0.1rem"
					*ngIf="!multiSelect && section.title !== 'Total US' && configId !== 3"
				>
					<niq-icon
						class="pt-1"
						title="Only one market can be chosen with distribution facts"
						state="primary"
						size="small"
						iconName="info"
						colorTheme="light"
					></niq-icon>
				</span>
				<p style="position: absolute; right: 50px" *ngIf="section.title !== 'Total US'">
					<b (click)="sectionToggle(index)">{{ selectedCMACount }} of {{ cmaMarkets.length }}</b>
				</p>
			</div>
			<div accordion-content class="accordion-content-container p-3">
				<!-- Total US Section with Radio button-->
				@if (section.title === 'Total US') {
				<niq-radio-group (selectedChange)="onBannerSelection($event)" size="small">
					<niq-radio-button
						*ngFor="let market of bannerMarkets"
						[value]="market.key"
						[checked]="selectedBannerMarket === market.key"
					>
						{{ market.key }}
					</niq-radio-button>
				</niq-radio-group>
				}
				<!-- CMA Sections -->
				@if (multiSelect && section.title !== 'Total US') {
				<niq-checkbox-group>
					<niq-checkbox
						*ngFor="let item of cmaMarkets | filter: searchKey:'key'; let i = index"
						[(checked)]="item.isSelected"
						size="small"
						(checkedChange)="onSelectionChange()"
					>
						<span [innerHTML]="searchKey ? item.highlightedValue : item.key"></span>
					</niq-checkbox>
				</niq-checkbox-group>
				} @if ( !multiSelect && section.title !== 'Total US' ) {
				<niq-radio-group size="small">
					<niq-radio-button
						(click)="onSingleSelection(item)"
						*ngFor="let item of cmaMarkets | filter: searchKey:'key'; let i = index"
						[value]="item.key"
						[(checked)]="item.isSelected"
					>
						<span
							class="menu-text"
							[innerHTML]="searchKey ? item.highlightedValue : item.key"
						></span>
					</niq-radio-button>
				</niq-radio-group>
				}
			</div>
		</niq-accordion>
	</niq-accordion-group>
	<!--Footer-->
	<div class="button-container py-2 d-flex justify-content-end">
		<niq-button variant="ghost" class="mr-2" size="medium" (click)="onCancel.emit()">
			Cancel
		</niq-button>
		<niq-button
			size="medium"
			(click)="onMarketApply()"
			[state]="isApplyBtn ? 'disabled' : 'default'"
		>
			Apply
		</niq-button>
	</div>
</div>
