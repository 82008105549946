import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';
import { REST_API_CONSTANTS } from 'src/app/constants/rest-api.constant';
import { SHARE_REPORT_CONSTANTS } from 'src/app/reports/share-report/constants/share-report-constants';
import { NlsnDataStoreService } from '../../core/service/store/nlsn-data-store.service';
import { KEY } from '../../constants/data.constant';
import { BASE_URL } from '../../../environments/env.config.constants';
import * as _ from 'lodash';
import { NlsnGlobalsContextExtract } from 'src/app/core/service/auth/nlsn-globals.service';

@Injectable({
	providedIn: 'root'
})
export class ShareReportDashboardService {
	errorMessage: any;
	userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);

	headers: any = {
		headers: new HttpHeaders().append(
			'X-NIQ-ACCESS-TOKEN',
			this.nlsnDataStoreService.getItem(USER_SESSION_DETAILS.ACCESS_TOKEN)
		)
	};
	url = BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment];
	constructor(
		public http: HttpClient,
		private nlsnDataStoreService: NlsnDataStoreService,
		private nlsnGlobalsContextExtract: NlsnGlobalsContextExtract
	) {}

	getShareReportDashboardInfo(reqObj1, tab): Observable<any> {
		let tempReq =
			tab === 'Product'
				? REST_API_CONSTANTS.SHARE_REPORT_PRODUCT
				: REST_API_CONSTANTS.SHARE_REPORT_MARKET;
		let reqUrl = this.url + tempReq.URL;
		return this.http.post<any>(reqUrl, reqObj1, this.headers);
	}

	getChildLevels(queid, productlevel): Observable<any> {
		const reqUrl =
			this.url + REST_API_CONSTANTS.AZ_SEARCH_GET_CHILD_LEVELS.URL + queid + '/' + productlevel;
		REST_API_CONSTANTS.AZ_SEARCH_GET_CHILD_LEVELS.OTHERPARM = false;
		return this.http.get<any>(reqUrl, this.headers);
	}

	getSelectedProduct(queid, multiSelect): Observable<any> {
		let reqUrl = this.url + REST_API_CONSTANTS.AZ_SEARCH_POC_GET_SAVED_SELECTION.URL + queid;
		let data = this.http.get<any>(reqUrl, this.headers);
		return data;
	}

	getAODPeriodEndDate(qid, period, reqData: any): Observable<any> {
		let URI = qid + '/' + period;
		let reqUrl = this.url + REST_API_CONSTANTS.PERIODS_END_DATE_POST.URL + URI;
		let data = this.http.post<any>(reqUrl, reqData, this.headers);
		return data;
	}

	getMarketListData() {
		let reqUrl = this.url + REST_API_CONSTANTS.GET_ALL_RMS_MARKET_NAMES.URL;
		let data = this.http.get<any>(reqUrl, this.headers);
		return data;
	}

	dashedToCamelCase(str) {
		if (str !== 'SUBCATEGORY') {
			return str.toLowerCase().replace(/_(.)/g, function (match, chr) {
				return chr.toUpperCase();
			});
		} else {
			return 'subCategory';
		}
	}

	getShareReportDataForChart(periods, dataList, measureSelected, factSelected) {
		let originalDataCopy = [...dataList];
		if (originalDataCopy) {
			const list1 = [],
				list2 = [],
				list3 = [],
				barChartKeys = [],
				dotChartKeys = [],
				selectedPeriods = [];
			let barDatasetList = [],
				dotDataSetList = [];
			const parentBarColorSet = SHARE_REPORT_CONSTANTS.parentBarColorSet;
			const childBarColorSet = SHARE_REPORT_CONSTANTS.childBarColorSet;
			const xAxisKeys = SHARE_REPORT_CONSTANTS.xAxisChartKeys;
			const periodList = [...periods];
			let maxPeriod = 'dollarMarketShare_52Week';
			periodList.forEach(val => {
				if (val.isSelected) {
					selectedPeriods.push(val.gkey);
					if (val.gkey === 4) {
						barChartKeys.push(xAxisKeys[0]);
						dotChartKeys.push(xAxisKeys[3]);
						maxPeriod =
							measureSelected.value === '$'
								? 'dollarMarketShare_4Week'
								: measureSelected.value === 'EQ'
								? 'eqUnitsMarketShare_4Week'
								: 'unitsMarketShare_4Week';
					}
					if (val.gkey === 13) {
						barChartKeys.push(xAxisKeys[1]);
						dotChartKeys.push(xAxisKeys[4]);
						maxPeriod =
							measureSelected.value === '$'
								? 'dollarMarketShare_13Week'
								: measureSelected.value === 'EQ'
								? 'eqUnitsMarketShare_13Week'
								: 'unitsMarketShare_13Week';
					}
					if (val.gkey === 52) {
						barChartKeys.push(xAxisKeys[2]);
						dotChartKeys.push(xAxisKeys[5]);
						maxPeriod =
							measureSelected.value === '$'
								? 'dollarMarketShare_52Week'
								: measureSelected.value === 'EQ'
								? 'eqUnitsMarketShare_52Week'
								: 'unitsMarketShare_52Week';
					}
				}
			});
			originalDataCopy = this.sortProductDataValues(originalDataCopy, maxPeriod);

			_.forEach(originalDataCopy, function (mappedItem, index) {
				const valList1 = [],
					valList2 = [],
					valList3 = [],
					valList4 = [];
				const barColors =
					mappedItem.hierarchy === 'parent'
						? parentBarColorSet
						: mappedItem.hierarchy === 'child'
						? childBarColorSet
						: parentBarColorSet;
				let idx = -1;
				if (list1 && list1.length > 0 && mappedItem.productLevel) {
					idx = list1.findIndex(function (d) {
						return d.productName === mappedItem.productLevel;
					});
				}
				let factSelected_key_4week = factSelected.key + '_4Week';
				let factSelected_key_13week = factSelected.key + '_13Week';
				let factSelected_key_52week = factSelected.key + '_52Week';
				if (idx === -1) {
					if (selectedPeriods.indexOf(4) !== -1) {
						valList1.push({
							name: 'dolUnitMarketShare_4Week',
							value: mappedItem.dollarMarketShare_4Week,
							hierarchy: mappedItem.hierarchy,
							color: barColors[0]
						});
						valList2.push({
							name: 'dolUnitMarketShare_4Week',
							value: mappedItem.unitsMarketShare_4Week,
							hierarchy: mappedItem.hierarchy,
							color: barColors[0]
						});
						valList3.push({
							name: 'dolUnitWMSharePtChangeBPS_4Week',
							value: mappedItem[factSelected_key_4week],
							hierarchy: mappedItem.hierarchy
						});
						valList4.push({
							name: 'dolEQUnitMarketShare_4Week',
							value: mappedItem.eqUnitsMarketShare_4Week,
							hierarchy: mappedItem.hierarchy,
							color: barColors[0]
						});
					}
					if (selectedPeriods.indexOf(13) !== -1) {
						valList1.push({
							name: 'dolUnitMarketShare_13Week',
							value: mappedItem.dollarMarketShare_13Week,
							hierarchy: mappedItem.hierarchy,
							color: barColors[1]
						});
						valList2.push({
							name: 'dolUnitMarketShare_13Week',
							value: mappedItem.unitsMarketShare_13Week,
							hierarchy: mappedItem.hierarchy,
							color: barColors[1]
						});
						valList3.push({
							name: 'dolUnitWMSharePtChangeBPS_13Week',
							value: mappedItem[factSelected_key_13week],
							hierarchy: mappedItem.hierarchy
						});
						valList4.push({
							name: 'dolEQUnitMarketShare_13Week',
							value: mappedItem.eqUnitsMarketShare_13Week,
							hierarchy: mappedItem.hierarchy,
							color: barColors[0]
						});
					}
					if (selectedPeriods.indexOf(52) !== -1) {
						valList1.push({
							name: 'dolUnitMarketShare_52Week',
							value: mappedItem.dollarMarketShare_52Week,
							hierarchy: mappedItem.hierarchy,
							color: barColors[2]
						});
						valList2.push({
							name: 'dolUnitMarketShare_52Week',
							value: mappedItem.unitsMarketShare_52Week,
							hierarchy: mappedItem.hierarchy,
							color: barColors[2]
						});
						valList3.push({
							name: 'dolUnitWMSharePtChangeBPS_52Week',
							value: mappedItem[factSelected_key_52week],
							hierarchy: mappedItem.hierarchy
						});
						valList4.push({
							name: 'dolEQUnitMarketShare_52Week',
							value: mappedItem.eqUnitsMarketShare_52Week,
							hierarchy: mappedItem.hierarchy,
							color: barColors[0]
						});
					}

					list1[index] = {
						productName: mappedItem.productLevel,
						values: valList1
					};
					list2[index] = {
						productName: mappedItem.productLevel,
						values: valList2
					};
					dotDataSetList[index] = {
						productName: mappedItem.productLevel,
						values: valList3
					};
					list3[index] = {
						productName: mappedItem.productLevel,
						values: valList4
					};
				}
			});

			let leftYAxisName = '',
				rightYAxisName = factSelected.value;
			if (measureSelected.value === '$') {
				barDatasetList = [...list1];
				leftYAxisName = 'WM $ Share';
			} else if (measureSelected.value === 'Units') {
				barDatasetList = [...list2];
				leftYAxisName = 'WM Units Share';
			} else if (measureSelected.value === 'EQ') {
				barDatasetList = [...list2];
				leftYAxisName = 'WM EQ Units Share';
			}

			let finalResult = {
				enclosingDivContainerID: '.shared-report-chart-dashboard',
				showBarChart: true,
				showDotGraph: true,
				barDatasetList: barDatasetList,
				dotDataSetList: dotDataSetList,
				barChartKeys: barChartKeys,
				dotChartKeys: dotChartKeys,
				leftYAxisName: leftYAxisName,
				rightYAxisName: rightYAxisName
			};

			return finalResult;
		}
		return null;
	}

	// Sort the list by maxPeriodMarketShare
	sortProductDataValues(productDataValues, maxPeriodMarketShare) {
		return productDataValues.sort(function (a, b) {
			if (a.hierarchy === 'parent') {
				return -1;
			}
			if (b.hierarchy === 'parent') {
				return 1;
			}
			return parseFloat(b[maxPeriodMarketShare]) - parseFloat(a[maxPeriodMarketShare]);
		});
	}
}
export const USER_SESSION_DETAILS = {
	IS_LOGGEDIN: 'isSignIn',
	USER_NAME: 'userName',
	USER_ROLE: 'userRole',
	ACCESS_TOKEN: 'accessToken',
	EXPIRY_TIME: 'expiryTime',
	EMAIL_ID: 'emailId',
	NIQSESSION_COOKIE: 'niqSessionCookie'
};
