import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { USER_SESSION_DETAILS } from 'src/app/constants/dashboard-ui.constant';
import { NlsnDataStoreService } from 'src/app/core/service/store/nlsn-data-store.service';
import { NlsnGlobalsContextExtract } from 'src/app/core/service/auth/nlsn-globals.service';
import { BASE_URL, CONCIERGE_URL } from 'src/environments/env.config.constants';

@Injectable({
	providedIn: 'root'
})
export class TrainingService {
	headers: { headers: any };

	constructor(
		public http: HttpClient,
		private nlsnDataStoreService: NlsnDataStoreService,
		private nlsnGlobalsContextExtract: NlsnGlobalsContextExtract
	) {
		this.headers = {
			headers: new HttpHeaders().append(
				'X-NIQ-ACCESS-TOKEN',
				this.nlsnDataStoreService.getItem(USER_SESSION_DETAILS.ACCESS_TOKEN)
			)
		};
	}

	getTrainingMaterial(): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] + CONCIERGE_URL.GET_TRAINING_MATERIAL;
		return this.http.get<any>(pingURL, this.headers).pipe(
			map(result => {
				return result.responseData;
			})
		);
	}

	sortTrainingMaterialonSequence(data) {
		for (let i in data) {
			data[i].forEach(topic => {
				topic.resource.sort(
					(a, b) =>
						Number(a.sequenceId.slice(0, a.sequenceId.length - 2).split('-')[1]) -
						Number(b.sequenceId.slice(0, b.sequenceId.length - 2).split('-')[1])
				);
			});
		}
	}

	deleteTrainingMaterial(obj): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			CONCIERGE_URL.DELETE_TRAINING_MATERIAL;
		return this.http.post<any>(pingURL, obj, this.headers).pipe(
			map(result => {
				return result.responseData;
			})
		);
	}

	createTrainingMaterial(obj): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			CONCIERGE_URL.CREATE_TRAINING_MATERIAL;
		return this.http.post<any>(pingURL, obj, this.headers).pipe(
			map(result => {
				return result.responseData;
			})
		);
	}

	updateTrainingMaterial(obj): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			CONCIERGE_URL.SAVE_TRAINING_MATERIAL;
		return this.http.post<any>(pingURL, obj, this.headers).pipe(
			map(result => {
				return result.responseData;
			})
		);
	}

	updateTrainingMaterialSequence(obj) {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			CONCIERGE_URL.UPDATE_TRAINING_MATERIAL_SEQUENCE;
		return this.http.post<any>(pingURL, obj, this.headers).pipe(
			map(result => {
				return result.responseData;
			})
		);
	}
}
