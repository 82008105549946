import { Injectable } from '@angular/core';
import { BASE_URL, CONCIERGE_URL } from 'src/environments/env.config.constants';
import { NlsnDataStoreService } from 'src/app/core/service/store/nlsn-data-store.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { KEY } from '../../constants/data.constant';
import { Observable } from 'rxjs';
import { NlsnGlobalsContextExtract } from 'src/app/core/service/auth/nlsn-globals.service';
@Injectable({
	providedIn: 'root'
})
export class ConciergeUserCreateService {
	constructor(
		private nlsnDataStoreService: NlsnDataStoreService,
		private http: HttpClient,
		public nlsnGlobalsContextExtract: NlsnGlobalsContextExtract
	) {}
	userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);

	headers: any = {
		headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', this.userDetails?.access_token)
	};

	manufacturerServiceSearch(queryParams): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] +
			CONCIERGE_URL.GET_CLIENTS +
			'?orgName=' +
			queryParams;
		return this.http.get<any>(pingURL, this.headers).pipe(
			map(result => {
				return result;
			})
		);
	}

	clientServiceSearch(payload): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] +
			CONCIERGE_URL.GET_ORGANIZATIONS;
		return this.http.post<any>(pingURL, payload, this.headers).pipe(
			map(result => {
				return result;
			})
		);
	}

	getGroups(name: string): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] +
			CONCIERGE_URL.CONCIERGE_GET_GROUPS +
			'?groupName=' +
			name;
		return this.http.get<any>(pingURL, this.headers).pipe(
			map(result => {
				return result;
			})
		);
	}

	getCategories(name): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] +
			CONCIERGE_URL.CONCIERGE_GET_CATEGORIES +
			'?dimCategory=' +
			name;
		return this.http.get<any>(pingURL, this.headers).pipe(
			map(result => {
				return result;
			})
		);
	}

	getApps(name): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] +
			CONCIERGE_URL.CONCIERGE_GET_APPS +
			'?appName=' +
			name;
		return this.http.get<any>(pingURL, this.headers).pipe(
			map(result => {
				return result;
			})
		);
	}

	getDashboards(name): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] +
			CONCIERGE_URL.CONCIERGE_GET_DASHBOARDS +
			'?dashboardName=' +
			name;
		return this.http.get<any>(pingURL, this.headers).pipe(
			map(result => {
				return result;
			})
		);
	}

	getOrganizations(name) {
		let data = {
			searchString: name,
			exactMatch: 'false'
		};

		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] +
			CONCIERGE_URL.CONCIERGE_GET_ORGANIZATIONS;
		return this.http.post<any>(pingURL, data, this.headers).pipe(
			map(result => {
				return result;
			})
		);
	}

	getSuggestedCategoriesByManufacturer(manufacturer): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] +
			CONCIERGE_URL.CONCIERGE_GET_SUGGESTED_CATEGORIES_BY_MANUFACTURER +
			'?manufacturer=' +
			manufacturer;
		return this.http.get<any>(pingURL, this.headers).pipe(
			map(result => {
				return result;
			})
		);
	}

	saveGroup(payload: any): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] + CONCIERGE_URL.SAVE_GROUP;
		return this.http.post<any>(pingURL, payload, this.headers).pipe(
			map(result => {
				return result;
			})
		);
	}

	saveUser(payload: any): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] + CONCIERGE_URL.SAVE_USER;
		return this.http.post<any>(pingURL, payload, this.headers).pipe(
			map(result => {
				return result;
			})
		);
	}
}
