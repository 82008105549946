import { Injectable } from '@angular/core';
import { NlsnDataStoreService } from '../core/service/store/nlsn-data-store.service';
import { Router } from '@angular/router';
import { isEmpty } from 'lodash';
import { KEY } from '../constants/data.constant';
import { ToastService } from '@nielseniq/athena-core';

@Injectable({
	providedIn: 'root'
})
export class RoleGuardService {
	constructor(
		public storeService: NlsnDataStoreService,
		public router: Router,
		public toastService: ToastService
	) {}
	// Check if app is available in list
	canActivate() {
		this.storeService.isAppLoaded$.subscribe(response => {
			if (response) {
				const userApps = this.storeService.getItem(KEY.APPS);
				if (!isEmpty(userApps) && userApps.some(app => app.text === 'Concierge Module')) {
					return true;
				} else {
					this.navigateToHome();
				}
			} else {
				// redirect to homepage
				this.navigateToHome();
			}
		});
	}

	navigateToHome() {
		this.toastService.InjectToast(
			'error',
			'redirecting to home page',
			'',
			3000,
			'',
			'Unauthorized access to the given route',
			'large'
		);
		this.router.navigateByUrl('/');
		return false;
	}
}
