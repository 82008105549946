import { Injectable } from '@angular/core';
import {
	BY_LEVEL_REQUEST_TYPE,
	DOLLARS,
	OTHER,
	UNITS,
	EQ
} from '../../../constants/report.constant';
import { ExportUtilityService } from './export-utility.service';

@Injectable({
	providedIn: 'root'
})
export class ReportHeaderUtility extends ExportUtilityService {
	getFactSelectorMapping(fact: any) {
		let factMapping: any = {
			value: fact[0]
				? fact[0]?.gkey
				: fact[2]
				? fact[2]?.gkey
				: fact[3]
				? fact[3]?.gkey
				: fact[4]
				? fact[4]?.gkey
				: fact[1]?.gkey,
			nameDollars: fact[0]?.name,
			nameUnits: fact[1]?.name,
			nameEQ: fact[2]?.name,
			nameOthers: fact[3]?.name,
			nameDistribution: fact[4]?.name,
			field: fact[0]
				? fact[0]?.field
				: fact[2]
				? fact[2]?.field
				: fact[3]
				? fact[3]?.field
				: fact[4]
				? fact[4]?.field
				: fact[1]?.field,
			valueBase: fact[0]
				? fact[0]?.valuebase
				: fact[2]
				? fact[2]?.valuebase
				: fact[3]
				? fact[3]?.valuebase
				: fact[4]
				? fact[4]?.valuebase
				: fact[1]?.valuebase,
			isCheckedDollar: fact[0]?.isSelected,
			isCheckedUnit: fact[1]?.isSelected,
			isCheckedEQ: fact[2]?.isSelected,
			isCheckedOther: fact[3]?.isSelected,
			isCheckedDistribution: fact[4]?.isSelected,
			isDollarPinned: !fact[0] || fact[0]?.isPinned,
			isUnitPinned: !fact[1] || fact[1]?.isPinned,
			isEQPinned: !fact[2] || fact[2]?.isPinned,
			isOtherPinned: !fact[3] || fact[3]?.isPinned,
			isDistributionPinned: !fact[4] || fact[4]?.isPinned
		};

		return factMapping;
	}

	getFactMap(factData: any): Map<String, any[]> {
		let factDataMap: Map<String, any[]> = new Map<string, any[]>();
		// Forming 5*n array from the column specifications
		factData.forEach(fact => {
			if (factDataMap.has(fact.gkey)) {
				if (fact.valuebase === DOLLARS) {
					factDataMap.get(fact.gkey)[0] = fact;
				} else if (fact.valuebase === UNITS) {
					factDataMap.get(fact.gkey)[1] = fact;
				} else if (fact.valuebase === EQ) {
					factDataMap.get(fact.gkey)[2] = fact;
				} else if (fact.valuebase === OTHER) {
					factDataMap.get(fact.gkey)[3] = fact;
				} else {
					factDataMap.get(fact.gkey)[4] = fact;
				}
			} else {
				if (fact.valuebase === DOLLARS) {
					factDataMap.set(fact.gkey, [fact, null, null, null, null]);
				} else if (fact.valuebase === UNITS) {
					factDataMap.set(fact.gkey, [null, fact, null, null, null]);
				} else if (fact.valuebase === EQ) {
					factDataMap.set(fact.gkey, [null, null, fact, null, null]);
				} else if (fact.valuebase === OTHER) {
					factDataMap.set(fact.gkey, [null, null, null, fact, null]);
				} else {
					factDataMap.set(fact.gkey, [null, null, null, null, fact]);
				}
			}
		});
		return factDataMap;
	}

	getFactMapSingle(factData: any): Map<String, any[]> {
		let factDataMap: Map<string, any[]> = new Map<string, any[]>();

		factData.forEach(fact => {
			if (factDataMap.has(fact.factGroup)) {
				factDataMap.get(fact.factGroup).push(fact);
			} else {
				factDataMap.set(fact.factGroup, [fact]);
			}
		});

		return factDataMap;
	}

	getByLevelRequestType(productSelectorValues): string {
		let type: string = '';

		Object.keys(productSelectorValues).forEach(key => {
			if (type === '' || productSelectorValues[key].length > 0) type = BY_LEVEL_REQUEST_TYPE[key];
		});

		return type;
	}

	getByLevelSelectorRequestParam(productValue: any): any {
		let params: any = {};

		Object.keys(productValue).forEach(productKey => {
			params[productKey] = productValue[productKey].join(';');
		});

		return params;
	}

	getSaveSelectionPayload(headerValues: any) {
		let payload = {
			totalBusiness:
				(headerValues.product.totalBusiness && headerValues.product.totalBusiness.length) > 0
					? headerValues.product.totalBusiness.join(';')
					: '',
			majorBusiness:
				headerValues.product.majorBusiness && headerValues.product.majorBusiness.length > 0
					? headerValues.product.majorBusiness.join(';')
					: '',
			department:
				headerValues.product.department && headerValues.product.department.length > 0
					? headerValues.product.department.join(';')
					: '',
			categoryGroup:
				headerValues.product.categoryGroup && headerValues.product.categoryGroup.length > 0
					? headerValues.product.categoryGroup.join(';')
					: '',
			category:
				headerValues.product.category && headerValues.product.category.length > 0
					? headerValues.product.category.join(';')
					: '',
			subCategory:
				headerValues.product.subCategory && headerValues.product.subCategory.length > 0
					? headerValues.product.subCategory.join(';')
					: '',
			segment:
				headerValues.product.segment && headerValues.product.segment.length > 0
					? headerValues.product.segment.join(';')
					: '',
			brandOwnerHigh:
				headerValues.product.brandOwnerHigh && headerValues.product.brandOwnerHigh.length > 0
					? headerValues.product.brandOwnerHigh.join(';')
					: '',
			brandOwnerLow:
				headerValues.product.brandOwnerLow && headerValues.product.brandOwnerLow.length > 0
					? headerValues.product.brandOwnerLow.join(';')
					: '',
			brandHigh:
				headerValues.product.brandHigh && headerValues.product.brandHigh.length > 0
					? headerValues.product.brandHigh.join(';')
					: '',
			brandFamily:
				headerValues.product.brandFamily && headerValues.product.brandFamily.length > 0
					? headerValues.product.brandFamily.join(';')
					: '',
			brandLow:
				headerValues.product.brandLow && headerValues.product.brandLow.length > 0
					? headerValues.product.brandLow.join(';')
					: '',
			brand:
				headerValues.product.brand && headerValues.product.brand.length > 0
					? headerValues.product.brand.join(';')
					: '',
			market:
				headerValues.market && headerValues.market.length > 0
					? headerValues.market.map(o => o.key).join(';')
					: '',
			periodIds:
				headerValues.period && headerValues.period.length > 0
					? headerValues.period.map(o => o.key).join(';')
					: '',
			facts:
				headerValues.fact && headerValues.fact.facts.length > 0
					? headerValues.fact.facts
							.filter(fact => {
								return fact.isCheckedDollar || fact.isCheckedUnit || fact.isCheckedOther;
							})
							.map(fact => {
								let selectedValues = [];

								if (fact.isCheckedDollar) selectedValues.push(fact.nameDollars);
								if (fact.isCheckedUnit) selectedValues.push(fact.nameUnits);
								if (fact.isCheckedOther) selectedValues.push(fact.nameOthers);

								return selectedValues.join(';');
							})
							.join(';')
					: ''
		};

		return payload;
	}

	getPeriodEndDatePayload() {
		return {
			totalBusiness: '',
			majorBusiness: '',
			department: '',
			categoryGroup: '',
			category: '',
			subCategory: '',
			segment: '',
			brandOwnerHigh: '',
			brandOwnerLow: '',
			brandHigh: '',
			brandFamily: '',
			brandLow: '',
			market: '',
			period: '',
			cyPeriod: '',
			yaPeriod: '',
			wmMarket: '',
			rmMarket: ''
		};
	}
}
