import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filter',
	standalone: true
})
export class FilterPipe implements PipeTransform {
	transform(items: any[], searchText: string, fieldName: string): any[] {
		if (!items) {
			return [];
		}
		if (!searchText) {
			return items;
		}

		searchText = searchText.toLowerCase();

		return items
			.filter(item => item[fieldName].toLowerCase().includes(searchText))
			.map(item => {
				item.highlightedValue = this.highlightSearchText(item[fieldName], searchText);
				return item;
			});
	}

	highlightSearchText(item, searchText) {
		const regex = new RegExp(searchText, 'gi');
		return item?.replace(regex, match => `<b>${match}</b>`);
	}
}
