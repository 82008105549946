import { Injectable } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { NlsnGlobalsContextExtract } from '../core/service/auth/nlsn-globals.service';
import { APP_INSIGHTS_KEY } from 'src/environments/env.config.constants';
import { NlsnDataStoreService } from '../core/service/store/nlsn-data-store.service';

@Injectable({
	providedIn: 'root'
})
export class AppInsightsService {
	private appInsights: ApplicationInsights;
	private appName: string = '';

	constructor(
		private router: Router,
		private nlsnGlobalsContextExtract: NlsnGlobalsContextExtract,
		private nlsnDataStoreService: NlsnDataStoreService
	) {
		this.appInsights = new ApplicationInsights({
			config: {
				instrumentationKey: APP_INSIGHTS_KEY[this.nlsnGlobalsContextExtract.extractEnvironment()],
				enableAutoRouteTracking: true
			}
		});
		this.appInsights.loadAppInsights();
		this.setupRouterTracking();
	}

	//  Initialize Application Insights with options
	initialize(appId: string, appName: string): void {
		this.appName = appName || '(Application Root)';
		if (appId) {
			this.appInsights.config.instrumentationKey = appId;
		} else {
			console.warn('Application Insights not initialized');
		}
	}

	// Setup tracking for route changes
	private setupRouterTracking(): void {
		try {
			this.router.events.subscribe(event => {
				if (event instanceof NavigationStart) {
					this.appInsights.startTrackPage(this.getPagePath());
				} else if (event instanceof NavigationEnd) {
					this.appInsights.setAuthenticatedUserContext(
						this.nlsnDataStoreService.getItem('emailId')
					);
					this.appInsights.stopTrackPage(this.getPagePath());
					this.appInsights.trackPageView({ name: this.getPagePath() });
				}
			});
		} catch (err) {
			console.error('Error occured in app-insights');
		}
	}

	//  Helper to get the current page path
	getPagePath(): string {
		return this.appName + this.router.url;
	}
}
