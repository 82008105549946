<div class="container m-0 p-0">
	<div class="p-3">
		<div class="col-12 d-flex flex-wrap align-items-center">
			<div>
				<div class="mr-4">
					<div class="reportHeader">
						<h4 class="m-0 reportName">{{ pageTitle }}</h4>
					</div>
					<br />
					<div class="mb-0 reportMenu">
						<div>
							<!-- Product -->
							<span
								class="report-dropdown product-filter-container filter-container mr-5 p-2"
								*ngIf="headerConfig.product.config.available"
								#product
								promptClickOutside
								(clickOutside)="this.headerConfig.product.ui.display = false"
								(click)="$event.stopPropagation()"
							>
								<a
									class="justify-content-between flex-wrap align-items-center"
									[class.disabled]="headerConfig.product.ui.disabled"
									(click)="toggleDropdown(headerConfig.product.config.id)"
									niqTooltipShowArrow="true"
									[niqTooltipColorTheme]="'dark'"
									[niqTooltip]="'Choose the Product you would like to show in this report'"
									niqTooltipPosition="bottom"
									[ngClass]="headerConfig.product.ui.display ? 'prompt-expanded' : ''"
								>
									<span>{{ headerConfig.product.value.display[0] }}</span>
									<span
										[ngClass]="headerConfig.product.ui.disabled ? 'disabled' : ''"
										class="badge item-count pl-2"
										*ngIf="headerConfig.product.value.display.length > 1"
										[title]="headerConfig.product.value.display"
									>
										+{{ headerConfig.product.value.display.length - 1 }}</span
									>
								</a>
								<div
									class="product-selector-position"
									*ngIf="headerConfig.product.config.multiSelect"
								>
									<crf-ca-multilayer-dropdown
										*ngIf="headerConfig.product.config.multiSelect"
										[selectorType]="questionId"
										[hidden]="!headerConfig.product.ui.display"
										(onClose)="headerConfig.product.ui.display = false"
										(onApply)="valueChangeHandler($event, headerConfig.product.config.id)"
									>
									</crf-ca-multilayer-dropdown>
								</div>
							</span>
							<!-- Level -->
							<span *ngIf="headerConfig.bylevel.config.available">by</span>
							<span
								class="report-dropdown level-filter-container filter-container mr-5 p-2"
								*ngIf="headerConfig.bylevel.config.available"
								promptClickOutside
								(clickOutside)="this.headerConfig.bylevel.ui.display = false"
								(click)="$event.stopPropagation()"
							>
								<a
									class="flex-wrap justify-content-between align-items-center"
									[class.disabled]="headerConfig.bylevel.ui.disabled"
									(click)="toggleDropdown(headerConfig.bylevel.config.id)"
									niqTooltipShowArrow="true"
									[niqTooltipColorTheme]="'dark'"
									[niqTooltip]="
										'Choose the By level breakdown you would like to show in this report'
									"
									niqTooltipPosition="bottom"
									[ngClass]="headerConfig.bylevel.ui.display ? 'prompt-expanded' : ''"
								>
									<span>{{ headerConfig.bylevel.value.display }}</span>
								</a>
								<app-dropdown-menu
									*ngIf="!headerConfig.bylevel.config.multiSelect"
									[hidden]="!headerConfig.bylevel.ui.display"
									[items]="headerConfig.bylevel.value.dropdown"
									[selectorType]="1"
									(selectedItem)="valueChangeHandler($event, headerConfig.bylevel.config.id)"
									(onDefaultChange)="saveResetDefault($event, 'byLevel')"
									[disableMenuOptions]="
										defaultPayload?.byLevel === headerConfig?.bylevel?.value?.display
									"
									[showMenu]="true"
									(cancel)="headerConfig.bylevel.ui.display = false"
								>
								</app-dropdown-menu>
							</span>
							<!-- Market -->
							<span *ngIf="headerConfig.market.config.available && byprodlevel">for</span>
							<span
								class="report-dropdown filter-container mr-5 p-2"
								*ngIf="headerConfig.market.config.available && byprodlevel"
								promptClickOutside
								(clickOutside)="this.headerConfig.market.ui.display = false"
								(click)="$event.stopPropagation()"
							>
								<a
									id="companySelectorBtn"
									type="button"
									data-toggle="dropdown"
									[class.disabled]="headerConfig.market.ui.disabled"
									(click)="toggleDropdown(headerConfig.market.config.id)"
									aria-haspopup="true"
									aria-expanded="false"
									[niqTooltip]="marketTooltipContent"
									niqTooltipPosition="bottom"
									[niqTooltipColorTheme]="'dark'"
									[ngClass]="headerConfig.market.ui.display ? 'prompt-expanded' : ''"
								>
									<span
										class="by-level-input-text"
										*ngIf="byprodlevel === 'BRAND_OWNER_HIGH' && defaultParentCompany"
										>{{ defaultParentCompany[0] }}</span
									>
									<span
										class="badge item-count pl-2"
										*ngIf="defaultParentCompany?.length > 1"
										[title]="defaultParentCompany"
										>+{{ defaultParentCompany?.length - 1 }}</span
									>
									<span
										class="by-level-input-text"
										*ngIf="byprodlevel === 'BRAND_OWNER_LOW' && defaultBrandOwner"
										>{{ defaultBrandOwner[0] }}</span
									>
									<span
										class="badge item-count pl-2"
										[title]="defaultBrandOwner"
										*ngIf="defaultBrandOwner?.length > 1"
										>+{{ defaultBrandOwner?.length - 1 }}</span
									>
								</a>
								<ng-template #marketTooltipContent>
									<div
										*ngIf="byprodlevel === 'BRAND_OWNER_HIGH'"
										style="padding: 9px 14px; color: #000000"
									>
										{{ pageHoverTexts.parentCompanyTooltip }}
									</div>
									<div
										*ngIf="byprodlevel === 'BRAND_OWNER_LOW'"
										style="padding: 9px 14px; color: #000000"
									>
										{{ pageHoverTexts.brandOwnerTooltip }}
									</div>
								</ng-template>
								<crf-ca-market-filter
									[selectorType]="2"
									[configId]="questionId"
									[marketItems]="
										byprodlevel === 'BRAND_OWNER_HIGH' ? parentCompanyList : brandOwnerList
									"
									[hidden]="!headerConfig.market.ui.display"
									(onApply)="valueChangeHandler($event, headerConfig.market.config.id)"
									(onCancel)="toggleDropdown(headerConfig.market.config.id)"
								></crf-ca-market-filter>
							</span>
							<!-- Period -->
							<span *ngIf="headerConfig.period.config.available">during</span>
							<span
								class="report-dropdown filter-container mr-5 p-2"
								*ngIf="headerConfig.period.config.available"
								promptClickOutside
								(clickOutside)="this.headerConfig.period.ui.display = false"
								(click)="$event.stopPropagation()"
							>
								<a
									class="justify-content-between flex-wrap align-items-center"
									[class.disabled]="headerConfig.period.ui.disabled"
									(click)="toggleDropdown(headerConfig.period.config.id)"
									niqTooltipShowArrow="true"
									[niqTooltipColorTheme]="'dark'"
									[niqTooltip]="'Choose the Period you would like to show in this report'"
									niqTooltipPosition="bottom"
									[ngClass]="headerConfig.period.ui.display ? 'prompt-expanded' : ''"
								>
									<span>{{ headerConfig.period.value.display }}</span>
								</a>
								<app-dropdown-menu
									[hidden]="!headerConfig.period.ui.display"
									[items]="headerConfig.period.value.dropdown"
									[selectorType]="5"
									(selectedItem)="valueChangeHandler($event, headerConfig.period.config.id)"
									(onDefaultChange)="saveResetDefault($event, 'periodIds')"
									(cancel)="headerConfig.period.ui.display = false"
									[disableMenuOptions]="
										defaultPayload?.periodIds === headerConfig?.period?.value?.display
									"
								>
								</app-dropdown-menu>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--More options-->
		<div class="option-container d-flex align-items-center justify-content-end">
			<crf-ca-export-report
				(onExport)="reportDownload($event)"
				class="download-icon-report"
			></crf-ca-export-report>
			<!--Save/ Reset default-->
			<crf-ca-save-default
				(toggleOption)="closeAllDropdown()"
				(menuSelector)="saveResetDefault($event, 'all')"
				class="save-selection-all"
				style="top: 10rem"
				[selectorType]="7"
				configId="13"
				[disableMenuOptions]="
					defaultPayload?.byLevel === headerConfig?.bylevel.value?.display &&
					defaultPayload?.periodIds === headerConfig?.period?.value?.display
				"
			>
			</crf-ca-save-default>
		</div>
	</div>
</div>
<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
	<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 order-summary-data">
		<div class="consumer-drive-container">
			<div class="con-interactive-tabs tab-content">
				<div class="dashboard-secondary-tabs-nav">
					<nav class="navbar">
						<ul class="nav navbar-nav">
							<li
								id="li_product_tab"
								class="by-level-p"
								[ngClass]="activeTab === 'category' ? 'active' : ''"
								(click)="onTabChange('category')"
							>
								<a data-target="#Category" data-toggle="tab" aria-expanded="true">
									<span class="by-level-input-alias">
										<span class="by-level-input-text">
											{{ levelDisplayNameByKey[this.lastLevel] }} :
											<span class="product-input-text"
												>{{ headerConfig.product.value.display[0] }}
												<span
													[ngClass]="headerConfig.product.ui.disabled ? 'disabled' : ''"
													class="badge item-count pl-2 mb-2"
													*ngIf="headerConfig.product.value.display.length > 1"
													[title]="headerConfig.product.value.display"
												>
													+{{ headerConfig.product.value.display.length - 1 }}</span
												>
											</span></span
										>
									</span>
								</a>
							</li>
							<li
								id="li_parent_tab"
								class="by-level-p"
								[ngClass]="activeTab === 'company' ? 'active' : ''"
								(click)="onTabChange('company')"
							>
								<a data-target="#ParentCompany" data-toggle="tab" aria-expanded="false">
									<span class="by-level-input-alias">
										<span
											class="by-level-input-text"
											*ngIf="byprodlevel === 'BRAND_OWNER_HIGH' && defaultParentCompany"
										>
											{{ defaultParentCompany[0] }}

											<span
												class="badge item-count pl-2"
												*ngIf="defaultParentCompany?.length > 1"
												[title]="defaultParentCompany"
											>
												+{{ defaultParentCompany?.length - 1 }}</span
											>
										</span>

										<span
											class="by-level-input-text"
											*ngIf="byprodlevel === 'BRAND_OWNER_LOW' && defaultBrandOwner"
										>
											{{ defaultBrandOwner[0] }}

											<span
												class="badge item-count pl-2"
												*ngIf="defaultBrandOwner?.length > 1"
												[title]="defaultBrandOwner"
											>
												+{{ defaultBrandOwner?.length - 1 }}</span
											>
										</span>
									</span>
								</a>
							</li>
						</ul>
					</nav>
				</div>
				<div class="tab-pane active">
					<div class="consumer-drive-header">
						<div
							class="
								col-lg-10
								col-md-10
								col-sm-10
								col-lg-offset-1
								col-md-offset-1
								col-sm-offset-1
								col-xs-12
							"
						>
							<div id="loadData" *ngIf="noData" (click)="hideNoDataMessage()">
								<div id="overlay" class="ovt-general-overlay"></div>
								<div class="ovt-no-data-message">
									<span class="glyphicon glyphicon-exclamation-sign"></span>
									<span>No data available</span>
									<span id="closeId" class="ovt-no-data-message-close">×</span>
								</div>
							</div>
							<!-- Map Container-->
							<div class="kpi-map-container" #map1>
								<div id="viewDiv" class="map">
									<span class="spinner-large" *ngIf="isLoading">
										<niq-spinner colorTheme="light" size="medium" variant="brand"></niq-spinner>
									</span>
									<crf-ca-gis-map
										[hidden]="!isMapDataReady && isLoading"
										(gisClick)="onMapClick($event)"
										(gisMouseEnter)="onMapHover($event)"
									></crf-ca-gis-map>
								</div>
							</div>
						</div>

						<div class="clearfix spacer-20"></div>

						<!-- Sales valumentrics Tables - Started -->
						<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div class="clearfix spacer-20"></div>

							<div id="marketArea" class="market-area">
								<span class="page-back-arrow" *ngIf="selectedMarket !== 'Total US'">
									<span class="tooltiptext"
										><niq-icon
											state="primary"
											size="medium"
											iconName="arrow-standard-left
  "
											niqTooltipShowArrow="true"
											[niqTooltipColorTheme]="'dark'"
											[niqTooltip]="'Back to TOTAL US'"
											niqTooltipPosition="top"
											colorTheme="light"
											(click)="backToTotalUS()"
										>
										</niq-icon>
									</span>
								</span>
								<span class="p-2">
									<strong>{{ selectedMarket }}</strong>
								</span>
							</div>

							<div class="clearfix spacer-20"></div>

							<div class="row market-table">
								<!--Share Table -->
								<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-3">
									<div>
										<div class="table-responsive">
											<table class="table table-bordered" aria-describedby="">
												<thead>
													<tr>
														<th scope="col" rowspan="2" class="wcol-35"></th>
														<th scope="col" colspan="3" class="header-kpi">
															<strong>Share</strong>
														</th>
													</tr>
													<tr>
														<th
															scope="col"
															class="wcol-15"
															title="Walmart’s $ Share of the product  
in the Total Market (WM + RM)"
														>
															WM $ Share
														</th>
														<th
															scope="col"
															class="wcol-15"
															title="Walmart’s $ Market Share change  
from year ago in basis points"
														>
															WM $ Share Pt Chg (BPS)
														</th>
														<th
															scope="col"
															title="$ Value of Walmart’s $ Share point change  
(WM $ Share Pt Chg (BPS)/10000) x (WM $ Sales + RM $ Sales)"
														>
															WM $ Value of Share Pt Chg
														</th>
													</tr>
												</thead>
												<tbody>
													<tr *ngIf="isLoading">
														<td class="table-loader">
															<span class="spinner-large">
																<niq-spinner
																	colorTheme="light"
																	size="medium"
																	variant="brand"
																></niq-spinner>
															</span>
														</td>
													</tr>
													<tr *ngIf="!share && !isLoading">
														<td class="table-loader">
															<span class="no-data"> No data available </span>
														</td>
													</tr>
													<tr *ngFor="let share of share; let i = index" [hidden]="isLoading">
														<td style="text-align: left !important" class="wcol-35">
															<span
																*ngIf="i == 0"
																class="text-ellips"
																id="span_productname_0"
																[title]="share.name"
																>{{ share.name }}</span
															>

															<span
																class="text-ellips"
																*ngIf="
																	byprodlevel === 'BRAND_OWNER_HIGH' &&
																	defaultParentCompany &&
																	i == 1
																"
																[title]="defaultParentCompany[0]"
															>
																{{ defaultParentCompany[0] }}

																<span
																	class="badge item-count pl-2"
																	*ngIf="defaultParentCompany?.length > 1"
																	[title]="defaultParentCompany"
																>
																	+{{ defaultParentCompany?.length - 1 }}</span
																>
															</span>

															<span
																class="text-ellips"
																*ngIf="
																	byprodlevel === 'BRAND_OWNER_LOW' && defaultBrandOwner && i == 1
																"
																[title]="defaultBrandOwner[0]"
															>
																{{ defaultBrandOwner[0] }}

																<span
																	class="badge item-count pl-2"
																	*ngIf="defaultBrandOwner?.length > 1"
																	[title]="defaultBrandOwner"
																>
																	+{{ defaultBrandOwner?.length - 1 }}</span
																>
															</span>
														</td>
														<td class="wcol-21-8">
															<span>{{
																share.dollarMarketShare | number: '1.0' | truncateString
															}}</span>
															<span *ngIf="share.dollarMarketShare != 'NA'">%</span>
														</td>
														<td class="wcol-21-8">
															<span
																[ngStyle]="share.dollarSharePtChg | number: '1.0' | colorvarient"
																>{{ share.dollarSharePtChg | number: '1.0' | truncateString }}</span
															>
														</td>
														<td>
															<span
																[ngStyle]="
																	share.dollarValueofSharePtChg | number: '1.0' | colorvarient
																"
															>
																<span>{{
																	share.dollarValueofSharePtChg | formatNumber: 'dollar'
																}}</span>
															</span>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<!--Volumetric Table -->
								<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-3">
									<div class="table-responsive">
										<table class="table table-bordered" aria-describedby="">
											<thead>
												<tr>
													<th scope="col" colspan="4" class="header-kpi">
														<strong>$ Volumetrics</strong>
													</th>
												</tr>
												<tr>
													<th scope="col" title="Walmart’s Market $ Sales">WM $ Sales</th>
													<th
														scope="col"
														title="Walmart’s Market $ Sales percent 
change from year ago"
													>
														WM $ Sales % Chg
													</th>
													<th scope="col" title="Rem. Market $ Sales">RM $ Sales</th>
													<th
														scope="col"
														title="Rem. Market $ Sales percent 
change from year ago"
													>
														RM $ Sales % Chg
													</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngIf="isLoading">
													<td class="table-loader">
														<span class="spinner-large">
															<niq-spinner
																colorTheme="light"
																size="medium"
																variant="brand"
															></niq-spinner>
														</span>
													</td>
												</tr>
												<tr *ngIf="!dollarVolumetrics && !isLoading">
													<td class="table-loader">
														<span class="no-data"> No data available </span>
													</td>
												</tr>

												<tr *ngFor="let share of dollarVolumetrics" [hidden]="isLoading">
													<td [ngStyle]="share.wmdollarSales | colorvarient">
														<span *ngIf="share.wmdollarSales != 'NA'"></span
														><span>{{ share.wmdollarSales | formatNumber: 'dollar' }}</span>
													</td>
													<td>
														<span
															[ngStyle]="
																share.wmdollarSalesPercentChg | number: '1.0' | colorvarient
															"
														>
															<span>{{
																share.wmdollarSalesPercentChg | number: '1.1' | truncateString
															}}</span>
															<span *ngIf="share.wmdollarSalesPercentChg != 'NA'">%</span>
														</span>
													</td>
													<td [ngStyle]="share.rmdollarSales | colorvarient">
														<span *ngIf="share.rmdollarSales != 'NA'"></span
														><span>{{ share.rmdollarSales | formatNumber: 'dollar' }}</span>
													</td>
													<td>
														<span
															[ngStyle]="
																share.rmdollarSalesPercentChg | number: '1.1' | colorvarient
															"
														>
															<span>{{
																share.rmdollarSalesPercentChg | number: '1.1' | truncateString
															}}</span
															><span *ngIf="share.rmdollarSalesPercentChg != 'NA'">%</span>
														</span>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
								<!--unit Voluetrics Table -->
								<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-3">
									<div class="table-responsive">
										<table class="table table-bordered" aria-describedby="">
											<thead>
												<tr>
													<th scope="col" colspan="4" class="header-kpi">
														<strong>Unit Volumetrics</strong>
													</th>
												</tr>
												<tr>
													<th scope="col">WM Units</th>
													<th scope="col">WM Units % Chg</th>
													<th scope="col">RM Units</th>
													<th scope="col">RM Units % Chg</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngIf="isLoading">
													<td class="table-loader">
														<span class="spinner-large">
															<niq-spinner
																colorTheme="light"
																size="medium"
																variant="brand"
															></niq-spinner>
														</span>
													</td>
												</tr>
												<tr *ngIf="!unitVolumetrics && !isLoading">
													<td id="td_no_data" class="table-loader">
														<span class="no-data"> No data available </span>
													</td>
												</tr>
												<tr *ngFor="let share of unitVolumetrics" [hidden]="isLoading">
													<td id="td_1_unit_0" [ngStyle]="share.wmunits | colorvarient">
														<span>{{ share.wmunits | formatNumber }}</span>
													</td>
													<td>
														<span [ngStyle]="share.wmunitPercentChg | number: '1.0' | colorvarient">
															<span>{{
																share.wmunitPercentChg | number: '1.0' | truncateString
															}}</span>
															<span *ngIf="share.wmunitPercentChg != 'NA'">%</span>
														</span>
													</td>
													<td [ngStyle]="share.rmunits | colorvarient">
														<span>{{ share.rmunits | formatNumber }}</span>
													</td>
													<td>
														<span
															[ngStyle]="share.rmunitsPercentChg | number: '1.1' | colorvarient"
														>
															<span>{{
																share.rmunitsPercentChg | number: '1.1' | truncateString
															}}</span>
															<span *ngIf="share.rmunitsPercentChg != 'NA'">%</span>
														</span>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
								<!--EQ Units Volumetrics Table-->
								<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-3">
									<div class="table-responsive">
										<table class="table table-bordered" aria-describedby="">
											<thead>
												<tr>
													<th scope="col" colspan="4" class="header-kpi">
														<strong>EQ Units Volumetrics</strong>
													</th>
												</tr>
												<tr>
													<th scope="col">WM EQ Units</th>
													<th scope="col">WM EQ Units % Chg</th>
													<th scope="col">RM EQ Units</th>
													<th scope="col">RM EQ Units % Chg</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngIf="isLoading">
													<td class="table-loader">
														<span class="spinner-large">
															<niq-spinner
																colorTheme="light"
																size="medium"
																variant="brand"
															></niq-spinner>
														</span>
													</td>
												</tr>
												<tr *ngIf="!eqUnitsVolumetrics && !isLoading">
													<td id="td_no_data" class="table-loader">
														<span class="no-data"> No data available </span>
													</td>
												</tr>
												<tr *ngFor="let share of eqUnitsVolumetrics" [hidden]="isLoading">
													<td id="td_1_unit_0" [ngStyle]="share.wmeqUnits | colorvarient">
														<span>{{ share.wmeqUnits | formatNumber }}</span>
													</td>
													<td>
														<span
															[ngStyle]="share.wmeqUnitPercentChg | number: '1.0' | colorvarient"
														>
															<span>{{
																share.wmeqUnitPercentChg | number: '1.0' | truncateString
															}}</span>
															<span *ngIf="share.wmeqUnitPercentChg != 'NA'">%</span>
														</span>
													</td>
													<td [ngStyle]="share.rmeqUnits | colorvarient">
														<span>{{ share.rmeqUnits | formatNumber }}</span>
													</td>
													<td>
														<span
															[ngStyle]="share.rmeqUnitsPercentChg | number: '1.1' | colorvarient"
														>
															<span>{{
																share.rmeqUnitsPercentChg | number: '1.1' | truncateString
															}}</span>
															<span *ngIf="share.rmeqUnitsPercentChg != 'NA'">%</span>
														</span>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="col-xs-12 footnote-block">
	<crf-ca-dashboard-footnote [reportDimension]="kpiCtrl"></crf-ca-dashboard-footnote>
</div>
