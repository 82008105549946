import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as _ from 'lodash';

@Injectable()
export class NlsnDataStoreService {
	public isAppLoaded$ = new Subject<boolean>();

	private savedItems: any = {};

	deleteAll(): void {
		this.savedItems = {};
	}

	getItem(key: string): any {
		return this.savedItems[key];
	}

	putItem(key: string, value: any): void {
		this.savedItems[key] = value;
	}
}
