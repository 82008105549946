import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import { ToastService } from '@nielseniq/athena-core';
import { Subject, takeUntil } from 'rxjs';
import { DISTRIBUTION } from 'src/app/constants/report.constant';
import { TOAST } from 'src/app/constants/toast.constant';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
import { ReportService } from 'src/app/service/report.service';
@Component({
	selector: 'crf-ca-fact-selector',
	templateUrl: './fact-selector.component.html',
	styleUrl: './fact-selector.component.scss'
})
export class FactSelectorComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() report: string;
	@Input() configId: number;
	@Input() factData: any;
	@Input() menu = true;
	@Input() isHeaderMultiSelect: boolean = true;
	@Input() isValueMultiSelect: boolean = true;
	@Input() isOtherColumnPresent: boolean = false;
	@Input() isDistributionColumnPresent: boolean = false;
	@Input() questionId = -1;
	@Input() disableMenuOptions: boolean;
	@Input() nonModifiableColumns: string[] = [];
	@Output() onClose = new EventEmitter();
	@Output() closeDropdown = new EventEmitter<void>();

	@Input() headerValue: string = 'dollar';

	originalCopyOfFactData: any;

	@Output() selectedFacts: EventEmitter<any> = new EventEmitter<any>();
	@Output() onDefaultChange: EventEmitter<any> = new EventEmitter<any>();
	@Output() headerChange: EventEmitter<any> = new EventEmitter<any>();
	@Output() close: EventEmitter<string> = new EventEmitter<any>();

	factHeaders: any[] = [
		{ title: 'Dollars', value: 'dollar' },
		{ title: 'Units', value: 'units' },
		{ title: 'EQ', value: 'EQ' }
	];

	accordionExpandedItem: number = -1;
	searchText: string;
	isAllChecked: {
		dollar: boolean;
		units: boolean;
		EQ: boolean; // Added EQ here
		others?: boolean;
		distribution?: boolean;
	};

	protected readonly unSubscribeAll: Subject<void> = new Subject<void>();

	constructor(
		private service: ReportService,
		private toastService: ToastService,
		private filterPipe: FilterPipe
	) {}

	ngOnInit() {
		if (this.isOtherColumnPresent) this.factHeaders.push({ title: 'Others', value: 'others' });
		if (this.isDistributionColumnPresent)
			this.factHeaders.push({ title: 'Distribution', value: 'distribution' });

		this.isAllChecked = {
			dollar: this.factData?.every(item => item.isCheckedDollar),
			units: this.factData?.every(item => item.isCheckedUnit),
			EQ: this.factData?.every(item => item.isCheckedEQ), // Initialize EQ check status
			others: this.factData?.every(item => item.isCheckedOther),
			distribution: this.factData?.every(item => item.isCheckedDistribution)
		};
	}

	ngAfterViewInit() {
		this.factData.map(fact => {
			fact.searchString = fact.value
				? fact.value
				: '' + ' ' + fact.nameDollars
				? fact.nameDollars
				: '' + ' ' + fact.nameUnits
				? fact.nameUnits
				: '' + ' ' + fact.nameOthers
				? fact.nameOthers
				: '' + ' ' + fact.nameEQ
				? fact.nameEQ
				: '' + ' ' + fact.nameDistribution
				? fact.nameDistribution
				: '';
			return fact;
		});

		this.originalCopyOfFactData = structuredClone(this.factData);

		if (!this.isHeaderMultiSelect) {
			if (this.headerValue == 'dollar') {
				this.factData.forEach(fact => {
					fact.isUnitPinned = true;
					fact.isEQPinned = true; // Added to pin EQ
					fact.isCheckedUnit = false;
					fact.isCheckedEQ = false; // Added to uncheck EQ
				});
			} else if (this.headerValue == 'units') {
				this.factData.forEach(fact => {
					fact.isDollarPinned = true;
					fact.isEQPinned = true; // Added to pin EQ
					fact.isCheckedDollar = false;
					fact.isCheckedEQ = false; // Added to uncheck EQ
				});
			} else if (this.headerValue == 'EQ') {
				this.factData.forEach(fact => {
					fact.isDollarPinned = true;
					fact.isUnitPinned = true;
					fact.isCheckedDollar = false;
					fact.isCheckedUnit = false;
				});
			}
		}
	}

	changeHeaderValue(value: string) {
		this.headerValue = value;
		if (value == 'dollar') {
			this.factData.forEach((fact, index) => {
				fact.isUnitPinned = true;
				fact.isEQPinned = true; // Added to pin EQ
				fact.isCheckedUnit = false;
				fact.isCheckedEQ = false; // Added to uncheck EQ
				fact.isCheckedDollar = this.originalCopyOfFactData[index].isCheckedDollar;
				fact.isDollarPinned = this.originalCopyOfFactData[index].isDollarPinned;
			});
			if (this.isAllChecked.dollar) {
				this.isAllChecked.dollar = false;
				this.checkUncheckAll('dollar');
			}
		} else if (value == 'units') {
			this.factData.forEach((fact, index) => {
				fact.isDollarPinned = true;
				fact.isEQPinned = true; // Added to pin EQ
				fact.isCheckedDollar = false;
				fact.isCheckedEQ = false; // Added to uncheck EQ
				fact.isCheckedUnit = this.originalCopyOfFactData[index]?.isCheckedUnit;
				fact.isUnitPinned = this.originalCopyOfFactData[index]?.isUnitPinned;
			});
			if (this.isAllChecked.units) {
				this.isAllChecked.units = false;
				this.checkUncheckAll('units');
			}
		} else if (value == 'EQ') {
			this.factData.forEach((fact, index) => {
				fact.isDollarPinned = true;
				fact.isUnitPinned = true;
				fact.isCheckedDollar = false;
				fact.isCheckedUnit = false;
				fact.isCheckedEQ = this.originalCopyOfFactData[index]?.isCheckedEQ;
				fact.isEQPinned = this.originalCopyOfFactData[index]?.isEQPinned;
			});
			if (this.isAllChecked.EQ) {
				this.isAllChecked.EQ = false;
				this.checkUncheckAll('EQ');
			}
		}
	}

	checkUncheckAll(type: 'dollar' | 'units' | 'EQ' | 'others' | 'distribution') {
		switch (type) {
			case 'dollar':
				this.isAllChecked.dollar = !this.isAllChecked.dollar;
				this.factData.forEach(fact => {
					if (!fact.isDollarPinned) fact.isCheckedDollar = this.isAllChecked.dollar;
				});
				break;
			case 'units':
				this.isAllChecked.units = !this.isAllChecked.units;
				this.factData.forEach(fact => {
					if (!fact.isUnitPinned) fact.isCheckedUnit = this.isAllChecked.units;
				});
				break;
			case 'EQ':
				this.isAllChecked.EQ = !this.isAllChecked.EQ;
				this.factData.forEach(fact => {
					if (!fact.isEQPinned) fact.isCheckedEQ = this.isAllChecked.EQ;
				});
				break;
			case 'others':
				this.isAllChecked.others = !this.isAllChecked.others;
				this.factData.forEach(fact => {
					if (!fact.isOtherPinned) fact.isCheckedOther = this.isAllChecked.others;
				});
				break;
			case 'distribution':
				this.isAllChecked.distribution = !this.isAllChecked.distribution;
				this.factData.forEach(fact => {
					if (!fact.isDistributionPinned)
						fact.isCheckedDistribution = this.isAllChecked.distribution;
				});
				break;
		}
	}

	checkUncheckFact(type: 'dollar' | 'units' | 'EQ' | 'others' | 'distribution', fact: any) {
		switch (type) {
			case 'dollar':
				fact.isCheckedDollar = !fact.isCheckedDollar;
				this.isAllChecked.dollar = this.factData
					.filter(item => this.isVisibile(type, item))
					.every(item => item.isCheckedDollar);
				break;
			case 'units':
				fact.isCheckedUnit = !fact.isCheckedUnit;
				this.isAllChecked.units = this.factData
					.filter(item => this.isVisibile(type, item))
					.every(item => item.isCheckedUnit);
				break;
			case 'EQ':
				fact.isCheckedEQ = !fact.isCheckedEQ; // Added EQ logic
				this.isAllChecked.EQ = this.factData
					.filter(item => this.isVisibile(type, item))
					.every(item => item.isCheckedEQ); // Updated EQ logic
				break;
			case 'others':
				fact.isCheckedOther = !fact.isCheckedOther;
				this.isAllChecked.others = this.factData
					.filter(item => this.isVisibile(type, item))
					.every(item => item.isCheckedOther);
				break;
			case 'distribution':
				fact.isCheckedDistribution = !fact.isCheckedDistribution;
				this.isAllChecked.distribution = this.factData
					.filter(item => this.isVisibile(type, item))
					.every(item => item.isCheckedDistribution);
				break;
		}
	}

	isVisibile(factType: 'dollar' | 'units' | 'EQ' | 'others' | 'distribution', fact: any): boolean {
		if (
			(factType === 'dollar' && !fact.nameDollars) ||
			(factType === 'units' && !fact.nameUnits) ||
			(factType === 'EQ' && !fact.nameEQ) ||
			(factType === 'others' && !fact.nameOthers) ||
			(factType === 'distribution' && !fact.nameDistribution)
		) {
			return false;
		}

		if (this.isDistributionColumnPresent && factType === 'distribution') {
			return (
				!fact.isDistributionPinned || fact.isCheckedDistribution || fact.valueBase === DISTRIBUTION
			);
		}

		if (this.isOtherColumnPresent && factType === 'others') {
			return !fact.isOtherPinned || fact.isCheckedOther;
		}

		return !(
			!fact.isOtherPinned ||
			fact.isCheckedOther ||
			!fact.isDistributionPinned ||
			fact.isCheckedDistribution
		);
	}

	isDisabled(factType: 'dollar' | 'units' | 'EQ' | 'others' | 'distribution', fact: any): boolean {
		if (this.nonModifiableColumns.map(col => col.toLowerCase()).includes(factType)) return true;
		else if (this.isOtherColumnPresent && factType == 'others') return fact.isOtherPinned;
		else if (this.isDistributionColumnPresent && factType == 'distribution')
			return fact.isDistributionPinned;
		else if (factType == 'EQ') return fact.isEQPinned; // Added EQ logic

		return factType == 'dollar'
			? fact.isDollarPinned
			: factType == 'units'
			? fact.isUnitPinned
			: false;
	}

	isChecked(factType: 'dollar' | 'units' | 'EQ' | 'others' | 'distribution', fact: any): boolean {
		if (factType == 'others') return fact.isCheckedOther;
		else if (factType == 'distribution') return fact.isCheckedDistribution;
		else if (factType == 'EQ') return fact.isCheckedEQ; // Added EQ logic

		return factType == 'dollar'
			? fact.isCheckedDollar
			: factType == 'units'
			? fact.isCheckedUnit
			: false;
	}

	getTotalCount(factType: 'dollar' | 'units' | 'EQ' | 'others' | 'distribution') {
		if (factType == 'dollar')
			return this.factData.filter(fact => this.isVisibile(factType, fact)).length;
		else if (factType == 'units')
			return this.factData.filter(fact => this.isVisibile(factType, fact)).length;
		else if (factType == 'EQ')
			return this.factData.filter(fact => this.isVisibile(factType, fact)).length;
		else if (factType == 'others')
			return this.factData.filter(fact => this.isVisibile(factType, fact)).length;
		else if (factType == 'distribution')
			return this.factData.filter(fact => this.isVisibile(factType, fact)).length;

		return 0;
	}

	getCheckedCount(factType: 'dollar' | 'units' | 'EQ' | 'others' | 'distribution') {
		if (factType == 'dollar') return this.factData.filter(fact => fact.isCheckedDollar).length;
		else if (factType == 'units') return this.factData.filter(fact => fact.isCheckedUnit).length;
		else if (factType == 'EQ') return this.factData.filter(fact => fact.isCheckedEQ).length;
		else if (factType == 'others') return this.factData.filter(fact => fact.isCheckedOther).length;
		else if (factType == 'distribution')
			return this.factData.filter(fact => fact.isCheckedDistribution).length;

		return 0;
	}

	apply() {
		if (this.isHeaderMultiSelect) {
			this.selectedFacts.emit(this.factData);
		} else {
			this.selectedFacts.emit({ header: this.headerValue.toUpperCase(), fact: this.factData });
		}
	}

	cancel() {
		this.onClose.emit(true);
		this.closeDropdown.emit();
	}

	search(searchString: string) {
		this.searchText = searchString;
		this.factData = this.originalCopyOfFactData
			/*.filter(
				fact =>
					fact.value?.toLowerCase()?.includes(searchString.toLowerCase()) ||
					fact.nameDollars?.toLowerCase()?.includes(searchString.toLowerCase()) ||
					fact.nameUnits?.toLowerCase()?.includes(searchString.toLowerCase()) ||
					fact.nameOthers?.toLowerCase()?.includes(searchString.toLowerCase()) ||
					fact.nameEQ?.toLowerCase()?.includes(searchString.toLowerCase()) ||
					fact.nameDistribution?.toLowerCase()?.includes(searchString.toLowerCase())
			)*/
			.map(item => {
				if (this.configId === 13) {
					//const highlightedValue = this.filterPipe.highlightSearchText(item.value, searchString);
					item.highlightedValue = this.filterPipe.highlightSearchText(item.value, searchString);
					return item;
				} else {
					const highlightedDollars = this.filterPipe.highlightSearchText(
						item.nameDollars,
						searchString
					);
					const highlightedUnits = this.filterPipe.highlightSearchText(
						item.nameUnits,
						searchString
					);
					const highlightedOthers = this.filterPipe.highlightSearchText(
						item.nameOthers,
						searchString
					);
					const highlightedEQ = this.filterPipe.highlightSearchText(item.nameEQ, searchString);
					const highlightedDistribution = this.filterPipe.highlightSearchText(
						item.nameDistribution,
						searchString
					);

					item.highlightedDollars = highlightedDollars;
					item.highlightedUnits = highlightedUnits;
					item.highlightedOthers = highlightedOthers;
					item.highlightedEQ = highlightedEQ;
					item.highlightedDistribution = highlightedDistribution;

					return item;
				}
			});

		if (!this.isHeaderMultiSelect) this.changeHeaderValue(this.headerValue);
	}
	clearSelection() {
		const dollarPinned = this.factData.filter(fact => fact.isDollarPinned === true);
		const unitPinned = this.factData.filter(fact => fact.isUnitPinned === true);
		const eqPinned = this.factData.filter(fact => fact.isEQPinned === true);

		this.factData.forEach(fact => {
			if (
				(!fact.isDollarPinned === true ||
					!fact.isUnitPinned === true ||
					!fact.isOtherPinned === true ||
					!fact.isDistributionPinned === true ||
					!fact.isEQPinned === true) &&
				(fact.isCheckedOther === true ||
					fact.isCheckedDollar === true ||
					fact.isCheckedUnit === true ||
					fact.isCheckedDistribution === true ||
					fact.isCheckedEQ === true)
			) {
				fact.isCheckedDollar = false;
				fact.isCheckedUnit = false;
				fact.isCheckedDistribution = false;
				fact.isCheckedOther = false;
				fact.isCheckedEQ = false;
			}
		});
		if (this.configId === 9 && dollarPinned.length > 0) {
			this.factData.forEach(fact => {
				dollarPinned.forEach(i => {
					if (i.value === fact.value && i.nameDollars !== undefined) {
						fact.isCheckedDollar = true;
					}
				});
			});
		}
		if (this.report === 'shareReport' && dollarPinned.length > 0) {
			this.factData.forEach(fact => {
				dollarPinned.forEach(i => {
					if (i.value === fact.value) {
						fact.isCheckedDollar = true;
					}
				});
			});
			this.factData.forEach(fact => {
				unitPinned.forEach(i => {
					if (i.value === fact.value) {
						fact.isCheckedUnit = true;
					}
				});
			});
			this.factData.forEach(fact => {
				eqPinned.forEach(i => {
					if (i.value === fact.value) {
						fact.isCheckedEQ = true;
					}
				});
			});
		}
		if (this.isAllChecked.dollar) {
			this.checkUncheckAll('dollar');
		}
		if (this.isAllChecked.units) {
			this.checkUncheckAll('units');
		}
		if (this.isAllChecked.distribution) {
			this.checkUncheckAll('distribution');
		}
		if (this.isAllChecked.others) {
			this.checkUncheckAll('others');
		}
		if (this.isAllChecked.EQ) {
			this.checkUncheckAll('EQ');
		}
	}

	clear(event) {
		this.searchText = '';
		this.factData = structuredClone(this.originalCopyOfFactData);
		//If header is single select disabling other column
		if (!this.isHeaderMultiSelect)
			if (this.headerValue == 'dollar')
				this.factData.forEach(fact => {
					fact.isUnitPinned = true;
					fact.isCheckedUnit = false;
				});
			else if (this.headerValue == 'units')
				this.factData.forEach(fact => {
					fact.isDollarPinned = true;
					fact.isCheckedDollar = false;
				});
	}

	toggle(index: number) {
		if (this.accordionExpandedItem == index) this.accordionExpandedItem = -1;
		else this.accordionExpandedItem = index;
	}

	getMenuResponse(data) {
		if (this.questionId != -1) {
			if (data.menuId == 1) {
				let facts = [];
				this.factData
					.filter(
						fact =>
							fact.isCheckedDollar || fact.isCheckedUnit || fact.isCheckedEQ || fact.isCheckedOther
					)
					.forEach(fact => {
						if (fact.isCheckedDollar) facts.push(fact.nameDollars);
						if (fact.isCheckedUnit) facts.push(fact.nameUnits);
						if (fact.isCheckedOther) facts.push(fact.nameOthers);
						if (fact.isCheckedDistribution) facts.push(fact.nameDistribution);
						if (fact.isCheckedEQ) facts.push(fact.nameEQ);
					});

				this.service
					.saveSelectors(this.questionId, 'facts', { facts: facts.join(';') })
					.pipe(takeUntil(this.unSubscribeAll))
					.subscribe({
						next: res => {
							//After saving replacing default data with new data
							this.originalCopyOfFactData = structuredClone(this.factData);
							this.toast('success', 'Success', 'Selections saved successfully');
						},
						error: err => {
							this.toast('error', 'Failed', 'Invalid Response');
						}
					});
			} else if (data.menuId == 2) {
				this.service
					.getSelectors(this.questionId)
					.pipe(takeUntil(this.unSubscribeAll))
					.subscribe({
						next: res => {
							if (res.responseData) {
								if (
									res.responseData.facts &&
									res.responseData.facts.split(';').length > 0 &&
									res.responseData.facts.split(';')[0].includes('Unit')
								) {
									this.factData = structuredClone(this.originalCopyOfFactData);
									this.isAllChecked.units = this.factData.every(item => item.isCheckedUnit);
									if (!this.isHeaderMultiSelect) this.changeHeaderValue('units');
								} else {
									this.factData = structuredClone(this.originalCopyOfFactData);
									this.isAllChecked.dollar = this.factData.every(item => item.isCheckedDollar);
									if (!this.isHeaderMultiSelect) this.changeHeaderValue('dollar');
								}
							} else this.toast('warning', 'Fact Selector', 'Get Menu Response: Invalid Response');
						},
						error: err => {
							this.toast('error', 'Fact Selector', 'Get Menu Response: Invalid Response');
						}
					});
			}
		}

		this.onDefaultChange.emit(data);
	}

	toast(type: any, title: string, message: string): void {
		this.toastService.InjectToast(type, message, '', TOAST.TIMEOUT, '', title, TOAST.SIZE);
	}

	ngOnDestroy(): void {
		this.unSubscribeAll.next();
		this.unSubscribeAll.complete();
	}
}
