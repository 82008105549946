import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@nielseniq/athena-core';
import { Observable, catchError, map, of, retry } from 'rxjs';
import { NlsnGlobalsContextExtract } from 'src/app/core/service/auth/nlsn-globals.service';
import { NlsnDataStoreService } from 'src/app/core/service/store/nlsn-data-store.service';
import { USER_SESSION_DETAILS } from '../../services/share-report-dashboard.service';
import { BASE_URL, PRODUCT_COMPARISON_ENDPOINTS } from 'src/environments/env.config.constants';
import { TOAST } from 'src/app/constants/toast.constant';

@Injectable({
	providedIn: 'root'
})
export class CategoryTrendsApiService {
	headers: { headers: HttpHeaders };
	constructor(
		public httpRequest: HttpClient,
		private nlsnDataStoreService: NlsnDataStoreService,
		public toastService: ToastService,
		public nlsnGlobalsContextExtract: NlsnGlobalsContextExtract
	) {
		this.headers = {
			headers: new HttpHeaders().append(
				'X-NIQ-ACCESS-TOKEN',
				this.nlsnDataStoreService.getItem(USER_SESSION_DETAILS.ACCESS_TOKEN)
			)
		};
	}
	getTablePost(payload, market): Observable<any> {
		let baseUrl =
			BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] +
			PRODUCT_COMPARISON_ENDPOINTS.TABLE_URL +
			`?${market}`;
		return this.httpRequest.post<any>(baseUrl, payload, this.headers).pipe(
			retry(3),
			catchError(error => {
				//Requested to be commented by Ujwal
				// this.toastService.InjectToast(
				// 	'error',
				// 	error.message,
				// 	'',
				// 	5000,
				// 	'',
				// 	'Something went wrong',
				// 	TOAST.SIZE
				// );
				return of(null);
			})
		);
	}
	getMarketPost(payload, market): Observable<any> {
		let baseUrl =
			BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] +
			PRODUCT_COMPARISON_ENDPOINTS.MARKET_URL +
			`?${market}`;
		return this.httpRequest.post<any>(baseUrl, payload, this.headers).pipe(
			map(res => res.markets),
			retry(3),
			catchError(error => {
				this.toastService.InjectToast(
					'error',
					error.message,
					'',
					5000,
					'',
					'Something went wrong',
					TOAST.SIZE
				);
				return of(null);
			})
		);
	}
}
