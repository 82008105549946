import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NlsnDataStoreService } from '../core/service/store/nlsn-data-store.service';
import {
	BASE_URL,
	CONCIERGE_URL,
	OVT_UI_URL_HOME_PAGE_URLS
} from '../../environments/env.config.constants';
import { KEY } from '../constants/data.constant';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { NlsnGlobalsContextExtract } from '../core/service/auth/nlsn-globals.service';
import * as _ from 'lodash';
import { ReportCard } from '../models/report.model';

@Injectable()
export class LandingPageService {
	constructor(
		private http: HttpClient,
		private nlsnDataStoreService: NlsnDataStoreService,
		private nlsnGlobalsContextExtract: NlsnGlobalsContextExtract
	) {}

	getAllCard(): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] +
			OVT_UI_URL_HOME_PAGE_URLS.QUESTIONS;
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		return this.http.get<ReportCard>(pingURL, headers).pipe(
			map(response => {
				// Stored data in service (Caching)
				let sortedCards = this.sortSubQuestions(response.result);
				this.nlsnDataStoreService.putItem(KEY.QUESTIONS, sortedCards);
				return sortedCards;
			})
		);
	}
	sortSubQuestions(data) {
		return data.map(section => {
			section.subQuestions.sort((a, b) => a.sequenceId - b.sequenceId);
			return section;
		});
	}
	getBanner(): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] +
			CONCIERGE_URL.CONCIERGE_GET_BANNER;
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		return this.http.get<any>(pingURL, headers);
	}
	geneateVideo(): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] +
			CONCIERGE_URL.SAS_TRAINING_MATERIAL;
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		return this.http.post<any>(pingURL, headers);
	}

	viewedDocument(data): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] +
			CONCIERGE_URL.UPDATE_ENHANCEMENT_SUMMARY_USERVIEWED;
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		return this.http.post<any>(pingURL, data, headers);
	}
}
