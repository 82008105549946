import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ConsumerDecisionTreeUtility } from 'src/app/utility/report/consumer-decision-tree.utility';
import { NodeComponent } from '../node/node.component';

@Component({
	selector: 'crf-ca-consumer-decision-tree-node',
	templateUrl: './consumer-decision-tree-node.component.html',
	styleUrl: './consumer-decision-tree-node.component.scss'
})
export class ConsumerDecisionTreeNodeComponent implements AfterViewInit {
	@Input() node: any;
	@Input() level: number = 0;
	@Input() displayTree: boolean = false;
	@Input() parent: NodeComponent;

	treeExpanded: boolean = false;
	displayMore: boolean = false;

	constructor(
		private utility: ConsumerDecisionTreeUtility,
		private changeDetectorRef: ChangeDetectorRef
	) {}

	ngAfterViewInit(): void {
		//Default: displaying only the level 0 tree
		this.displayTree = this.level == 0 || this.displayTree;

		//Expand tree toggle
		this.utility.expandTree$.subscribe({
			next: res => (this.displayTree = res || this.level == 0)
		});

		this.changeDetectorRef.detectChanges();
	}
}
