import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { defaultColDef, icons, themeClass } from '../../../../shared/model/column.config';
import { ColDef, GetDataPath, GridApi, GridReadyEvent } from 'ag-grid-community';
import { SELECTORS } from 'src/app/constants/report.constant';
import { SHARE_REPORT_CONSTANTS } from '../../constants/share-report-constants';
import { ShareReportDashboardService } from 'src/app/reports/services/share-report-dashboard.service';
import { UtilityService } from 'src/app/reports/services/utility.service';
import { ShareReportService } from 'src/app/reports/services/share-report.service';
import { SelectorConfiguration } from 'src/app/models/report.model';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import { GridCellComponent } from 'src/app/shared/components/grid/grid-cell/grid-cell.component';

@Component({
	selector: 'crf-ca-cma-comparison',
	templateUrl: './cma-comparison.component.html',
	styleUrl: './cma-comparison.component.scss'
})
export class CMAComparisonComponent implements OnInit {
	constructor(
		public shareReportDashboardService: ShareReportDashboardService,
		public utility: UtilityService,
		public shareReportService: ShareReportService
	) {
		this.upcColumns = SHARE_REPORT_CONSTANTS.BASE_FACT_LIST;
	}
	@Input() productData = [];
	@Input() selectedPeriod: any;
	@Input() factDataNew: any;
	@Input() selectedFact: any;
	@Input() actualValue: any;
	@Output() sortDetails = new EventEmitter<any>();
	@Output() excelData = new EventEmitter<any>();

	private gridApi!: GridApi;
	//public defaultColDef: ColDef = defaultColDef;
	public themeClass = themeClass;
	public icons = icons;
	enableLoader: boolean = true;
	upcColumns: any;
	colDefs: any;

	public defaultColDef: ColDef = {
		...defaultColDef,
		cellRenderer: GridCellComponent
	};
	public autoGroupColumnDef: ColDef = {
		headerName: 'CMA',
		field: 'productLevel',
		minWidth: 300,
		cellRendererParams: {
			suppressCount: true
		},
		pinned: true,
		sort: 'asc'
	};
	public rowData;
	public groupDefaultExpanded = -1;
	public rowSelection: 'single';
	public getDataPath: GetDataPath = (data: any) => {
		return data.productLevelHierarchy;
	};

	selectors: SelectorConfiguration = {
		id: SELECTORS.FACT.ID,
		display: true,
		displayName: 'Fact',
		displayValue: 'Fact Selector',
		actualValue: [],
		tooltip: 'Choose the Fact you would like to show in this report',
		icon: 'chevron-down',
		displayDropdown: false,
		type: 'fact-selector',
		dropdownItems: [],
		isMultipleSelect: false
	};

	// Load column at initial load
	defaultColumns: any = ['CMA'];

	onGridReady(params: GridReadyEvent) {
		this.gridApi = params.api;
	}
	ngOnInit(): void {
		this.upcColumns = this.utility.setDefaultColumnsPropertyByGkey(
			this.upcColumns,
			this.defaultColumns
		);
		this.getDefaultProduct();
		this.loadFactDropdown();
		this.colDefs = this.getFactColumnDefinition();
		this.getMarketResponse();
		this.excelData.emit(this.colDefs);
	}

	getMarketResponse() {
		let data = this.linkParentChild(this.productData);
		this.rowData = data;
		this.enableLoader = false;
	}

	public linkParentChild(data) {
		const productData = data;
		const parentProductLevel = _.map(
			productData.filter(x => x.hierarchy === 'parent'),
			'productLevel'
		);
		productData.forEach(element => {
			if (element.hierarchy === 'parent') {
				element.productLevelHierarchy = [parentProductLevel];
			}
			if (element.hierarchy === 'child') {
				element.productLevelHierarchy = [parentProductLevel, element.productLevel];
			}
		});
		return productData;
	}

	printSortColoumn(event) {
		let nameCol;
		let sortOrder;
		let columnWithSort = this.gridApi.getColumnState().find(col => col.sort !== null);
		if (columnWithSort) {
			if (columnWithSort.colId === 'ag-Grid-AutoColumn') {
				nameCol = 'CMA';
			} else {
				this.colDefs.filter(ccolName => {
					if (ccolName.field === columnWithSort.colId) {
						nameCol = ccolName.name;
					}
				});
			}
			sortOrder = columnWithSort.sort;
			this.sortDetails.emit({ nameCol, sortOrder });
		} else {
			sortOrder = '';
			nameCol = '';
			this.sortDetails.emit({ nameCol, sortOrder });
		}
	}

	getDefaultProduct() {
		let defaultCol = this.shareReportService.expandPeriodFact(
			this.selectedPeriod,
			this.selectedFact
		);
		defaultCol.forEach(ele => {
			this.defaultColumns.push(ele);
		});
	}

	getFactColumnDefinition() {
		let facts = null;
		facts = this.selectors.actualValue;
		const factMap: Map<String, any[]> = this.getFactMap();
		const colDefinition: any[] = [];
		//colDefinition.push(factMap.get('Product')[2]);
		facts.forEach(fact => {
			if (
				fact.isCheckedDollar &&
				factMap.size > 0 &&
				factMap.get(fact.value)[0] != null &&
				fact.nameDollars === factMap.get(fact.value)[0].name
			)
				colDefinition.push(factMap.get(fact.value)[0]);
			if (
				fact.isCheckedDollar &&
				factMap.size > 0 &&
				factMap.get(fact.value)[1] != null &&
				fact.nameDollars === factMap.get(fact.value)[1].name
			)
				colDefinition.push(factMap.get(fact.value)[1]);
			if (
				fact.isCheckedDollar &&
				factMap.size > 0 &&
				factMap.get(fact.value)[2] != null &&
				fact.nameDollars === factMap.get(fact.value)[2].name
			)
				colDefinition.push(factMap.get(fact.value)[2]);

			if (
				fact.isCheckedUnit &&
				factMap.size > 0 &&
				factMap.get(fact.value)[3] != null &&
				fact.nameUnits === factMap.get(fact.value)[3].name
			)
				colDefinition.push(factMap.get(fact.value)[3]);
			if (
				fact.isCheckedUnit &&
				factMap.size > 0 &&
				factMap.get(fact.value)[4] != null &&
				fact.nameUnits === factMap.get(fact.value)[4].name
			)
				colDefinition.push(factMap.get(fact.value)[4]);
			if (
				fact.isCheckedUnit &&
				factMap.size > 0 &&
				factMap.get(fact.value)[5] != null &&
				fact.nameUnits === factMap.get(fact.value)[5].name
			)
				colDefinition.push(factMap.get(fact.value)[5]);
			if (
				fact.isCheckedEQ &&
				factMap.size > 0 &&
				factMap.get(fact.value)[6] != null &&
				fact.nameEQ === factMap.get(fact.value)[6].name
			)
				colDefinition.push(factMap.get(fact.value)[6]);
			if (
				fact.isCheckedEQ &&
				factMap.size > 0 &&
				factMap.get(fact.value)[7] != null &&
				fact.nameEQ === factMap.get(fact.value)[7].name
			)
				colDefinition.push(factMap.get(fact.value)[7]);
			if (
				fact.isCheckedEQ &&
				factMap.size > 0 &&
				factMap.get(fact.value)[8] != null &&
				fact.nameEQ === factMap.get(fact.value)[8].name
			)
				colDefinition.push(factMap.get(fact.value)[8]);
		});
		return colDefinition;
	}

	getFactMap(): Map<String, any[]> {
		const PERIOD_PLACEHOLDER = '{period}';
		const GLOBAL_REG_EXP_MODIFIER = 'g';

		let expandedBaseFacts: any = [];
		let factData: any = [];
		factData = this.upcColumns;

		let factDataMap: Map<String, any[]> = new Map<string, any[]>();

		this.selectedPeriod.forEach(period => {
			factData.forEach(baseFact => {
				if (!_.eq(baseFact.gkey, 'Product')) {
					if (_.isMatch(baseFact.availablePeriods, period.gkey)) {
						let expandedBaseFact: any = cloneDeep(baseFact);

						expandedBaseFact.name = _.replace(
							expandedBaseFact.name,
							new RegExp(PERIOD_PLACEHOLDER, GLOBAL_REG_EXP_MODIFIER),
							period.gkey
						);

						expandedBaseFact.headerName = _.replace(
							expandedBaseFact.headerName,
							new RegExp(PERIOD_PLACEHOLDER, GLOBAL_REG_EXP_MODIFIER),
							period.gkey
						);
						expandedBaseFact.field = _.replace(
							expandedBaseFact.field,
							new RegExp(PERIOD_PLACEHOLDER, GLOBAL_REG_EXP_MODIFIER),
							period.gkey
						);
						expandedBaseFact.period = _.replace(
							expandedBaseFact.period,
							new RegExp(PERIOD_PLACEHOLDER, GLOBAL_REG_EXP_MODIFIER),
							period.gkey
						);

						delete expandedBaseFact.availablePeriods;
						expandedBaseFacts.push(expandedBaseFact);
					}
				} else if (!_.find(expandedBaseFacts, baseFact)) {
					expandedBaseFacts.push(baseFact);
				}
			});
		});
		//Forming 3*n array from the column specifications
		expandedBaseFacts.forEach(fact => {
			if (factDataMap.has(fact.gkey)) {
				if (fact.valuebase === 'dollar' && fact.period === '4 Week') {
					factDataMap.get(fact.gkey)[0] = fact;
				}
				if (fact.valuebase === 'dollar' && fact.period === '52 Week') {
					factDataMap.get(fact.gkey)[1] = fact;
				}
				if (fact.valuebase === 'dollar' && fact.period === '13 Week') {
					factDataMap.get(fact.gkey)[2] = fact;
				}
				if (fact.valuebase === 'units' && fact.period === '4 Week') {
					factDataMap.get(fact.gkey)[3] = fact;
				} else if (fact.valuebase === 'units' && fact.period === '52 Week') {
					factDataMap.get(fact.gkey)[4] = fact;
				} else if (fact.valuebase === 'units' && fact.period === '13 Week') {
					factDataMap.get(fact.gkey)[5] = fact;
				}
				if (fact.valuebase === 'EQ' && fact.period === '4 Week') {
					factDataMap.get(fact.gkey)[6] = fact;
				} else if (fact.valuebase === 'EQ' && fact.period === '52 Week') {
					factDataMap.get(fact.gkey)[7] = fact;
				} else if (fact.valuebase === 'EQ' && fact.period === '13 Week') {
					factDataMap.get(fact.gkey)[8] = fact;
				}
			} else if (fact.valuebase === 'dollar' && fact.period === '4 Week') {
				factDataMap.set(fact.gkey, [fact, null, null]);
			} else if (fact.valuebase === 'dollar' && fact.period === '52 Week') {
				factDataMap.set(fact.gkey, [fact, null, null]);
			} else if (fact.valuebase === 'dollar' && fact.period === '13 Week') {
				factDataMap.set(fact.gkey, [fact, null, null]);
			} else if (fact.valuebase === 'units' && fact.period === '4 Week') {
				factDataMap.set(fact.gkey, [null, fact, null]);
			} else if (fact.valuebase === 'units' && fact.period === '52 Week') {
				factDataMap.set(fact.gkey, [null, fact, null]);
			} else if (fact.valuebase === 'units' && fact.period === '13 Week') {
				factDataMap.set(fact.gkey, [null, fact, null]);
			} else if (fact.valuebase === 'EQ' && fact.period === '4 Week') {
				factDataMap.set(fact.gkey, [null, fact, null]);
			} else if (fact.valuebase === 'EQ' && fact.period === '52 Week') {
				factDataMap.set(fact.gkey, [null, fact, null]);
			} else if (fact.valuebase === 'EQ' && fact.period === '13 Week') {
				factDataMap.set(fact.gkey, [null, fact, null]);
			} else {
				factDataMap.set(fact.gkey, [null, null, fact]);
			}
		});

		return factDataMap;
	}

	loadFactDropdown() {
		this.actualValue.push({
			value: 'Product',
			nameDollars: 'Product',
			nameUnits: null,
			isCheckedDollar: null,
			isCheckedUnit: null,
			isCheckedEQ: null,
			isDollarPinned: null,
			isUnitPinned: null,
			isEQPinned: null
		});
		this.selectors.dropdownItems.push(this.actualValue);
		this.selectors.actualValue = [];
		this.actualValue.forEach(fact => {
			if (fact.isCheckedDollar || fact.isCheckedUnit || fact.isCheckedEQ)
				this.selectors.actualValue.push(fact);
		});
	}

	loadColumnDefinition(periodFactSelected) {
		this.actualValue = [];
		this.selectedPeriod = [];
		this.actualValue = [...periodFactSelected.finalData];
		this.selectedPeriod = [...periodFactSelected.periods];
		this.loadFactDropdown();
		this.colDefs = this.getFactColumnDefinition();
		this.excelData.emit(this.colDefs);
	}
}
