import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map, of } from 'rxjs';
import { ProductSearchService } from 'src/app/service/product-search.service';
import { UtilityService } from './utility.service';
import { NlsnDataStoreService } from 'src/app/core/service/store/nlsn-data-store.service';
import {
	BASE_URL,
	OVT_UI_URL_DASHBOARD_SELECTION,
	PRODUCT_PERFORMANCE_DASHBOARD
} from 'src/environments/env.config.constants';
import { KEY } from 'src/app/constants/data.constant';
import { ChartRequestData } from 'src/app/models/report-dimension.model';
import { NlsnGlobalsContextExtract } from 'src/app/core/service/auth/nlsn-globals.service';

@Injectable({
	providedIn: 'root'
})
export class ProductPerformanceService {
	defaultSelectors: any;
	constructor(
		private http: HttpClient,
		private nlsnDataStoreService: NlsnDataStoreService,
		private nlsnGlobalsContextExtract: NlsnGlobalsContextExtract,
		private productSearchService: ProductSearchService,
		private utilityService: UtilityService
	) {}

	public retriveLevelData(level, id, data): Observable<any> {
		let searchString = '?';
		this.utilityService.isValid(data.total)
			? (searchString += 'totalBusiness=sampletotal')
			: (searchString += 'totalBusiness=');
		this.utilityService.isValid(data.major)
			? (searchString += '&majorBusiness=samplemajor')
			: (searchString += '&majorBusiness=');
		this.utilityService.isValid(data.dept)
			? (searchString += '&department=sampledept')
			: (searchString += '&department=');
		this.utilityService.isValid(data.catgrp)
			? (searchString += '&categoryGroup=samplecatgrp')
			: (searchString += '&categoryGroup=');
		this.utilityService.isValid(data.cat)
			? (searchString += '&category=samplecat')
			: (searchString += '&category=');
		this.utilityService.isValid(data.subcat)
			? (searchString += '&subCategory=samplesubcat')
			: (searchString += '&subCategory=');
		this.utilityService.isValid(data.seg)
			? (searchString += '&segment=sampleseg')
			: (searchString += '&segment=');
		this.utilityService.isValid(data.brandownerhigh)
			? (searchString += '&brandOwnerHigh=samplebrdownhgh')
			: (searchString += '&brandOwnerHigh=');
		this.utilityService.isValid(data.brandownerlow)
			? (searchString += '&brandOwnerLow=samplebrandownlow')
			: (searchString += '&brandOwnerLow=');
		this.utilityService.isValid(data.brdhgh)
			? (searchString += '&brandHigh=samplebrdhgh')
			: (searchString += '&brandHigh=');
		this.utilityService.isValid(data.brdlow)
			? (searchString += '&brandLow=samplebrdlow')
			: (searchString += '&brandLow=');

		return this.productSearchService.getChildLevels(id, level, searchString);
	}

	public getAllMarket(): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			OVT_UI_URL_DASHBOARD_SELECTION.GET_ALL_RMS_MARKET_NAMES;
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		return this.http.get<any>(pingURL, headers).pipe(
			map(result => {
				return result.responseData;
			})
		);
	}

	public retriveFactData(): Observable<any> {
		return of([
			{
				key: 'DOLLAR',
				value: '$',
				displayName: '$'
			},
			{
				key: 'UNITS',
				value: 'Units',
				displayName: 'Unit'
			},
			{
				key: 'EQ_UNITS',
				value: 'EQ',
				displayName: 'EQ'
			}
		]);
	}

	public getAllRMSPeriod(): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			OVT_UI_URL_DASHBOARD_SELECTION.GET_ALL_RMS_PERIODS;
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		return this.http.get<any>(pingURL, headers).pipe(
			map(result => {
				return result.responseData;
			})
		);
	}

	public getPeriodEndDate(queid, period, data): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			OVT_UI_URL_DASHBOARD_SELECTION.PERIODS_END_DATE +
			queid +
			'/' +
			period;
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		return this.http.post<any>(pingURL, data, headers).pipe(
			map(result => {
				return result.responseData;
			})
		);
	}

	getSelectedProducts(defaultData) {
		let selectedProducts = {};
		let selectedvalue = '';
		let selectedlevel = '';
		let parentChild = this.getCurrAndParProduct(defaultData);
		if (this.utilityService.isValid(defaultData.totalbusiness)) {
			selectedvalue = defaultData.totalbusiness;
			selectedlevel = 'TOTAL_BUSINESS';
		}
		if (this.utilityService.isValid(defaultData.majorbusiness)) {
			selectedvalue = defaultData.majorbusiness;
			selectedlevel = 'MAJOR_BUSINESS';
		}

		if (this.utilityService.isValid(defaultData.department)) {
			selectedvalue = defaultData.department;
			selectedlevel = 'DEPARTMENT';
		}
		if (this.utilityService.isValid(defaultData.categorygroup)) {
			selectedvalue = defaultData.categorygroup;
			selectedlevel = 'CATEGORY_GROUP';
		}
		if (this.utilityService.isValid(defaultData.category)) {
			selectedvalue = defaultData.category;
			selectedlevel = 'CATEGORY';
		}

		if (this.utilityService.isValid(defaultData.subcategory)) {
			selectedvalue = defaultData.subcategory;
			selectedlevel = 'SUBCATEGORY';
		}
		if (this.utilityService.isValid(defaultData.segment)) {
			selectedvalue = defaultData.segment;
			selectedlevel = 'SEGMENT';
		}
		if (this.utilityService.isValid(defaultData.brandownerhigh)) {
			selectedvalue = defaultData.brandownerhigh;
			selectedlevel = 'BRAND_OWNER_HIGH';
		}
		if (this.utilityService.isValid(defaultData.brandownerlow)) {
			selectedvalue = defaultData.brandownerlow;
			selectedlevel = 'BRAND_OWNER_LOW';
		}
		if (this.utilityService.isValid(defaultData.brandhigh)) {
			selectedvalue = defaultData.brandhigh;
			selectedlevel = 'BRAND_HIGH';
		}
		if (this.utilityService.isValid(defaultData.brandfamily)) {
			selectedvalue = defaultData.brandfamily;
			selectedlevel = 'BRAND_FAMILY';
		}
		if (this.utilityService.isValid(defaultData.brandlow)) {
			selectedvalue = defaultData.brandlow;
			selectedlevel = 'BRAND_LOW';
		}
		if (this.utilityService.isValid(defaultData.brand)) {
			selectedvalue = defaultData.brand;
			selectedlevel = 'BRAND';
		}
		selectedProducts = {
			selectedProduct: {
				value: selectedvalue,
				level: selectedlevel
			},
			dept: defaultData.department,
			catgrp: defaultData.categorygroup,
			cat: defaultData.category,
			subcat: defaultData.subcategory,
			seg: defaultData.segment,
			brandownerhigh: defaultData.brandownerhigh,
			brandownerlow: defaultData.brandownerlow,
			brdhgh: defaultData.brandhigh,
			brdfamily: defaultData.brandfamily,
			brdlow: defaultData.brandlow,
			total: defaultData.totalbusiness,
			major: defaultData.majorbusiness,
			brand: defaultData.brand, //for case dashboards
			responseData: parentChild,
			byLevel: defaultData.byLevel,
			market: defaultData.market,
			baseFact: defaultData.baseFact,
			factType: defaultData.factType,
			periodIds: defaultData.periodIds,
			facts: defaultData.facts,
			comparisonPeriod: defaultData.comparisonPeriod
		};
		return selectedProducts;
	}

	getCurrAndParProduct(data) {
		let rspon = data;
		let returnObj = {
			current_val: '',
			current_level: '',
			parent_val: '',
			parent_level: ''
		};

		let reportKeyMaping = {
			department: 'DEPARTMENT',
			categorygroup: 'CATEGORY_GROUP',
			category: 'CATEGORY',
			subcategory: 'SUBCATEGORY',
			segment: 'SEGMENT',
			brandownerhigh: 'BRAND_OWNER_HIGH',
			brandownerlow: 'BRAND_OWNER_LOW'
		};
		let reversKeyMaping = {
			DEPARTMENT: 'department',
			CATEGORY_GROUP: 'categorygroup',
			CATEGORY: 'category',
			SUBCATEGORY: 'subcategory',
			SEGMENT: 'segment',
			BRAND_OWNER_HIGH: 'brandownerhigh',
			BRAND_OWNER_LOW: 'brandownerlow'
		};

		let levelsHirarchy = [
			'DEPARTMENT',
			'CATEGORY_GROUP',
			'CATEGORY',
			'SUBCATEGORY',
			'SEGMENT',
			'BRAND_OWNER_HIGH',
			'BRAND_OWNER_LOW'
		];

		let latestLevel = 0;
		Object.keys(data).map(key => {
			if (reportKeyMaping[key] && data[key]) {
				let currentIndex = levelsHirarchy.indexOf(reportKeyMaping[key]);
				let parentIndex = currentIndex - 1;

				if (parentIndex !== -1) {
					Object.values(reportKeyMaping).forEach(item => {
						if (item === levelsHirarchy[parentIndex]) {
							if (latestLevel < currentIndex) {
								let parentKey = item;
								let currentKey = levelsHirarchy[currentIndex];

								returnObj.current_val = rspon[reversKeyMaping[currentKey]];
								returnObj.current_level = currentKey;
								returnObj.parent_val = rspon[reversKeyMaping[parentKey]];
								returnObj.parent_level = parentKey;

								latestLevel = currentIndex;
							}
						}
					});
				} else {
					returnObj = {
						current_val: data[key],
						current_level: levelsHirarchy[currentIndex],
						parent_val: '',
						parent_level: ''
					};
				}
			}
		});
		return returnObj;
	}

	findDefaultByLevel(list, currentLevel, property) {
		let currentIndex = list.findIndex(item => item[property] === currentLevel);
		return currentIndex === -1
			? 0
			: currentIndex === list.length - 1
			? list.length - 1
			: currentIndex + 1;
	}

	getChartData(reqObj: ChartRequestData): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			PRODUCT_PERFORMANCE_DASHBOARD.LHS +
			reqObj.level +
			'/' +
			reqObj.period +
			'/' +
			reqObj.fact;
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		return this.http.post<any>(pingURL, reqObj, headers).pipe(
			map(data => {
				return this.perapareChartList(data.responseData);
			})
		);
	}

	perapareChartList(data) {
		let chartData = data['LHS Data'];
		let chartBoundData = data['Chart Bounds'];
		let responseData = [
			{
				current: null,
				childData: []
			},
			{
				current: null,
				childData: []
			}
		];
		let barchartData = {
			labels: [],
			values: []
		};
		chartData.forEach(cdata => {
			barchartData.labels.push(cdata.productName);
			let wObj = {
				hierarchy: cdata.hierarchy,
				productName: cdata.productName,
				walmartSalesChange: cdata.walmartSalesChange,
				sharePtChange: cdata.sharePtChange
			};
			let rObj = {
				hierarchy: cdata.hierarchy,
				productName: cdata.productName,
				remainingMarketSalesChange: cdata.remainingMarketSalesChange,
				sharePtChange: cdata.sharePtChange
			};
			barchartData.values.push(wObj, rObj);
		});
		responseData[0].current = chartData.filter(item => item.hierarchy === 'parent')[0];
		responseData[1].current = chartData.filter(item => item.hierarchy === 'current')[0];
		responseData[1].childData = chartData.filter(item => item.hierarchy === 'child');

		return {
			chart: barchartData,
			resp: responseData,
			chartBounds: chartBoundData,
			suppressedProducts: data?.suppressedProducts
		};
	}

	getProductPerformanceInfo(rhsReqObj) {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			PRODUCT_PERFORMANCE_DASHBOARD.RHS +
			rhsReqObj.level +
			'/' +
			rhsReqObj.period +
			'/' +
			rhsReqObj.fact +
			'/' +
			rhsReqObj.hierarchy;
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		return this.http.post<any>(pingURL, rhsReqObj, headers).pipe(
			map(data => {
				return data.responseData;
			})
		);
	}
}
