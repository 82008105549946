const UNIT_BEATS = 'unitsBeats';
const PT_BEATS = 'ptBeats';
const BEATS_PT_CHG = 'beatsPtChg';
const PRICE_GAP = 'priceGap';
const PRICE_GAP_POINT_CHANGE = 'priceGapPtChg';

const DESC = 'desc';
const ASC = 'asc';

export const BASE_FACT_LIST = [
	{ desc: 'Units Beats', value: 'Units Beats', key: UNIT_BEATS },
	{ desc: '% Price Beats', value: '%Beats', key: PT_BEATS },
	{ desc: '% Beats Pt Chg', value: 'Beats%Chg', key: BEATS_PT_CHG },
	{ desc: '% Price Gap', value: 'Price Gap', key: PRICE_GAP },
	{ desc: '% Price Gap Pt Chg', value: 'Price Gap % Chg', key: PRICE_GAP_POINT_CHANGE }
];
