import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastService, ToastState } from '@nielseniq/athena-core';
import { Subscription } from 'rxjs';
import { DOLLAR_CP, EQ, UNITs_CP } from 'src/app/constants/report.constant';
import { TOAST } from 'src/app/constants/toast.constant';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
import { ReportService } from 'src/app/service/report.service';

@Component({
	selector: 'crf-ca-fact-selector-single',
	templateUrl: './fact-selector-single.component.html',
	styleUrl: './fact-selector-single.component.scss'
})
export class FactSelectorSingleComponent implements OnInit {
	@Input() configId: number;
	@Input() disableMenuOptions: boolean;
	@Input() menu = true;
	@Input() factData;
	@Input() defaultValue;
	@Input() questionId = -1;

	@Output() selectedFacts = new EventEmitter<any>();
	@Output() onClose = new EventEmitter<any>();

	initial = { headerValue: '', factValue: undefined };

	factHeaders: any[] = [
		{ title: 'Dollars', value: DOLLAR_CP, selectedCount: 0 },
		{ title: 'Units', value: UNITs_CP, selectedCount: 0 },
		{ title: 'EQ', value: EQ, selectedCount: 0 }
	];

	headerValue;
	factValue;
	accordionExpandedItem: number = -1;
	originalCopyOfTheFactData: any[] = [];
	disabledButton = false;
	searchText: string;

	constructor(
		private service: ReportService,
		private toastService: ToastService,
		private filterPipe: FilterPipe
	) {}

	ngOnInit(): void {
		if (this.factData && this.factData.length > 0) {
			this.factData.forEach(fact => {
				if (fact.isDollarChecked) {
					this.headerValue = DOLLAR_CP;
					this.factValue = fact;
				} else if (fact.isUnitChecked) {
					this.headerValue = UNITs_CP;
					this.factValue = fact;
				} else if (fact.isEQChecked) {
					this.headerValue = EQ;
					this.factValue = fact;
				}
			});
		}

		if (!this.factValue) {
			this.factValue = this.factData ? this.factData[0] : '';
		}
		if (this.factValue && !this.headerValue) {
			this.factData[0].isDollarChecked = true;
		}
		if (!this.headerValue) {
			this.headerValue = DOLLAR_CP;
		}
		this.initial.headerValue = this.headerValue;
		this.initial.factValue = this.factValue;

		this.originalCopyOfTheFactData = this.factData;
		this.getCheckedCount(this.headerValue);
	}

	headerValueChange(value): void {
		this.headerValue = value;
	}

	factValueChange(value, type: 'DOLLAR' | 'UNITS' | 'EQ'): void {
		this.factValue = value;
		this.headerValue = type;
		this.disabledButton = false;
		switch (type) {
			case DOLLAR_CP:
				this.factData.filter(fact => {
					if (fact.isDollarChecked === true) {
						fact.isDollarChecked = false;
					}
				});
				value.isDollarChecked = !value.isDollarChecked;
				break;
			case UNITs_CP:
				this.factData.filter(fact => {
					if (fact.isUnitChecked === true) {
						fact.isUnitChecked = false;
					}
				});
				value.isUnitChecked = !value.isUnitChecked;
				break;
			case EQ:
				this.factData.filter(fact => {
					if (fact.isEQChecked === true) {
						fact.isEQChecked = false;
					}
				});
				value.isEQChecked = !value.isEQChecked;
				break;
			default:
				// Added for fix code smells
				break;
		}
		this.updateSelection();
		//this.selectedFacts.emit({ header: this.headerValue, value: this.factValue });
	}
	getCheckedCount(factType: 'DOLLAR' | 'UNITS' | 'EQ') {
		switch (factType) {
			case DOLLAR_CP:
				this.factHeaders[0].selectedCount = this.factData.filter(
					fact => fact.isDollarChecked
				).length;
				break;

			case UNITs_CP:
				this.factHeaders[1].selectedCount = this.factData.filter(fact => fact.isUnitChecked).length;
				break;

			case EQ:
				this.factHeaders[2].selectedCount = this.factData.filter(fact => fact.isEQChecked).length;
				break;
			default:
				return 0;
		}
	}

	cancel() {
		this.onClose.emit(true);
	}

	apply() {
		this.selectedFacts.emit({ header: this.headerValue, value: this.factValue });
	}

	clearSelection() {
		this.disabledButton = true;
		this.factValue = '';
		this.factData.filter(fact => {
			if (fact.isDollarChecked || fact.isUnitChecked || fact.isEQChecked) {
				fact.isDollarChecked = false;
				fact.isUnitChecked = false;
				fact.isEQChecked = false;
			}
		});
	}

	search(searchString: string) {
		this.searchText = searchString;
		this.factData = this.originalCopyOfTheFactData
			.filter(
				fact =>
					fact.key?.toLowerCase()?.includes(searchString.toLowerCase()) ||
					fact.dollarKey?.toLowerCase()?.includes(searchString.toLowerCase()) ||
					fact.unitKey?.toLowerCase()?.includes(searchString.toLowerCase()) ||
					fact.eqKey?.toLowerCase()?.includes(searchString.toLowerCase())
			)
			.map(item => {
				item.highlightedValue = this.filterPipe.highlightSearchText(item.key, searchString);
				return item;
			});
	}

	clear(event) {
		this.searchText = '';
		this.factData = this.originalCopyOfTheFactData;
		this.updateSelection();
	}

	updateSelection() {
		if (this.headerValue) {
			if (this.headerValue === DOLLAR_CP) {
				this.factData.filter(fact => {
					if (fact.isUnitChecked === true) {
						fact.isUnitChecked = false;
					}
					if (fact.isEQChecked === true) {
						fact.isEQChecked = false;
					}
				});
			} else if (this.headerValue === UNITs_CP) {
				this.factData.filter(fact => {
					if (fact.isDollarChecked === true) {
						fact.isDollarChecked = false;
					}
					if (fact.isEQChecked === true) {
						fact.isEQChecked = false;
					}
				});
			} else {
				this.factData.filter(fact => {
					if (fact.isDollarChecked === true) {
						fact.isDollarChecked = false;
					}
					if (fact.isUnitChecked === true) {
						fact.isUnitChecked = false;
					}
				});
			}
			this.factHeaders[0].selectedCount = this.factData.filter(fact => fact.isDollarChecked).length;
			this.factHeaders[1].selectedCount = this.factData.filter(fact => fact.isUnitChecked).length;
			this.factHeaders[2].selectedCount = this.factData.filter(fact => fact.isEQChecked).length;

			/**
			 * Remove commented code  after validation
			 */

			// this.headerValue === DOLLAR_CP
			// 	? this.factData.filter(fact => {
			// 			if (fact.isUnitChecked === true) {
			// 				fact.isUnitChecked = false;
			// 			}
			// 	  })
			// 	: this.factData.filter(fact => {
			// 			if (fact.isDollarChecked === true) {
			// 				fact.isDollarChecked = false;
			// 			}
			// 	  });
			// this.headerValue === DOLLAR_CP
			// 	? this.getCheckedCount(UNITs_CP)
			// 	: this.getCheckedCount(DOLLAR_CP);
		}
	}
	toggle(index: number) {
		if (this.accordionExpandedItem == index) this.accordionExpandedItem = -1;
		else this.accordionExpandedItem = index;
	}

	getMenuResponse(data) {
		let subscription: Subscription;
		if (this.questionId !== -1) {
			if (data.menuId === 1) {
				let value = '';
				if (this.headerValue === DOLLAR_CP) {
					value = this.factValue?.dollarKey;
				} else if (this.headerValue === UNITs_CP) {
					value = this.factValue?.unitKey;
				} else {
					value = this.factValue?.eqKey;
				}

				if (value !== undefined) {
					subscription = this.service
						.saveSelectors(this.questionId, 'facts', { facts: value })
						.subscribe({
							next: res => {
								// Copying value from the initial variables
								this.initial.factValue = this.factValue;
								this.initial.headerValue = this.headerValue;
								this.toast('success', 'Success', 'Selections saved successfully');
							},
							error: err => {
								this.toast('error', 'Failed', 'Invalid Response');
								if (subscription) {
									subscription.unsubscribe();
								}
							},
							complete: () => {
								if (subscription) {
									subscription.unsubscribe();
								}
							}
						});
				} else {
					console.error('Value is undefined. Unable to subscribe.');
				}
			} else if (data.menuId === 2) {
				// Copying value from the initial variables
				this.factValue = this.initial.factValue;
				this.headerValue = this.initial.headerValue;

				this.selectedFacts.emit({ header: this.headerValue, value: this.factValue });
			}
		} else {
			console.error('Invalid question ID.');
		}
	}

	toast(type: ToastState, title: string, message: string): void {
		this.toastService.InjectToast(type, message, '', TOAST.TIMEOUT, '', title, TOAST.SIZE);
	}
}
