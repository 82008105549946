import { Component, OnInit } from '@angular/core';
import { NlsnGlobalsContextExtract } from '../core/service/auth/nlsn-globals.service';
import { AppInsightsService } from '../service/app-insights.service';
import { APP_INSIGHTS_KEY } from 'src/environments/env.config.constants';

@Component({
	selector: 'crf-ca-page',
	templateUrl: './page.component.html',
	styleUrl: './page.component.scss'
})
export class PageComponent implements OnInit {
	constructor(
		private nlsnGlobalsContextExtract: NlsnGlobalsContextExtract,
		private appInsightsService: AppInsightsService
	) {}

	ngOnInit() {
		// Initialize app insights with appId and appName
		this.appInsightsService.initialize(
			APP_INSIGHTS_KEY[this.nlsnGlobalsContextExtract.extractEnvironment()],
			undefined
		);
	}
}
