<div class="container m-0 p-0">
	<div class="row">
		<div class="col-12">
			<crf-ca-report-selector-header
				[questionId]="questionId"
				[selectorConfig]="headerComponentSelectorConfiguration"
				(selectorChange)="loadReport()"
				(export)="exportReport($event)"
			>
			</crf-ca-report-selector-header>
		</div>
	</div>
	<div class="row p-3">
		<div class="col-12">
			<crf-ca-main-performance-quadrant-report
				(tabChange)="mainTabChangeHandler($event)"
			></crf-ca-main-performance-quadrant-report>
		</div>
	</div>
	<div class="col-xs-12 footnote-block" *ngIf="selectorValues">
		<crf-ca-dashboard-footnote
			[reportDimension]="{
				dashboardName: 'Performance Quadrant (Scan)',
				selectedMarket:
					mainComponent.selectedTab === 'cma-quadrants'
						? ['All']
						: setMarketArray(selectorValues.market),
				selectedLevelToShow:
					mainComponent.selectedTab === 'cma-quadrants' ? '' : selectorValues.bylevel[0].value,
				period: selectorValues.period[0].value,
				periodEndDate: selectorValues.periodenddate,
				selectedProduct: setSelectedProduct(selectedProducts),
				volumeBasis:
					selectorValues.fact.factType[0] === 'DOLLAR'
						? '$'
						: selectorValues.fact.factType[0] === 'EQ'
						? 'EQ'
						: 'Units',
				isCmaView: mainComponent.selectedTab === 'cma-quadrants',
				multiMarketEnabled: 'true'
			}"
		></crf-ca-dashboard-footnote>
	</div>
</div>
