<div
	[ngClass]="{
		'all-save-selection': selectorType === 7,
		'all-save-selection-6': selectorType === 6
	}"
	class="selector-header"
>
	<div *ngIf="selectorType !== 7" class="selector-header-name">
		{{ selectorTypes[selectorType]?.displayName }}
	</div>
	<div
		*ngIf="menu"
		[hidden]="hideSaveSelection()"
		class="dropdown dropdown-ovt more-option"
		(click)="disable ? false : openMenu()"
	>
		<div class="btn">
			<a
				class="btn"
				style="background-color: transparent !important"
				id="defaultSelectBtn"
				type="button"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
				class="more-option-icon save-selection-ellipsis-block"
			>
				<niq-icon iconName="menu-meatball" size="small"></niq-icon>
			</a>
		</div>
	</div>
	<div *ngIf="showMoreOptions" class="more-option-list">
		<ul aria-labelledby="defaultSelectBtn">
			<li
				class="saveselection-menulist"
				[ngClass]="disableMenuOptions ? 'disabled' : ''"
				*ngFor="let menu of menuOptions"
				(click)="menuItemClicked(menu.id, selectorType)"
			>
				<span class="saveselection-menuicon">
					<niq-icon size="small" [iconName]="menu.icon" colorTheme="light"></niq-icon>
				</span>

				<span style="padding: 0px !important; white-space: nowrap"> {{ menu.displayName }}</span>
			</li>
		</ul>
	</div>
</div>
