export const ZIP_DOLLER = "zip:'dollar'";
export const ZIP_ROUNDED_NUMBER = "zip:'roundednumber'";
export const ZIP_PERCENTAGE = "zip:'perc'";
export const GRID_FROM_NUMBER = 'gridFromNumber';
export const DISTRIBUTION = 'Distribution';
export const UPC = 'UPC';
export const OTHER = 'Other';
export const DOLLARS = 'Dollars';
export const UNITS = 'Units';
export const TOTAL_US = 'Total US';
export const CMA = 'CMA';
export const EXCEL = 'excel';
export const PPT = 'ppt';
export const EQ = 'EQ';
export const BRAND_OWNER_LOW = 'brandOwnerLow';

// Below const used  Trended weekly
export const DOLLAR_SM = 'dollar';
export const UNITs_SM = 'units';
export const DOLLAR_CP = 'DOLLAR'; //Caps
export const UNITs_CP = 'UNITS'; //Caps
export const EQ_SM = 'eq';

export const SELECTORS = {
	PRODUCT: {
		ID: 'product',
		KEY: 'product'
	},
	BY_LEVEL: {
		ID: 'bylevel',
		KEY: 'bylevel'
	},
	MARKET: {
		ID: 'market',
		KEY: 'market'
	},
	PERIOD: {
		ID: 'periodIds',
		KEY: 'period'
	},
	FACT: {
		ID: 'facts',
		KEY: 'fact'
	}
};

export const PRODUCT_SELECTOR_MAPPING = {
	TOTAL: {
		NAME: 'Total Business',
		PAYLOAD_KEY: 'totalBusiness'
	},
	MAJOR: {
		NAME: 'Major Business',
		PAYLOAD_KEY: 'majorBusiness'
	},
	DEPT: {
		NAME: 'Department',
		PAYLOAD_KEY: 'department'
	},
	CATGRP: {
		NAME: 'Category Group',
		PAYLOAD_KEY: 'categoryGroup'
	},
	CAT: {
		NAME: 'Category',
		PAYLOAD_KEY: 'category'
	},
	SUBCAT: {
		NAME: 'Subcategory',
		PAYLOAD_KEY: 'subCategory'
	},
	SEG: {
		NAME: 'Segment',
		PAYLOAD_KEY: 'segment'
	},
	BRDOWNHGH: {
		NAME: 'Brand Owner High',
		PAYLOAD_KEY: 'brandOwnerHigh'
	},
	BRDOWNLOW: {
		NAME: 'Brand Owner',
		PAYLOAD_KEY: 'brandOwnerLow'
	},
	BRDHGH: {
		NAME: 'Brand High',
		PAYLOAD_KEY: 'brandHigh'
	},
	BRDFAMILY: {
		NAME: 'Brand Family',
		PAYLOAD_KEY: 'brandFamily'
	},
	BRDLOW: {
		NAME: 'Brand Low',
		PAYLOAD_KEY: 'brandLow'
	}
};

export const BY_LEVEL_REQUEST_TYPE = {
	totalBusiness: 'TOTAL_BUSINESS',
	majorBusiness: 'MAJOR_BUSINESS',
	department: 'DEPARTMENT',
	categoryGroup: 'CATEGORY_GROUP',
	category: 'CATEGORY',
	subCategory: 'SUBCATEGORY',
	segment: 'SEGMENT',
	brandOwnerHigh: 'BRAND_OWNER_HIGH',
	brandOwnerLow: 'BRAND_OWNER_LOW',
	brandHigh: 'BRAND_HIGH',
	brandFamily: 'BRAND_FAMILY',
	brandLow: 'BRAND_LOW'
};

export const BY_LEVEL_DISPLAY_LABEL = {
	department: 'Department',
	categoryGroup: 'Category Group',
	category: 'Category',
	subCategory: 'Subcategory',
	segment: 'Segment',
	brandOwnerHigh: 'Brand Owner High',
	brandOwnerLow: 'Brand Owner',
	brandHigh: 'Brand High',
	brandFamily: 'Brand Family',
	brandLow: 'Brand Low',
	totalBusiness: 'Total Business',
	majorBusiness: 'Major Business'
};

export const EXPORT_TYPE = {
	EXCEL: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	PPT: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	DEFAULT: 'application/text/csv'
};
