import {
	ZIP_ROUNDED_NUMBER,
	ZIP_PERCENTAGE,
	GRID_FROM_NUMBER,
	ZIP_DOLLER
} from 'src/app/constants/report.constant';

export const REQ_OBJ = {
	totalBusiness: [],
	majorBusiness: [],
	department: [],
	categoryGroup: [],
	category: [],
	subCategory: [],
	segment: [],
	brandOwnerHigh: [],
	brandOwnerLow: [],
	brandHigh: [],
	brandFamily: [],
	brandLow: '',
	market: [],
	period: '',
	cyPeriod: '',
	yaPeriod: '',
	wmMarket: [],
	rmMarket: []
};

export const optedSelection = [
	{ key: 'TOTAL_BUSINESS', value: [], alias_name: 'TOTAL' },
	{ key: 'MAJOR_BUSINESS', value: [], alias_name: 'MAJOR' },
	{ key: 'DEPARTMENT', value: [], alias_name: 'DEPT' },
	{ key: 'CATEGORY_GROUP', value: [], alias_name: 'CATGRP' },
	{ key: 'CATEGORY', value: [], alias_name: 'CAT' },
	{ key: 'SUBCATEGORY', value: [], alias_name: 'SUBCAT' },
	{ key: 'SEGMENT', value: [], alias_name: 'SEG' },
	{ key: 'BRAND_OWNER_HIGH', value: [], alias_name: 'BRDOWNHGH' },
	{ key: 'BRAND_OWNER_LOW', value: [], alias_name: 'BRDOWNLOW' },
	{ key: 'BRAND_HIGH', value: [], alias_name: 'BRDHGH' },
	{ key: 'BRAND_FAMILY', value: [], alias_name: 'BRDFAMILY' },
	{ key: 'BRAND_LOW', value: [], alias_name: 'BRDLOW' },
	{ key: 'BRAND', value: [], alias_name: 'BRAND' }
];

export const SELECT_PRODUCT = {
	department: '',
	categoryGroup: '',
	category: '',
	subCategory: '',
	segment: '',
	brandOwnerHigh: '',
	brandOwnerLow: '',
	brandFamily: '',
	brandLow: '',
	brandHigh: '',
	totalBusiness: '',
	majorBusiness: '',
	brand: '',
	level: ''
};
// export const multiSelectDelimiter: ";"
// export const BANNER_TITLE_TEXT: "Walmart One Version of Truth"

// export const LOG_LEVEL_TYPE: {
// 		ERROR: "ERROR",
// 		INFO: "INFO",
// 		DEBUG: "DEBUG",
// 	}

//   export const USER_TILES: {}
//   export const USER_APPS: {}
//   export const user_tiles_Text: []
//   export const MAIN_MENU: {
// 		ITEMS: ["Favorites", "Help"],
// 	}
// export const ACCOUNT_INFO: {
// 		ITEMS: ["My Favorites", "Shared with me", "Preferences"],
// 	}
//   export const LOGOUT_INFO: {
// 		ITEMS: ["Logout"],
// 	}
export const PANEL_DASHBOARD = {
	PRODUCT_SELECTOR_HOVER_TEXT: 'Choose the Product you would like to show in this report',
	MARKET_SELECTOR_HOVER_TEXT: 'Choose the Walmart Market you would like to show in this report',
	PERIOD_SELECTOR_HOVER_TEXT: 'Choose the Period you would like to show in this report',
	COMPARISON_PERIOD_SELECTOR_HOVER_TEXT:
		'Choose the comparison time period you would like to show in this report',
	EXPORT_TO_CSV_HOVER_TEXT: 'Export to CSV',

	DATA_NOT_AVAILABLE: 'Data Not Available',
	RAW_BUYERS_THRESHOLD: 'Raw buyers do not meet threshold',
	RAW_OCCASIONS_THRESHOLD: 'Low sample size',

	PURCHASE_DYNAMICS: 'Purchase Dynamics in Walmart',
	PENETRATION: 'TOTAL US PENETRATION',
	BUYING_RATE: 'BUYING RATE',
	FREQUENCY: 'FREQUENCY',
	DOLLAR_PER_TRIP: '$/TRIP',
	CHANGE_YA: 'CHANGE',
	CHANGE_PTS: 'CHANGE (PTS)',
	PENETRATION_LOWER_CASE: 'Total US Penetration',
	BUYING_HOUSEHOLDS: 'Buying Households',
	PERCENT_EXT: '%',

	SHARE_OF_WALLET_BLOCK_TITLE: 'Walmart Shopper Share of Wallet & Chg (PTS)',
	SHARE_OF_WALLET: 'Share Of Wallet ($)',
	SHARE_OF_WALLET_CHG: 'Share Of Wallet ($) Chg PTS',
	VS_YA: 'vs YA',

	DOLLAR_EXT: '$',
	CONTRIBUTION_DRIVERS: '$ Contribution Drivers',
	VALUE_CURRENT: 'Purchase $ TY',
	PERIOD_END_DATE: 'Period End Date: ',
	GAINED: ' gained',
	LOST: ' lost',
	DUETO: ' due to change in ',
	THE_NUMBER_OF: 'the number of ',
	MILLION: ' million',
	CHART_BOTTOM_LEGEND:
		'*Panel dollar amounts are used to illustrate relative impact of each driver and do not reflect actual sales values.',

	// Share of wallet dashboard section
	SHARE_TO: 'By',
	SHARE_TO_SELECTOR_LIST: ['Retailer', 'Channel'],
	SHARE_TO_SELECTOR_HOVER_TEXT: 'Choose to show Share of Wallet by Retailer Outlets or Channels',
	EXPAND_SHARE_WALLET_HOVER_TEXT: 'Expand Share of Wallet Table',
	CONTRACT_SHARE_WALLET_HOVER_TEXT: 'Return to Shopper Overview',
	// Share of Wallet expanded Table
	SHARE_WALLET_TABLE_TITLE: 'Share of Wallet Table',
	RETAILER_COLUMN: 'Retailer',
	BUYING_HOUSEHOLDS_COLUMN: 'Buying Households',
	PURCHASE_COLUMN: 'Purchase $',
	VM_SHARE_COLUMN: 'WM Share of Wallet',
	BPS_COLUMN: 'PTS Gained / Lost'
};
export const USER_SESSION_DETAILS = {
	IS_LOGGEDIN: 'isSignIn',
	USER_NAME: 'userName',
	USER_ROLE: 'userRole',
	ACCESS_TOKEN: 'accessToken',
	EXPIRY_TIME: 'expiryTime',
	EMAIL_ID: 'emailId',
	NIQSESSION_COOKIE: 'niqSessionCookie'
};

export const GEOGRAPHIC_DEEP_DIVE_DASHBOARD = {
	HEADER_NAMES: {
		DEPARTMENT: 'Department',
		CATEGORY_GROUP: 'Category Group',
		CATEGORY: 'Category',
		SUBCATEGORY: 'Subcategory',
		SEGMENT: 'Segment',
		BRAND_OWNER_HIGH: 'Brand Owner High',
		BRAND_OWNER_LOW: 'Brand Owner',
		BRAND_HIGH: 'Brand High',
		BRAND_FAMILY: 'Brand Family',
		BRAND_LOW: 'Brand Low',
		TOTAL_BUSINESS: 'Total Business',
		MAJOR_BUSINESS: 'Major Business',
		BRAND: 'Brand Low'
	},
	OPT_VAL: [
		{ key: 'DEPARTMENT', value: 'NA' },
		{ key: 'CATEGORY_GROUP', value: 'NA' },
		{ key: 'CATEGORY', value: 'NA' },
		{ key: 'SUBCATEGORY', value: 'NA' },
		{ key: 'SEGMENT', value: 'NA' },
		{ key: 'BRAND_OWNER_HIGH', value: 'NA' },
		{ key: 'BRAND_OWNER_LOW', value: 'NA' },
		{ key: 'BRAND_HIGH', value: 'NA' },
		{ key: 'BRAND_FAMILY', value: 'NA' },
		{ key: 'BRAND_LOW', value: 'NA' },
		{ key: 'TOTAL_BUSINESS', value: 'NA' },
		{ key: 'MAJOR_BUSINESS', value: 'NA' },
		{ key: 'BRAND', value: 'NA' }
	],
	MAP_INFO: {
		ESRI_CLOUD_URL: 'https://services7.arcgis.com/qHAgwIxldkwnmCm4/arcgis/rest/services/',
		LAYERS_LIST: {
			Name: 'Walmart CMA Market Names',
			Url: 'WalmartCMA/FeatureServer',
			outFields: ['CMA', 'CMA_Name', 'Index'],
			queryFilter: 'market',
			layerMode: 'MODE_ONDEMAND'
		},
		LEGEND_NAMES: {
			marketShare: 'WM $ Share',
			sharePTChg: 'WM $ Share Pt Chg (BPS) vs YA',
			sharePTChgTwoYA: 'WM $ Share Pt Chg (BPS) vs 2YA',
			dollarValueOfShrPTChg: 'WM $ Value Of Share Pt Chg vs YA',
			dollarValueOfShrPTChgTwoYA: 'WM $ Value Of Share Pt Chg vs 2YA',
			salesPerChg: 'WM $ Sales % Chg vs YA',
			salesPerChgTwoYA: 'WM $ Sales % Chg vs 2YA',
			salesActualChg: 'WM $ Sales Actual Chg vs YA',
			salesActualChgTwoYA: 'WM $ Sales Actual Chg vs 2YA',
			unitsMarketShare: 'WM Units Share',
			unitsPTChg: 'WM Units Share Pt Chg (BPS) vs YA',
			unitsPTChgTwoYA: 'WM Units Share Pt Chg (BPS) vs 2YA',
			unitValueOfShrPTChg: 'WM Units Value Of Share Pt Chg vs YA',
			unitValueOfShrPTChgTwoYA: 'WM Units Value Of Share Pt Chg vs 2YA',
			unitsSalesChg: 'WM Units % Chg vs YA',
			unitsSalesChgTwoYA: 'WM Units % Chg vs 2YA',
			unitsActualChg: 'WM Units Actual Chg vs YA',
			unitsActualChgTwoYA: 'WM Units Actual Chg vs 2YA'
		}
	},
	GIS_MODULE_LIST: [
		'dojo/parser',
		'dojo/dom',
		'dojo/dom-construct',
		'dojo/ready',
		'dojo/on',
		'dojo/_base/array',
		'esri/map',
		'esri/layers/FeatureLayer',
		'esri/InfoTemplate',
		'esri/arcgis/utils',
		'esri/tasks/geometry',
		'esri/geometry/Point',
		'esri/geometry/webMercatorUtils',
		'esri/graphic',
		'esri/symbols/SimpleFillSymbol',
		'esri/renderers/UniqueValueRenderer',
		'esri/symbols/SimpleMarkerSymbol',
		'esri/Color',
		'esri/symbols/SimpleLineSymbol',
		'esri/renderers/SimpleRenderer',
		'esri/renderers/ClassBreaksRenderer',
		'esri/dijit/InfoWindowLite',
		'esri/dijit/Legend',
		'esri/dijit/BasemapGallery',
		'esri/lang',
		'dijit/layout/BorderContainer',
		'dijit/layout/ContentPane',
		'dijit/layout/AccordionContainer',
		'esri/dijit/PopupTemplate',
		'dijit/TitlePane',
		'dijit/_WidgetBase',
		'dijit/registry',
		'dojo/dom-style',
		'dijit/TooltipDialog',
		'dijit/popup',
		'dojo/domReady!'
	]
};

// 	//starts Concierge
// export const CONCIERGE_HOMEPAGE_TILES: {
// 		LANDING_PAGE: [
// 			{
// 				heading: "Entitlements",
// 				subquestions: [
// 					{
// 						text: "Create Users",
// 						id: "8",
// 						image: "assets/img/tile-create-users.png",
// 						alttext: "",
// 						navlink: "createUsers",
// 					},
// 					{
// 						text: "Enable Content to Existing Users",
// 						id: "9",
// 						image: "assets/img/tile-enable-content-to-existing-users.png",
// 						alttext: "",
// 						navlink: "editEntitlements",
// 					},
// 					{
// 						text: "Bulk User Approve",
// 						id: "10",
// 						image: "assets/img/tile-bulk-user-approve.png",
// 						alttext: "",
// 						navlink: "bulkUserApprove",
// 					},
// 					{
// 						text: "Users & Entitlements Summary",
// 						id: "10",
// 						image: "assets/img/tile-request-status.png",
// 						alttext: "",
// 						navlink: "requestStatus",
// 					},
// 				],
// 			},
// 			{
// 				heading: "Communications",
// 				subquestions: [
// 					{
// 						text: "Push Notifications to the App",
// 						id: "11",
// 						image: "assets/img/tile-push-notifications-to-the-app.png",
// 						alttext: "",
// 						navlink: "pushNotifications",
// 					},
// 					{
// 						text: "Email to all Users",
// 						id: "12",
// 						image: "assets/img/tile-email-to-all-users.png",
// 						alttext: "",
// 						navlink: "",
// 					},
// 					{
// 						text: "Training Material",
// 						id: "12.1",
// 						image: "assets/img/tile-training.png",
// 						alttext: "",
// 						navlink: "trainings.edit",
// 					},
// 					{
// 						text: "User Notifications",
// 						id: "12.2",
// 						image: "assets/img/tile-training.png",
// 						alttext: "",
// 						navlink: "enhancementSummary",
// 					},
// 				],
// 			},
// 			{
// 				heading: "Stats",
// 				subquestions: [
// 					{
// 						text: "Stats",
// 						id: "13",
// 						image: "assets/img/tile-usage-report.png",
// 						alttext: "",
// 						navlink: "stats",
// 					},
// 				],
// 			},
// 			{
// 				heading: "Report a Problem",
// 				subquestions: [
// 					{
// 						text: "Urgent Scalations",
// 						id: "14",
// 						image: "assets/img/tile-urgent-escalations.png",
// 						alttext: "",
// 						navlink: "",
// 					},
// 				],
// 			},
// 		],
// 	}
//   export const CONCIERGE_HOMEPAGE: {
// 		TITLE: " Concierge",
// 		CONCIERGE_APP_NAME: "Concierge Module",
// 	}
//     export const WALMART_OVT_APP: {
// 		text: "Walmart One Version of Truth",
// 		image: "assets/img/app-ovt.png",
// 		alttext: "",
// 		navLink: "home",
// 		hoverText: "Walmart One Version of Truth",
// 		jbpApp: "true",
// 	}
// export const CONCIERGE_APP: {
// 		altText: "",
// 		hoverText: "Concierge Module",
// 		image: "assets/img/app-concierge-module.png",
// 		jbpApp: "true",
// 		navLink: "concierge.home",
// 		text: "Concierge Module",
// 	}
//   export const PUSH_NOTIFICATIONS: {
// 		UPPER_TITLE: "  Communications",
// 		NAV_BAR_PARENT: "Communications ",
// 		TITLE: "Push Notifications to the App",
// 		SUBJECT: "Subject: ",
// 		MESSAGE: "Message: ",
// 		APPS: "App: ",
// 		DASHBOARDS: "Dashboard: ",
// 		USERS: "Users",
// 		DEPARTMENTS: "Department:",
// 		SELECT_ALL: "Select All",
// 		SUBMIT: "Submit",
// 		ORGANIZATION: [
// 			{ key: 1, value: "NielsenIQ" },
// 			{ key: 2, value: "Walmart" },
// 		],
// 		SUBJECT_ERROR: "Please enter a Subject for your notification",
// 		MESSAGE_ERROR: "Please enter a Message for your notification",
// 		APPS_ERROR: "Please select at least one option",
// 		NOTIFICATION_CONFIRM:
// 			"Your message will be submitted to all users. Please confirm.",
// 		NOTIFICATION_SUCCESS: "Notification submitted succesfully.",
// 		NOTIFICATION_ERROR:
// 			"An error has occurred while submitting the notification, please try again later.",
// 	}
export const CREATE_USERS = {
	UPPER_TITLE: '  Entitlements',
	NAV_BAR_PARENT: 'Entitlements ',
	TITLE: 'User Creation',

	ONE_USER: 'Create User',
	MULTIPLE_USERS: 'Bulk Import Users',
	FIRST_NAME: 'First Name',
	FIRST_NAME_ERROR: "Please enter the user's first name.",
	LAST_NAME: 'Last Name',
	LAST_NAME_ERROR: "Please enter the user's last name.",
	EMAIL: 'Email',
	EMAIL_ERROR: 'Please enter a valid email of the user.',
	CLIENT: 'Client',
	ORGANIZATION: 'Organization',
	ORGANIZATION_ERROR: 'Please select a valid organization.',
	CLIENT_ERROR: 'Please select a valid client.',
	MANUFACTURER_RADIO: 'Manufacturer',
	WALMART_RADIO: 'Walmart',
	NIELSEN_RADIO: 'NielsenIQ',
	CONNECT_PARTNER_RADIO: 'Connect Partner',
	DEMOUSER_RADIO: 'Demo User',
	ADD_USER_LIST: 'Add Users From a List',
	BROWSER_NO_SUPPORT_FILE_READ_FUNCTIONS:
		'Your browser does not allow this function, please update your browser.',
	RECORDS_READ: 'Records successfully read: ',
	FILE_CANT_READ:
		"The file supplied can't be read, please try again following the format required.",
	SUCCESS_USER_CREATION_REQUEST:
		'Your user creation request has been successfully submitted. A confirmation message will be send to your email.',
	ERROR_USER_CREATION_REQUEST:
		'An error has occurred while submitting your request. Please try again later.',

	SELECT_APPS_ACCESS: 'Select Apps Access',
	SELECT_APPS_TO_ACCESS: 'Select Apps to Access',
	WALMART_OVT_DASHBOARDS: 'Walmart One Version of Truth Dashboards',

	GROUP: 'Group',
	SELECT_GROUPS: 'Select Existing Groups',
	CREATE_GROUP: 'Create New Group',
	GROUP_NAME_ERROR: 'Please provide a name for the new group',
	SEARCH_GROUP_PLACEHOLDER: 'Search Groups',
	GROUP_SELECTION_ERROR: 'Please select at least one group',

	SELECT_CATEGORY: 'Select Category',
	SEARCH_CATEGORIES_PLACEHOLDER: 'Search Categories',

	SUCCESS_NEW_GROUP: 'New group successfully saved.',
	CATEGORY_SELECTION_ERROR: 'Please select at least one category',
	DASHBOARD_SELECTION_ERROR: 'Please select at least one dashboard',
	ERROR_NEW_GROUP: 'An error has occurred while saving the new group, please try again later.'
};
export const EDIT_ENTITLEMENTS = {
	UPPER_TITLE: ' Entitlements',
	NAV_BAR_PARENT: 'Entitlements ',
	TITLE: 'Enable Content to the Existing Users',

	CONTAINER_HEADER: 'Existing groups',
	ORGANIZATION: 'Organization',
	ORGANIZATION_PLACEHOLDER: 'Search',
	ORGANIZATION_ERROR: 'Please select a valid organization.',
	MANUFACTURER_RADIO: 'Client',
	WALMART_RADIO: 'Walmart',
	NIELSEN_RADIO: 'NielsenIQ',
	SELECT_GROUP: 'Select group'
};
//   export const STATS: {
// 		UPPER_TITLE: "  Stats",
// 		NAV_BAR_PARENT: "Stats ",
// 		TITLE: "Usage Report",
// 	}
// 	// Ends Concierge
//   export const PRICE_BEATS_DASHBOARD: {
// 		TITLE: " WALMART PRICE BEATS",
// 		MAP: "MAP",
// 		TABLE: "TABLE",
// 		HEADER_NAMES: {
// 			DEPARTMENT: "Department",
// 			CATEGORY_GROUP: "Category Group",
// 			CATEGORY: "Category",
// 			SUBCATEGORY: "Subcategory",
// 			SEGMENT: "Segment",
// 			BRAND_OWNER_HIGH: "Brand Owner High",
// 			BRAND_OWNER_LOW: "Brand Owner",
// 			BRAND_HIGH: "Brand High",
// 			BRAND_FAMILY: "Brand Family",
// 			BRAND_LOW: "Brand Low",
// 			TOTAL_BUSINESS: "Total Business",
// 			MAJOR_BUSINESS: "Major Business",
// 		},
// 		MAP_INFO: {
// 			ESRI_CLOUD_URL:
// 				"https://services7.arcgis.com/qHAgwIxldkwnmCm4/arcgis/rest/services/",
// 			LAYERS_LIST: {
// 				Name: "Walmart CMA Market Names",
// 				Url: "WalmartCMA/FeatureServer",
// 				outFields: ["CMA", "CMA_Name", "Index"],
// 				queryFilter: "market",
// 				layerMode: "MODE_ONDEMAND",
// 			},
// 			LEGEND_NAMES: {
// 				"Units Beats": "Units Beat by CMA",
// 				"%Beats": "% Price Beats",
// 				"Beats%Chg": "% Beats Pt Chg",
// 				"Price Gap": "% Price Gap",
// 				"Price Gap % Chg": "% Price Gap Pt Chg",
// 			},
// 		},
// 		GIS_MODULE_LIST: [
// 			"dojo/parser",
// 			"dojo/dom",
// 			"dojo/dom-construct",
// 			"dojo/ready",
// 			"dojo/on",
// 			"dojo/_base/array",
// 			"esri/map",
// 			"esri/layers/FeatureLayer",
// 			"esri/InfoTemplate",
// 			"esri/arcgis/utils",
// 			"esri/tasks/geometry",
// 			"esri/geometry/Point",
// 			"esri/geometry/webMercatorUtils",
// 			"esri/graphic",
// 			"esri/symbols/SimpleFillSymbol",
// 			"esri/renderers/UniqueValueRenderer",
// 			"esri/symbols/SimpleMarkerSymbol",
// 			"esri/Color",
// 			"esri/symbols/SimpleLineSymbol",
// 			"esri/renderers/SimpleRenderer",
// 			"esri/renderers/ClassBreaksRenderer",
// 			"esri/dijit/InfoWindowLite",
// 			"esri/dijit/Legend",
// 			"esri/dijit/BasemapGallery",
// 			"esri/lang",
// 			"dijit/layout/BorderContainer",
// 			"dijit/layout/ContentPane",
// 			"dijit/layout/AccordionContainer",
// 			"esri/dijit/PopupTemplate",
// 			"dijit/TitlePane",
// 			"dijit/_WidgetBase",
// 			"dijit/registry",
// 			"dojo/dom-style",
// 			"dijit/TooltipDialog",
// 			"dijit/popup",
// 			"dojo/domReady!",
// 		],
// 	}
export const PRODUCT_PERFORMANCE = {
	TITLE: '  PRODUCT PERFORMANCE',
	PRODUCT_SELECTOR_HOVER_TEXT: 'Choose the Product you would like to show in this report',
	SUBCATEGORY_SELECTOR_HOVER_TEXT:
		'Choose the By level breakdown you would like to show in this report',
	MARKET_SELECTOR_HOVER_TEXT: 'Choose the Walmart Market you would like to show in this report',
	BASEFACT_SELECTOR_HOVER_TEXT: 'Choose the Base fact you would like to show in this report',
	PERIOD_SELECTOR_HOVER_TEXT: 'Choose the Period you would like to show in this report',

	SALES_MARKET: 'Sales and Market Share Pt Change',

	UNIT_SALES: 'Sorted by WM Units',
	DOLLAR_SALES: 'Sorted by WM $ Sales',
	EQ_SALES: 'Sorted by WM EQ',
	UNIT_CHG: 'Units % Chg',
	SALES_CHG: '$ Sales % Chg',
	EQ_CHG: 'EQ % Chg',
	UNIT_SHARE: 'Unit Share Pt Chg (BPS)',
	DOLLAR_SHARE: '$ Share Pt Chg (BPS)',
	EQ_SHARE: 'EQ Share Pt Chg (BPS)',

	REMAINING_MARKET: 'Remaining Market',
	WALMART: 'Walmart Market',
	SALES: 'Sales',
	UNITS: 'Units',
	SALES_PT_CHANGE: '% Chg',

	MARKET_SHARE: 'Share',
	SHARE_PT_CHG: 'Share Pt Chg (BPS)',

	CONTRI_PER: 'Contribution ',
	PT_CHG_BPS: 'Contribution Pt Chg',

	DOLLAR_SHARE_PT_CHG: ' Value Of Share Pt Chg',

	TOP_10: 'Top 10',
	BOTTOM_10: 'Bottom 10',

	CONTRIBUTION_DRIVERS: '$ Contribution Drivers',
	VALUE_CURRENT: 'Value Current',
	PERIOD_END_DATE: 'Period End Date: ',
	GAINED: ' gained',
	LOST: ' lost',
	DUETO: ' due to change in the number of ',
	CHART_BOTTOM_LEGEND:
		'*Panel amounts are used to illustrate relative impact of each driver and do not reflect actual sales values.'
};

export const FILE_TYPES = {
	EXCEL: 'excel',
	CSV: 'csv',
	PPT: 'ppt'
};

export const DASHBOARD_NAME = {
	KPI: '',
	PANEL: 'CONTRIBUTIONDRIVERS',
	QUADRANT: 'PERFORMANCEQUADRAN',
	PROD_PERFORMANCE: 'PRODUCTPERFORMANCE'
};

export const SELECTOR_HOVER_TEXT = {
	PRODUCT_MAIN: 'Choose the Product you would like to show in all the reports',
	PRODUCT: 'Choose the Product you would like to show in this report',
	MARKET: 'Choose the Walmart Market you would like to show in this report',
	PARENTCOMPANY: 'Choose the Brand Owner High you would like to show in this report',
	BRANDOWNER: 'Choose the Brand Owner you would like to show in this report',
	LEVEL: 'Choose the By level breakdown you would like to show in this report',
	BASEFACT: 'Choose the Base fact you would like to show in this report',
	PERIOD: 'Choose the Period you would like to show in this report',
	BYPRODLEVEL: 'Choose the by level you would like to show in this report'
};
export const REPORT_KEY_MAPPING = {
	TOTAL_BUSINESS: 'totalBusiness',
	MAJOR_BUSINESS: 'majorBusiness',
	DEPARTMENT: 'department',
	CATEGORY_GROUP: 'categoryGroup',
	CATEGORY: 'category',
	SUBCATEGORY: 'subCategory',
	SEGMENT: 'segment',
	BRAND_OWNER_HIGH: 'brandOwnerHigh',
	BRAND_OWNER_LOW: 'brandOwner',
	BRAND_HIGH: 'brandHigh',
	BRAND_FAMILY: 'brandFamily',
	BRAND_LOW: 'brandLow',
	BRAND: 'brandType'
};
export const LEAKAGE_DASH = {
	TITLE: ' Leakage Tree',
	SELECTOR_HOVER_TEXT: {
		PRODUCT: 'Choose the Product you would like to show in this report',
		LEAKBYTYPE: 'Choose the leakage By Type you would like to show in this report',
		LEAKAGEBY: 'Choose to show leakage by All Retailers,Channel or Individual Retailer',
		MARKET: "Choose the CMA's you would like to show in this report",
		PERIOD: 'Choose the Period you would like to show in this report',
		COMPARISON_PERIOD: 'Choose the comparison time period you would like to show in this report'
	},
	// NEED TO UPDATE THIS CONSTANT (CHECK IN NEXT RELEASE)
	LEAKAGE_BY_TYPE: [
		{
			id: 1,
			key: 'Channels',
			code: 'Channels',
			name: 'Channels',
			value: 'Channels',
			isSelected: false
		},
		{
			id: 2,
			key: 'Retailers',
			code: 'Retailers',
			name: 'Retailers',
			value: 'Retailers',
			isSelected: false
		}
	]
};
//           export const CATEGORY_TREND: {
// 		TITLE: " CATEGORY TRENDS",
// 		SELECTOR_HOVER_TEXT: {
// 			PRODUCT: "Choose the product you would like to show in this report",
// 			BASEFACT: "Choose the Base fact you would like to show in this report",
// 			MARKET: "Choose the Walmart Market you would like to show in this report",
// 			PERIOD: "Choose the Period you would like to show in this report",
// 		},
// 		LEAKAGE_BY_TYPE: [
// 			{ id: 1, name: "Total Outlets" },
// 			{ id: 2, name: "Channel" },
// 			{ id: 3, name: "Retailer" },
// 		],
// 	}

//             export const NEW_USER_REGISTRATION_FORM: {
// 		ORGANIZATION_INFO_TEXT:
// 			"Unlisted organizations have not yet been stood up in OVT. so individual users cannot be added. Please submit " +
// 			"a ticket through our support button if you think your organization has been excluded in error. ",
// 	}
// 	PRODUCT_SELECTOR_LEVELS: [
// 		{ key: "TOTAL_BUSINESS", value: "NA" },
// 		{ key: "MAJOR_BUSINESS", value: "NA" },
// 		{ key: "DEPARTMENT", value: "NA" },
// 		{ key: "CATEGORY_GROUP", value: "NA" },
// 		{ key: "CATEGORY", value: "NA" },
// 		{ key: "SUBCATEGORY", value: "NA" },
// 		{ key: "SEGMENT", value: "NA" },
// 		{ key: "BRAND_OWNER_HIGH", value: "NA" },
// 		{ key: "BRAND_OWNER_LOW", value: "NA" },
// 		{ key: "BRAND_HIGH", value: "NA" },
// 		{ key: "BRAND_FAMILY", value: "NA" },
// 		{ key: "BRAND_LOW", value: "NA" },
// 		{ key: "BRAND", value: "NA" },
// 	],
export const SALES_DRIVER_DASH = {
	DASHBOARD_TITLE: ' SALES DRIVERS',
	OPT_VAL: [
		{ key: 'DEPARTMENT', value: 'NA' },
		{ key: 'CATEGORY_GROUP', value: 'NA' },
		{ key: 'CATEGORY', value: 'NA' },
		{ key: 'SUBCATEGORY', value: 'NA' },
		{ key: 'SEGMENT', value: 'NA' },
		{ key: 'BRAND_OWNER_HIGH', value: 'NA' },
		{ key: 'BRAND_OWNER_LOW', value: 'NA' },
		{ key: 'BRAND_HIGH', value: 'NA' },
		{ key: 'BRAND_FAMILY', value: 'NA' },
		{ key: 'BRAND_LOW', value: 'NA' },
		{ key: 'TOTAL_BUSINESS', value: 'NA' },
		{ key: 'MAJOR_BUSINESS', value: 'NA' },
		{ key: 'BRAND', value: 'NA' }
	],
	TABLE_COLS: [
		{
			valuebase: 'Other',
			gkey: 'Products',
			name: 'Products',
			factName: 'Products',
			units_display_name: 'Products',
			headerName: 'Products',
			field: 'productName',
			cellTooltip: 'toolTip',
			width: 200,
			pinnedLeft: true,
			enablePinning: true,
			cellClass: 'gridProductName',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false,
			enableColumnMoving: false
		},
		{
			valuebase: 'Other',
			gkey: 'Product Description',
			name: 'Product Description',
			factName: 'Product Description',
			units_display_name: 'Product Description',
			headerName: 'Product Description',
			field: 'productDescription',
			cellTooltip: 'toolTipProdDesc',
			width: 200,
			pinnedLeft: true,
			enablePinning: true,
			cellClass: 'special special-header-left ',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},

		{
			valuebase: 'Other',
			gkey: 'TM Number of UPCs Selling',
			name: 'TM Number of UPCs Selling',
			factName: 'TM Number of UPCs Selling',
			units_display_name: 'TM Number of UPCs Selling',
			headerName: 'TM Number of UPCs Selling',
			field: 'tmupCount',
			type: 'number',
			cellTooltip: 'Total Market Number of UPCs Selling',
			width: 170,
			pinnedLeft: true,
			enablePinning: true,
			'text-align': 'center',
			cellClass: 'gridCountofUPC',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false,
			enableColumnMoving: false,
			cellTemplate:
				'<div id="divCountoftmupc"   class="ui-grid-cell-contents" ng-click="grid.appScope.countofUPCClicked(row,col)" title="Total Market Number of UPCs Selling">{{ row.entity.tmupCount | zip:"roundeddollar"}}</div>'
		},

		{
			valuebase: 'Other',
			gkey: 'WM Number of UPCs Selling',
			name: 'WM Number of UPCs Selling',
			factName: 'WM Number of UPCs Selling',
			units_display_name: 'WM Number of UPCs Selling',
			headerName: 'WM Number of UPCs Selling',
			field: 'upCount',
			type: 'number',
			cellTooltip: 'Walmart Number of UPCs Selling',
			width: 170,
			'text-align': 'center',
			cellClass: 'gridCountofUPC',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false,
			cellTemplate:
				'<div id="divCountofupc"   class="ui-grid-cell-contents" ng-click="grid.appScope.countofUPCClicked(row,col)" title="Walmart Number of UPCs Selling">{{ row.entity.upCount | zip:"roundeddollar" }}</div>'
		},

		{
			valuebase: 'Other',
			gkey: 'RM Number of UPCs Selling',
			name: 'RM Number of UPCs Selling',
			factName: 'RM Number of UPCs Selling',
			units_display_name: 'RM Number of UPCs Selling',
			headerName: 'RM Number of UPCs Selling',
			field: 'rmupCount',
			type: 'number',
			cellTooltip: 'Remaining Market Number of UPCs Selling',
			width: 170,
			'text-align': 'center',
			cellClass: 'gridCountofUPC',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false,
			cellTemplate:
				'<div id="divCountofupc"   class="ui-grid-cell-contents" ng-click="grid.appScope.countofUPCClicked(row,col)" title="Remaining Market Number of UPCs Selling">{{ row.entity.rmupCount | zip:"roundeddollar" }}</div>'
		},

		{
			valuebase: 'Dollars',
			gkey: 'WM Share',
			name: 'WM $ Share',
			factName: 'WM $ Share',
			units_display_name: 'WM Units Share',
			headerName: 'WM $ Share',
			field: 'marketShare',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Share Pt Chg (BPS) vs YA',
			name: 'WM $ Share Pt Chg (BPS) vs YA',
			factName: 'WM $ Share Pt Chg (BPS) vs YA',
			units_display_name: 'WM Units Share Pt Chg (BPS) vs YA',
			field: 'sharePtChange',
			type: 'number',
			headerName: 'WM $ Share Pt Chg (BPS) vs YA',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Share Pt Chg (BPS) vs 2YA',
			name: 'WM $ Share Pt Chg (BPS) vs 2YA',
			factName: 'WM $ Share Pt Chg (BPS) vs 2YA',
			units_display_name: 'WM Units Share Pt Chg (BPS) vs 2YA',
			field: 'sharePtChangeTwoYA',
			type: 'number',
			headerName: 'WM $ Share Pt Chg (BPS) vs 2YA',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Value of Share Pt Chg vs YA',
			name: 'WM $ Value of Share Pt Chg vs YA',
			factName: 'WM $ Value of Share Pt Chg vs YA',
			units_display_name: 'WM Units Value of Share Pt Chg vs YA',
			field: 'valueOfSharePointChange',
			type: 'number',
			headerName: 'WM $ Value of Share Pt Chg vs YA',
			width: 200,
			cellFilter: "zip:'dollar'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false,
			sort: { direction: 'desc' }
		}, //sorting
		{
			valuebase: 'Dollars',
			gkey: 'WM Value of Share Pt Chg vs 2YA',
			name: 'WM $ Value of Share Pt Chg vs 2YA',
			factName: 'WM $ Value of Share Pt Chg vs 2YA',
			units_display_name: 'WM Units Value of Share Pt Chg vs 2YA',
			field: 'valueOfSharePointChangeTwoYA',
			type: 'number',
			headerName: 'WM $ Value of Share Pt Chg vs 2YA',
			width: 200,
			cellFilter: "zip:'dollar'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		}, //sorting
		{
			valuebase: 'Dollars',
			gkey: 'WM Sales',
			name: 'WM $ Sales',
			factName: 'WM $ Sales',
			units_display_name: 'WM Units',
			headerName: 'WM $ Sales',
			field: 'walmartSales',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Sales % Chg vs YA',
			name: 'WM $ Sales % Chg vs YA',
			factName: 'WM $ Sales % Chg vs YA',
			units_display_name: 'WM Units Sales % Chg vs YA',
			headerName: 'WM $ Sales % Chg vs YA',
			field: 'walmartSalesChange',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Sales % Chg vs 2YA',
			name: 'WM $ Sales % Chg vs 2YA',
			factName: 'WM $ Sales % Chg vs 2YA',
			units_display_name: 'WM Units Sales % Chg vs 2YA',
			headerName: 'WM $ Sales % Chg vs 2YA',
			field: 'walmartSalesChangeTwoYA',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},

		{
			valuebase: 'Units',
			gkey: 'WM Share',
			name: 'WM Units Share',
			factName: 'WM Units Share',
			units_display_name: 'WM Units Share',
			headerName: 'WM $ Share',
			field: 'marketUnitShare',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Share Pt Chg (BPS) vs YA',
			name: 'WM Units Share Pt Chg (BPS) vs YA',
			factName: 'WM Units Share Pt Chg (BPS) vs YA',
			units_display_name: 'WM Units Share Pt Chg (BPS) vs YA',
			field: 'sharePtUnitChange',
			type: 'number',
			headerName: 'WM $ Share Pt Chg (BPS)  vs YA',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Share Pt Chg (BPS) vs 2YA',
			name: 'WM Units Share Pt Chg (BPS) vs 2YA',
			factName: 'WM Units Share Pt Chg (BPS) vs 2YA',
			units_display_name: 'WM Units Share Pt Chg (BPS) vs 2YA',
			field: 'sharePtUnitChangeTwoYA',
			type: 'number',
			headerName: 'WM $ Share Pt Chg (BPS) vs 2YA',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Value of Share Pt Chg vs YA',
			name: 'WM Units Value of Share Pt Chg vs YA',
			factName: 'WM Units Value of Share Pt Chg vs YA',
			units_display_name: 'WM Units Value of Share Pt Chg vs YA',
			field: 'valueOfUnitSharePointChange',
			type: 'number',
			headerName: 'WM $ Value of Share Pt Chg vs YA',
			width: 200,
			cellFilter: "zip:'dollar'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		}, //sorting
		{
			valuebase: 'Units',
			gkey: 'WM Value of Share Pt Chg vs 2YA',
			name: 'WM Units Value of Share Pt Chg vs 2YA',
			factName: 'WM Units Value of Share Pt Chg vs 2YA',
			units_display_name: 'WM Units Value of Share Pt Chg vs 2YA',
			field: 'valueOfUnitSharePointChangeTwoYA',
			type: 'number',
			headerName: 'WM $ Value of Share Pt Chg vs 2YA',
			width: 200,
			cellFilter: "zip:'dollar'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		}, //sorting
		{
			valuebase: 'Units',
			gkey: 'WM Sales',
			name: 'WM Units',
			factName: 'WM Units Sales',
			units_display_name: 'WM Units',
			headerName: 'WM Units',
			field: 'walmartUnitSales',
			type: 'number',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Sales % Chg vs YA',
			name: 'WM Units % Chg vs YA',
			factName: 'WM Units Sales % Chg vs YA',
			units_display_name: 'WM Unit % Chg vs YA',
			headerName: 'WM $ Sales % Chg vs YA',
			field: 'walmartUnitSalesChange',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Sales % Chg vs 2YA',
			name: 'WM Units % Chg vs 2YA',
			factName: 'WM Units Sales % Chg vs 2YA',
			units_display_name: 'WM Unit % Chg vs 2YA',
			headerName: 'WM $ Sales % Chg vs 2YA',
			field: 'walmartUnitSalesChangeTwoYA',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},

		{
			valuebase: 'Other',
			gkey: 'WM % ACV',
			name: 'WM %ACV (UPC level data only)',
			factName: 'WM %ACV (UPC level data only)',
			units_display_name: 'WM %ACV (UPC level data only)',
			field: 'walmartPerACV',
			headerName: 'WM %ACV (UPC level data only)',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'RM % ACV',
			name: 'RM %ACV (UPC level data only)',
			factName: 'RM %ACV (UPC level data only)',
			units_display_name: 'RM %ACV (UPC level data only)',
			field: 'remainingMarketPerACV',
			headerName: 'RM %ACV (UPC level data only)',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},

		{
			valuebase: 'Dollars',
			gkey: 'WM Sales Actual Chg vs YA',
			name: 'WM $ Sales Actual Chg vs YA',
			factName: 'WM $ Sales Actual Chg vs YA',
			units_display_name: 'WM Units Actual Chg vs YA',
			headerName: 'WM $ Sales Actual Chg vs YA',
			field: 'walmartSalesActualChange',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Sales Actual Chg vs 2YA',
			name: 'WM $ Sales Actual Chg vs 2YA',
			factName: 'WM $ Sales Actual Chg vs 2YA',
			units_display_name: 'WM Units Actual Chg vs 2YA',
			headerName: 'WM $ Sales Actual Chg vs 2YA',
			field: 'walmartSalesActualChangeTwoYA',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Sales/TDP',
			name: 'WM $/TDP',
			factName: 'WM $/TDP',
			units_display_name: 'WM Units/TDP',
			field: 'walmartTDP',
			headerName: 'WM $/TDP',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Sales/TDP % Chg',
			name: 'WM $/TDP % Chg',
			factName: 'WM $/TDP % Chg',
			units_display_name: 'WM Units/TDP % Chg',
			field: 'walmartTDPChange',
			headerName: 'WM $/TDP % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Contribution',
			name: 'WM $ Contribution',
			factName: 'WM $ Contribution',
			units_display_name: 'WM Units Contribution',
			field: 'walmartContribution',
			headerName: 'WM $ Contribution',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Contribution Pt Chg (BPS)',
			name: 'WM $ Contribution Pt Chg (BPS)',
			factName: 'WM $ Contribution Pt Chg (BPS)',
			units_display_name: 'WM Units Contribution Pt Chg (BPS)',
			field: 'walmartContributionPointChange',
			headerName: 'WM $ Contribution Pt Chg (BPS)',
			type: 'number',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Any Promo',
			name: 'WM $ Any Promo',
			factName: 'WM Any Promo $',
			units_display_name: 'WM Any Promo Units',
			field: 'walmartAnyPromo',
			headerName: 'WM $ Any Promo',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Any Promo % Chg',
			name: 'WM $ Any Promo % Chg',
			factName: 'WM Any Promo $ % Chg',
			units_display_name: 'WM Any Promo Units % Chg',
			field: 'walmartAnyPromoPerChange',
			headerName: 'WM $ Any Promo % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM No Promo',
			name: 'WM $ No Promo',
			factName: 'WM No Promo $',
			units_display_name: 'WM No Promo Units',
			field: 'walmartNoPromo',
			headerName: 'WM $ No Promo',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM No Promo % Chg',
			name: 'WM $ No Promo % Chg',
			factName: 'WM No Promo $ % Chg',
			units_display_name: 'WM No Promo Units % Chg',
			field: 'walmartNoPromoPerChange',
			headerName: 'WM $ No Promo % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Feature and Display',
			name: 'WM $ Feature and Display',
			factName: 'WM Feature and Display $',
			units_display_name: 'WM Feature and Display Units',
			field: 'walmartFeatureAndDisplay',
			headerName: 'WM $ Feature and Display',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Feature and Display % Chg',
			name: 'WM $ Feature and Display % Chg',
			factName: 'WM Feature and Display $ % Chg',
			units_display_name: 'WM Feature and Display Units % Chg',
			field: 'walmartFeatureAndDisplayPerChange',
			headerName: 'WM $ Feature and Display % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Display w/o Feature',
			name: 'WM $ Display w/o Feature',
			factName: 'WM Display w/o Feature $',
			units_display_name: 'WM Display w/o Feature Units',
			field: 'walmartDisplayWithOutFeature',
			headerName: 'WM $ Display w/o Feature',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Display w/o Feature % Chg',
			name: 'WM $ Display w/o Feature % Chg',
			factName: 'WM Display w/o Feature $ % Chg',
			units_display_name: 'WM Display w/o Feature Units % Chg',
			field: 'walmartDisplayWithOutFeaturePerChange',
			headerName: 'WM $ Display w/o Feature % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Feature w/o Display',
			name: 'WM $ Feature w/o Display',
			factName: 'WM Feature w/o Display $',
			units_display_name: 'WM Feature w/o Display Units',
			field: 'walmartFeatureWithoutDisplay',
			headerName: 'WM $ Feature w/o Display',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Feature w/o Display % Chg',
			name: 'WM $ Feature w/o Display % Chg',
			factName: 'WM Feature w/o Display $ % Chg',
			units_display_name: 'WM Feature w/o Display Units % Chg',
			field: 'walmartFeatureWithoutDisplayPerChange',
			headerName: 'WM $ Feature w/o Display % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Price Decrease',
			name: 'WM $ Price Decrease',
			factName: 'WM Price Decrease $',
			units_display_name: 'WM Price Decrease Units',
			field: 'walmartPriceDecrease',
			headerName: 'WM $ Price Decrease',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Price Decrease % Chg',
			name: 'WM $ Price Decrease % Chg',
			factName: 'WM Price Decrease $ % Chg',
			units_display_name: 'WM Price Decrease Units % Chg',
			field: 'walmartPriceDecreasePerChange',
			headerName: 'WM $ Price Decrease % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},

		{
			valuebase: 'Units',
			gkey: 'WM Sales Actual Chg vs YA',
			name: 'WM Units Actual Chg vs YA',
			factName: 'WM Units Actual Chg vs YA',
			units_display_name: 'WM Units Actual Chg vs YA',
			headerName: 'WM $ Sales Actual Chg vs YA',
			field: 'walmartUnitSalesActualChange',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Sales Actual Chg vs 2YA',
			name: 'WM Units Actual Chg vs 2YA',
			factName: 'WM Units Actual Chg vs 2YA',
			units_display_name: 'WM Units Actual Chg vs 2YA',
			headerName: 'WM $ Sales Actual Chg vs 2YA',
			field: 'walmartUnitSalesActualChangeTwoYA',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Sales/TDP',
			name: 'WM Units/TDP',
			factName: 'WM Units/TDP',
			units_display_name: 'WM Units/TDP',
			field: 'walmartUnitTDP',
			headerName: 'WM $/TDP',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Sales/TDP % Chg',
			name: 'WM Units/TDP % Chg',
			factName: 'WM Units/TDP % Chg',
			units_display_name: 'WM Units/TDP % Chg',
			field: 'walmartUnitTDPChange',
			headerName: 'WM $/TDP % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Contribution',
			name: 'WM Units Contribution',
			factName: 'WM Units Contribution',
			units_display_name: 'WM Units Contribution',
			field: 'walmartUnitContribution',
			headerName: 'WM $ Contribution',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Contribution Pt Chg (BPS)',
			name: 'WM Units Contribution Pt Chg (BPS)',
			factName: 'WM Units Contribution Pt Chg (BPS)',
			units_display_name: 'WM Units Contribution Pt Chg (BPS)',
			field: 'walmartUnitContributionPointChange',
			headerName: 'WM $ Contribution Pt Chg (BPS)',
			type: 'number',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Any Promo',
			name: 'WM Units Any Promo',
			factName: 'WM Any Promo Units',
			units_display_name: 'WM Units Any Promo',
			field: 'walmartAnyPromoUnit',
			headerName: 'WM Any Promo $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Any Promo % Chg',
			name: 'WM Units Any Promo % Chg',
			factName: 'WM Any Promo Units % Chg',
			units_display_name: 'WM Units Any Promo % Chg',
			field: 'walmartAnyPromoUnitPerChange',
			headerName: 'WM Any Promo $ % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM No Promo',
			name: 'WM Units No Promo',
			factName: 'WM No Promo Units',
			units_display_name: 'WM Units No Promo',
			field: 'walmartNoPromoUnit',
			headerName: 'WM No Promo $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM No Promo % Chg',
			name: 'WM Units No Promo % Chg',
			factName: 'WM No Promo Units % Chg',
			units_display_name: 'WM Units No Promo % Chg',
			field: 'walmartNoPromoUnitPerChange',
			headerName: 'WM No Promo $ % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Feature and Display',
			name: 'WM Units Feature and Display',
			factName: 'WM Feature and Display Units',
			units_display_name: 'WM Units Feature and Display',
			field: 'walmartFeatureAndDisplayUnit',
			headerName: 'WM Feature and Display $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Feature and Display % Chg',
			name: 'WM Units Feature and Display % Chg',
			factName: 'WM Feature and Display Units % Chg',
			units_display_name: 'WM Units Feature and Display % Chg',
			field: 'walmartFeatureAndDisplayUnitPerChange',
			headerName: 'WM Feature and Display $ % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Display w/o Feature',
			name: 'WM Units Display w/o Feature',
			factName: 'WM Display w/o Feature Units',
			units_display_name: 'WM Units Display w/o Feature',
			field: 'walmartDisplayWithOutFeatureUnit',
			headerName: 'WM Display w/o Feature $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Display w/o Feature % Chg',
			name: 'WM Units Display w/o Feature % Chg',
			factName: 'WM Display w/o Feature Units % Chg',
			units_display_name: 'WM Units Display w/o Feature % Chg',
			field: 'walmartDisplayWithOutFeatureUnitPerChange',
			headerName: 'WM Display w/o Feature $ % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Feature w/o Display',
			name: 'WM Units Feature w/o Display',
			factName: 'WM Feature w/o Display Units',
			units_display_name: 'WM Units Feature w/o Display',
			field: 'walmartFeatureWithoutDisplayUnit',
			headerName: 'WM Feature w/o Display $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Feature w/o Display % Chg',
			name: 'WM Units Feature w/o Display % Chg',
			factName: 'WM Feature w/o Display Units % Chg',
			units_display_name: 'WM Units Feature w/o Display % Chg',
			field: 'walmartFeatureWithoutDisplayUnitPerChange',
			headerName: 'WM Feature w/o Display $ % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Price Decrease',
			name: 'WM Units Price Decrease',
			factName: 'WM Price Decrease Units',
			units_display_name: 'WM Units Price Decrease',
			field: 'walmartPriceDecreaseUnit',
			headerName: 'WM Price Decrease $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Price Decrease % Chg',
			name: 'WM Units Price Decrease % Chg',
			factName: 'WM Price Decrease Units % Chg',
			units_display_name: 'WM Units Price Decrease % Chg',
			field: 'walmartPriceDecreaseUnitPerChange',
			headerName: 'WM Price Decrease $ % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},

		{
			valuebase: 'Other',
			gkey: 'WM TDP Share (%)',
			name: 'WM TDP Share (%)',
			factName: 'WM TDP Share (%)',
			units_display_name: 'WM TDP Share (%)',
			field: 'walmartTDPShare',
			headerName: 'WM TDP Share (%)',
			type: 'number',
			width: 200,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'WM TDP Share Pt Chg (BPS)',
			name: 'WM TDP Share Pt Chg (BPS)',
			factName: 'WM TDP Share Pt Chg (BPS)',
			units_display_name: 'WM TDP Share Pt Chg (BPS)',
			field: 'walmartTDPSharePointChange',
			headerName: 'WM TDP Share Pt Chg (BPS)',
			type: 'number',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'WM Avg Unit Price',
			name: 'WM Avg Unit Price',
			factName: 'WM Avg Unit Price',
			units_display_name: 'WM Avg Unit Price',
			field: 'walmartAverageUnitPrice',
			headerName: 'WM Avg Unit Price',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar2decimal'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'WM Avg Unit Price Pt Chg',
			name: 'WM Avg Unit Price % Chg',
			factName: 'WM Avg Unit Price % Chg',
			units_display_name: 'WM Avg Unit Price % Chg',
			field: 'walmartUnitPriceChange',
			headerName: 'WM Avg Unit Price % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},

		{
			valuebase: 'Dollars',
			gkey: 'RM Sales',
			name: 'RM $ Sales',
			factName: 'RM $ Sales',
			units_display_name: 'RM Units Sales',
			headerName: 'RM $ Sales',
			field: 'remainingMarketSales',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Sales % Chg vs YA',
			name: 'RM $ Sales % Chg vs YA',
			factName: 'RM $ Sales % Chg vs YA',
			units_display_name: 'RM Units % Chg vs YA',
			headerName: 'RM $ Sales % Chg vs YA',
			field: 'remainingMarketSalesChange',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Sales % Chg vs 2YA',
			name: 'RM $ Sales % Chg vs 2YA',
			factName: 'RM $ Sales % Chg vs 2YA',
			units_display_name: 'RM Units % Chg vs 2YA',
			headerName: 'RM $ Sales % Chg vs 2YA',
			field: 'remainingMarketSalesChangeTwoYA',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Sales Actual Chg vs YA',
			name: 'RM $ Sales Actual Chg vs YA',
			factName: 'RM $ Sales Actual Chg vs YA',
			units_display_name: 'RM Units Actual Chg vs YA',
			headerName: 'RM $ Sales Actual Chg vs YA',
			field: 'remainingMarketSalesActualChange',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Sales Actual Chg vs 2YA',
			name: 'RM $ Sales Actual Chg vs 2YA',
			factName: 'RM $ Sales Actual Chg vs 2YA',
			units_display_name: 'RM Units Actual Chg vs 2YA',
			headerName: 'RM $ Sales Actual Chg vs 2YA',
			field: 'remainingMarketSalesActualChangeTwoYA',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Sales/TDP',
			name: 'RM $/TDP',
			factName: 'RM $/TDP',
			units_display_name: 'RM Units/TDP',
			field: 'remainingMarketTDP',
			headerName: 'RM $/TDP',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Sales/TDP % Chg',
			name: 'RM $/TDP % Chg',
			factName: 'RM $/TDP % Chg',
			units_display_name: 'RM Units/TDP % Chg',
			field: 'remainingMarketTDPChange',
			headerName: 'RM $/TDP % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Contribution',
			name: 'RM $ Contribution',
			factName: 'RM $ Contribution',
			units_display_name: 'RM Units Contribution',
			field: 'remainingMarketContribution',
			headerName: 'RM $ Contribution',
			type: 'number',
			width: 200,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Contribution Pt Chg (BPS)',
			name: 'RM $ Contribution Pt Chg (BPS)',
			factName: 'RM $ Contribution Pt Chg (BPS)',
			units_display_name: 'RM Units Contribution Pt Chg (BPS)',
			field: 'remainingMarketContributionPointChange',
			headerName: 'RM $ Contribution Pt Chg (BPS)',
			type: 'number',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Any Promo',
			name: 'RM $ Any Promo',
			factName: 'RM Any Promo $',
			units_display_name: 'RM Any Promo Units',
			field: 'remainingAnyPromo',
			headerName: 'RM $ Any Promo',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Any Promo % Chg',
			name: 'RM $ Any Promo % Chg',
			factName: 'RM Any Promo $ % Chg',
			units_display_name: 'RM Any Promo Units % Chg',
			field: 'remainingAnyPromoPerChange',
			headerName: 'RM $ Any Promo % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM No Promo',
			name: 'RM $ No Promo',
			factName: 'RM No Promo $',
			units_display_name: 'RM No Promo Units',
			field: 'remainingNoPromo',
			headerName: 'RM $ No Promo',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM No Promo % Chg',
			name: 'RM $ No Promo % Chg',
			factName: 'RM No Promo $ % Chg',
			units_display_name: 'RM No Promo Units % Chg',
			field: 'remainingNoPromoPerChange',
			headerName: 'RM $ No Promo % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Feature and Display',
			name: 'RM $ Feature and Display',
			factName: 'RM Feature and Display $',
			units_display_name: 'RM Feature and Display Units',
			field: 'remainingFeatureAndDisplay',
			headerName: 'RM $ Feature and Display',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Feature and Display % Chg',
			name: 'RM $ Feature and Display % Chg',
			factName: 'RM Feature and Display $ % Chg',
			units_display_name: 'RM Feature and Display Units % Chg',
			field: 'remainingFeatureAndDisplayPerChange',
			headerName: 'RM $ Feature and Display % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Display w/o Feature',
			name: 'RM $ Display w/o Feature',
			factName: 'RM Display w/o Feature $',
			units_display_name: 'RM Display w/o Feature Units',
			field: 'remainingDisplayWithOutFeature',
			headerName: 'RM $ Display w/o Feature',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Display w/o Feature % Chg',
			name: 'RM $ Display w/o Feature % Chg',
			factName: 'RM Display w/o Feature $ % Chg',
			units_display_name: 'RM Display w/o Feature Units % Chg',
			field: 'remainingDisplayWithOutFeaturePerChange',
			headerName: 'RM $ Display w/o Feature % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Feature w/o Display',
			name: 'RM $ Feature w/o Display',
			factName: 'RM Feature w/o Display $',
			units_display_name: 'RM Feature w/o Display Units',
			field: 'remainingFeatureWithoutDisplay',
			headerName: 'RM $ Feature w/o Display',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Feature w/o Display % Chg',
			name: 'RM $ Feature w/o Display % Chg',
			factName: 'RM Feature w/o Display $ % Chg',
			units_display_name: 'RM Feature w/o Display Units % Chg',
			field: 'remainingFeatureWithoutDisplayPerChange',
			headerName: 'RM $ Feature w/o Display % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Price Decrease',
			name: 'RM $ Price Decrease',
			factName: 'RM Price Decrease $',
			units_display_name: 'RM Price Decrease Units',
			field: 'remainingPriceDecrease',
			headerName: 'RM $ Price Decrease',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Price Decrease % Chg',
			name: 'RM $ Price Decrease % Chg',
			factName: 'RM Price Decrease $ % Chg',
			units_display_name: 'RM Price Decrease Units % Chg',
			field: 'remainingPriceDecreasePerChange',
			headerName: 'RM $ Price Decrease % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Sales',
			name: 'RM Units',
			factName: 'RM Units Sales',
			units_display_name: 'RM Units',
			headerName: 'RM $ Sales',
			field: 'remainingMarketUnitSales',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Sales % Chg vs YA',
			name: 'RM Units % Chg vs YA',
			factName: 'RM Units Sales % Chg vs YA',
			units_display_name: 'RM Units % Chg vs YA',
			headerName: 'RM $ Sales % Chg vs YA',
			field: 'remainingMarketUnitSalesChange',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Sales % Chg vs 2YA',
			name: 'RM Units % Chg vs 2YA',
			factName: 'RM Units Sales % Chg vs 2YA',
			units_display_name: 'RM Units % Chg vs 2YA',
			headerName: 'RM $ Sales % Chg vs 2YA',
			field: 'remainingMarketUnitSalesChangeTwoYA',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Sales Actual Chg vs YA',
			name: 'RM Units Actual Chg vs YA',
			factName: 'RM Units Actual Chg vs YA',
			units_display_name: 'RM Units Actual Chg vs YA',
			headerName: 'RM $ Sales Actual Chg vs YA',
			field: 'remainingMarketUnitSalesActualChange',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Sales Actual Chg vs 2YA',
			name: 'RM Units Actual Chg vs 2YA',
			factName: 'RM Units Actual Chg vs 2YA',
			units_display_name: 'RM Units Actual Chg vs 2YA',
			headerName: 'RM $ Sales Actual Chg vs 2YA',
			field: 'remainingMarketUnitSalesActualChangeTwoYA',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Sales/TDP',
			name: 'RM Units/TDP',
			factName: 'RM Units/TDP',
			units_display_name: 'RM Units/TDP',
			field: 'remainingMarketUnitTDP',
			headerName: 'RM $/TDP',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Sales/TDP % Chg',
			name: 'RM Units/TDP % Chg',
			factName: 'RM Units/TDP % Chg',
			units_display_name: 'RM Units/TDP % Chg',
			field: 'remainingMarketUnitTDPChange',
			headerName: 'RM $/TDP % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Contribution',
			name: 'RM Units Contribution',
			factName: 'RM Units Contribution',
			units_display_name: 'RM Units Contribution',
			field: 'remainingMarketUnitContribution',
			headerName: 'RM $ Contribution',
			type: 'number',
			width: 200,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Contribution Pt Chg (BPS)',
			name: 'RM Units Contribution Pt Chg (BPS)',
			factName: 'RM Units Contribution Pt Chg (BPS)',
			units_display_name: 'RM Units Contribution Pt Chg (BPS)',
			field: 'remainingMarketUnitContributionPointChange',
			headerName: 'RM $ Contribution Pt Chg (BPS)',
			type: 'number',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Any Promo',
			name: 'RM Units Any Promo',
			factName: 'RM Any Promo Units',
			units_display_name: 'RM Units Any Promo',
			field: 'remainingAnyPromoUnit',
			headerName: 'RM Any Promo $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Any Promo % Chg',
			name: 'RM Units Any Promo % Chg',
			factName: 'RM Any Promo Units % Chg',
			units_display_name: 'RM Units Any Promo % Chg',
			field: 'remainingAnyPromoUnitPerChange',
			headerName: 'RM Any Promo $ % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM No Promo',
			name: 'RM Units No Promo',
			factName: 'RM No Promo Units',
			units_display_name: 'RM Units No Promo',
			field: 'remainingNoPromoUnit',
			headerName: 'RM No Promo $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM No Promo % Chg',
			name: 'RM Units No Promo % Chg',
			factName: 'RM No Promo Units % Chg',
			units_display_name: 'RM Units No Promo % Chg',
			field: 'remainingNoPromoUnitPerChange',
			headerName: 'RM No Promo $ % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Feature and Display',
			name: 'RM Units Feature and Display',
			factName: 'RM Feature and Display Units',
			units_display_name: 'RM Units Feature and Display',
			field: 'remainingFeatureAndDisplayUnit',
			headerName: 'RM Feature and Display $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Feature and Display % Chg',
			name: 'RM Units Feature and Display % Chg',
			factName: 'RM Feature and Display Units % Chg',
			units_display_name: 'RM Units Feature and Display % Chg',
			field: 'remainingFeatureAndDisplayUnitPerChange',
			headerName: 'RM Feature and Display $ % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Display w/o Feature',
			name: 'RM Units Display w/o Feature',
			factName: 'RM Display w/o Feature Units',
			units_display_name: 'RM Units Display w/o Feature',
			field: 'remainingDisplayWithOutFeatureUnit',
			headerName: 'RM Display w/o Feature $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Display w/o Feature % Chg',
			name: 'RM Units Display w/o Feature % Chg',
			factName: 'RM Display w/o Feature Units % Chg',
			units_display_name: 'RM Units Display w/o Feature % Chg',
			field: 'remainingDisplayWithOutFeatureUnitPerChange',
			headerName: 'RM Display w/o Feature $ % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Feature w/o Display',
			name: 'RM Units Feature w/o Display',
			factName: 'RM Feature w/o Display Units',
			units_display_name: 'RM Units Feature w/o Display',
			field: 'remainingFeatureWithoutDisplayUnit',
			headerName: 'RM Feature w/o Display $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Feature w/o Display % Chg',
			name: 'RM Units Feature w/o Display % Chg',
			factName: 'RM Feature w/o Display % Chg',
			units_display_name: 'RM Units Feature w/o Display % Chg',
			field: 'remainingFeatureWithoutDisplayUnitPerChange',
			headerName: 'RM Feature w/o Display $ % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Price Decrease',
			name: 'RM Units Price Decrease',
			factName: 'RM Price Decrease Units',
			units_display_name: 'RM Units Price Decrease',
			field: 'remainingPriceDecreaseUnit',
			headerName: 'RM Price Decrease $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Price Decrease % Chg',
			name: 'RM Units Price Decrease % Chg',
			factName: 'RM Price Decrease Units % Chg',
			units_display_name: 'RM Units Price Decrease % Chg',
			field: 'remainingPriceDecreaseUnitPerChange',
			headerName: 'RM Price Decrease $ % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'RM TDP Share (%)',
			name: 'RM TDP Share (%)',
			factName: 'RM TDP Share (%)',
			units_display_name: 'RM TDP Share (%)',
			field: 'remainingMarketTDPShare',
			headerName: 'RM TDP Share (%)',
			type: 'number',
			width: 200,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'RM TDP Share Pt Chg (BPS)',
			name: 'RM TDP Share Pt Chg (BPS)',
			factName: 'RM TDP Share Pt Chg (BPS)',
			units_display_name: 'RM TDP Share Pt Chg (BPS)',
			field: 'remainingMarketTDPSharePointChange',
			headerName: 'RM TDP Share Pt Chg (BPS)',
			type: 'number',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'RM Avg Unit Price',
			name: 'RM Avg Unit Price',
			factName: 'RM Avg Unit Price',
			units_display_name: 'RM Avg Unit Price',
			field: 'remainingMarketAverageUnitPrice',
			headerName: 'RM Avg Unit Price',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar2decimal'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'RM Avg Unit Price Pt Chg',
			name: 'RM Avg Unit Price % Chg',
			factName: 'RM Avg Unit Price % Chg',
			units_display_name: 'RM Avg Unit Price % Chg',
			field: 'remainingMarketUnitPriceChange',
			headerName: 'RM Avg Unit Price % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'TM Sales',
			name: 'TM $ Sales',
			factName: 'TM $ Sales',
			units_display_name: 'TM Units Sales',
			headerName: 'TM $ Sales',
			field: 'totalMarketSales',
			type: 'number',
			width: 170,
			cellClass: 'special',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'TM Sales % Chg vs YA',
			name: 'TM $ Sales % Chg vs YA',
			factName: 'TM $ Sales % Chg vs YA',
			units_display_name: 'TM Units % Chg vs YA',
			headerName: 'TM $ Sales % Chg vs YA',
			field: 'totalMarketSalesPerChange',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'perc'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'TM Sales % Chg vs 2YA',
			name: 'TM $ Sales % Chg vs 2YA',
			factName: 'TM $ Sales % Chg vs 2YA',
			units_display_name: 'TM Units % Chg vs 2YA',
			headerName: 'TM $ Sales % Chg vs 2YA',
			field: 'totalMarketSalesPerChangeTwoYA',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'perc'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'TM Sales Actual Chg vs YA',
			name: 'TM $ Sales Actual Chg vs YA',
			factName: 'TM $ Sales Actual Chg vs YA',
			units_display_name: 'TM Units Actual Chg vs YA',
			headerName: 'TM $ Sales Actual Chg vs YA',
			field: 'totalMarketSalesActualChange',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'TM Sales Actual Chg vs 2YA',
			name: 'TM $ Sales Actual Chg vs 2YA',
			factName: 'TM $ Sales Actual Chg vs 2YA',
			units_display_name: 'TM Units Actual Chg vs 2YA',
			headerName: 'TM $ Sales Actual Chg vs 2YA',
			field: 'totalMarketSalesActualChangeTwoYA',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'TM Sales',
			name: 'TM Units',
			factName: 'TM Units Sales',
			units_display_name: 'TM Units',
			headerName: 'TM $ Sales',
			field: 'totalMarketUnitSales',
			type: 'number',
			width: 170,
			cellClass: 'special',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'TM Sales % Chg vs YA',
			name: 'TM Units % Chg vs YA',
			factName: 'TM Units % Chg vs YA',
			units_display_name: 'TM Units % Chg vs YA',
			headerName: 'TM $ Sales % Chg vs YA',
			field: 'totalMarketUnitSalesPerChange',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'perc'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'TM Sales % Chg vs 2YA',
			name: 'TM Units % Chg vs 2YA',
			factName: 'TM Units % Chg vs 2YA',
			units_display_name: 'TM Units % Chg vs 2YA',
			headerName: 'TM $ Sales % Chg vs 2YA',
			field: 'totalMarketUnitSalesPerChangeTwoYA',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'perc'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'TM Sales Actual Chg vs YA',
			name: 'TM Units Actual Chg vs YA',
			factName: 'TM Units Actual Chg vs YA',
			units_display_name: 'TM Units Actual Chg vs YA',
			headerName: 'TM $ Sales Actual Chg vs YA',
			field: 'totalMarketUnitSalesActualChange',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'TM Sales Actual Chg vs 2YA',
			name: 'TM Units Actual Chg vs 2YA',
			factName: 'TM Units Actual Chg vs 2YA',
			units_display_name: 'TM Units Actual Chg vs 2YA',
			headerName: 'TM $ Sales Actual Chg vs 2YA',
			field: 'totalMarketUnitSalesActualChangeTwoYA',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		}
	],
	LEVEL_NAMES: {
		DEPARTMENT: 'Department',
		CATEGORY_GROUP: 'Category group',
		CATEGORY: 'Category',
		SUBCATEGORY: 'Sub category',
		SEGMENT: 'Segment',
		BRAND_OWNER_HIGH: 'Brand Owner High',
		BRAND_OWNER_LOW: 'Brand Owner',
		BRAND_HIGH: 'Brand High',
		BRAND_FAMILY: 'Brand Family',
		BRAND_LOW: 'Brand Low',
		TOTAL_BUSINESS: 'TOTAL BUSINESS',
		MAJOR_BUSINESS: 'MAJOR BUSINESS'
	},
	PERIOD_END_DATE: 'Period End Date: ',
	EXPORT_MODAL_NAME: 'SALESDRIVERS'
};

export const ASSORTMENT_DASHBOARD = {
	TITLE: ' ASSORTMENT 4x4',
	TABS_COMPLETE_LIST: [
		{ key: 'majorBusiness', value: 'Major Business', isVisible: false },
		{ key: 'department', value: 'Department', isVisible: false },
		{ key: 'categoryGroup', value: 'Category Group', isVisible: false },
		{ key: 'category', value: 'Category', isVisible: true },
		{ key: 'subCategory', value: 'Sub Category', isVisible: true },
		{ key: 'segment', value: 'Segment', isVisible: true },
		{ key: 'parentCompany', value: 'Brand Owner High', isVisible: true },
		{ key: 'brandOwner', value: 'Brand Owner', isVisible: true },
		{ key: 'brandFamily', value: 'Brand High', isVisible: true },
		{ key: 'brand', value: 'Brand Low', isVisible: true }
	],

	TABLE_COLS: [
		// {
		// 	valuebase: 'Other',
		// 	gkey: 'Products',
		// 	name: 'Products',
		// 	factName: 'Products',
		// 	units_display_name: 'Products',
		// 	headerName: 'Products',
		// 	field: 'productName',
		// 	cellTooltip: 'toolTip',
		// 	width: 200,
		// 	pinnedLeft: true,
		// 	enablePinning: true,
		// 	cellClass: 'gridProductName',
		// 	headerTooltip: 'headTootTip',
		// 	isSelected: true,
		// 	isPinned: false,
		// 	enableColumnMoving: false
		// },
		// {
		// 	valuebase: 'Other',
		// 	gkey: 'Product Description',
		// 	name: 'Product Description',
		// 	factName: 'Product Description',
		// 	units_display_name: 'Product Description',
		// 	headerName: 'Product Description',
		// 	field: 'productDescription',
		// 	cellTooltip: 'toolTipProdDesc',
		// 	width: 200,
		// 	pinnedLeft: true,
		// 	enablePinning: true,
		// 	cellClass: 'special special-header-left ',
		// 	headerTooltip: 'headTootTip',
		// 	isSelected: true,
		// 	isPinned: false
		// },

		{
			valuebase: 'Other',
			gkey: 'TM Number of UPCs Selling',
			name: 'TM Number of UPCs Selling',
			factName: 'TM Number of UPCs Selling',
			units_display_name: 'TM Number of UPCs Selling',
			headerName: 'TM Number of UPCs Selling',
			field: 'tmupCount',
			type: 'number',
			cellTooltip: 'Total Market Number of UPCs Selling',
			width: 170,
			pinnedLeft: true,
			enablePinning: true,
			'text-align': 'center',
			cellClass: 'gridCountofUPC',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false,
			enableColumnMoving: false,
			cellTemplate:
				'<div id="divCountoftmupc"   class="ui-grid-cell-contents" ng-click="grid.appScope.countofUPCClicked(row,col)" title="Total Market Number of UPCs Selling">{{ row.entity.tmupCount | zip:"roundeddollar"}}</div>'
		},

		{
			valuebase: 'Other',
			gkey: 'WM Number of UPCs Selling',
			name: 'WM Number of UPCs Selling',
			factName: 'WM Number of UPCs Selling',
			units_display_name: 'WM Number of UPCs Selling',
			headerName: 'WM Number of UPCs Selling',
			field: 'upCount',
			type: 'number',
			cellTooltip: 'Walmart Number of UPCs Selling',
			width: 170,
			'text-align': 'center',
			cellClass: 'gridCountofUPC',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false,
			cellTemplate:
				'<div id="divCountofupc"   class="ui-grid-cell-contents" ng-click="grid.appScope.countofUPCClicked(row,col)" title="Walmart Number of UPCs Selling">{{ row.entity.upCount | zip:"roundeddollar" }}</div>'
		},

		{
			valuebase: 'Other',
			gkey: 'RM Number of UPCs Selling',
			name: 'RM Number of UPCs Selling',
			factName: 'RM Number of UPCs Selling',
			units_display_name: 'RM Number of UPCs Selling',
			headerName: 'RM Number of UPCs Selling',
			field: 'rmupCount',
			type: 'number',
			cellTooltip: 'Remaining Market Number of UPCs Selling',
			width: 170,
			'text-align': 'center',
			cellClass: 'gridCountofUPC',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false,
			cellTemplate:
				'<div id="divCountofupc"   class="ui-grid-cell-contents" ng-click="grid.appScope.countofUPCClicked(row,col)" title="Remaining Market Number of UPCs Selling">{{ row.entity.rmupCount | zip:"roundeddollar" }}</div>'
		},

		{
			valuebase: 'Dollars',
			gkey: 'WM Share',
			name: 'WM $ Share',
			factName: 'WM $ Share',
			units_display_name: 'WM Units Share',
			headerName: 'WM $ Share',
			field: 'marketShare',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Share Pt Chg (BPS) vs YA',
			name: 'WM $ Share Pt Chg (BPS) vs YA',
			factName: 'WM $ Share Pt Chg (BPS) vs YA',
			units_display_name: 'WM Units Share Pt Chg (BPS) vs YA',
			field: 'sharePtChange',
			type: 'number',
			headerName: 'WM $ Share Pt Chg (BPS) vs YA',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Share Pt Chg (BPS) vs 2YA',
			name: 'WM $ Share Pt Chg (BPS) vs 2YA',
			factName: 'WM $ Share Pt Chg (BPS) vs 2YA',
			units_display_name: 'WM Units Share Pt Chg (BPS) vs 2YA',
			field: 'sharePtChangeTwoYA',
			type: 'number',
			headerName: 'WM $ Share Pt Chg (BPS) vs 2YA',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Value of Share Pt Chg vs YA',
			name: 'WM $ Value of Share Pt Chg vs YA',
			factName: 'WM $ Value of Share Pt Chg vs YA',
			units_display_name: 'WM Units Value of Share Pt Chg vs YA',
			field: 'valueOfSharePointChange',
			type: 'number',
			headerName: 'WM $ Value of Share Pt Chg vs YA',
			width: 200,
			cellFilter: "zip:'dollar'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false,
			sort: { direction: 'desc' }
		}, //sorting
		{
			valuebase: 'Dollars',
			gkey: 'WM Value of Share Pt Chg vs 2YA',
			name: 'WM $ Value of Share Pt Chg vs 2YA',
			factName: 'WM $ Value of Share Pt Chg vs 2YA',
			units_display_name: 'WM Units Value of Share Pt Chg vs 2YA',
			field: 'valueOfSharePointChangeTwoYA',
			type: 'number',
			headerName: 'WM $ Value of Share Pt Chg vs 2YA',
			width: 200,
			cellFilter: "zip:'dollar'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		}, //sorting
		{
			valuebase: 'Dollars',
			gkey: 'WM Sales',
			name: 'WM $ Sales',
			factName: 'WM $ Sales',
			units_display_name: 'WM Units',
			headerName: 'WM $ Sales',
			field: 'walmartSales',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Sales % Chg vs YA',
			name: 'WM $ Sales % Chg vs YA',
			factName: 'WM $ Sales % Chg vs YA',
			units_display_name: 'WM Units Sales % Chg vs YA',
			headerName: 'WM $ Sales % Chg vs YA',
			field: 'walmartSalesChange',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Sales % Chg vs 2YA',
			name: 'WM $ Sales % Chg vs 2YA',
			factName: 'WM $ Sales % Chg vs 2YA',
			units_display_name: 'WM Units Sales % Chg vs 2YA',
			headerName: 'WM $ Sales % Chg vs 2YA',
			field: 'walmartSalesChangeTwoYA',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},

		{
			valuebase: 'Units',
			gkey: 'WM Share',
			name: 'WM Units Share',
			factName: 'WM Units Share',
			units_display_name: 'WM Units Share',
			headerName: 'WM $ Share',
			field: 'marketUnitShare',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Share Pt Chg (BPS) vs YA',
			name: 'WM Units Share Pt Chg (BPS) vs YA',
			factName: 'WM Units Share Pt Chg (BPS) vs YA',
			units_display_name: 'WM Units Share Pt Chg (BPS) vs YA',
			field: 'sharePtUnitChange',
			type: 'number',
			headerName: 'WM $ Share Pt Chg (BPS)  vs YA',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Share Pt Chg (BPS) vs 2YA',
			name: 'WM Units Share Pt Chg (BPS) vs 2YA',
			factName: 'WM Units Share Pt Chg (BPS) vs 2YA',
			units_display_name: 'WM Units Share Pt Chg (BPS) vs 2YA',
			field: 'sharePtUnitChangeTwoYA',
			type: 'number',
			headerName: 'WM $ Share Pt Chg (BPS) vs 2YA',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Value of Share Pt Chg vs YA',
			name: 'WM Units Value of Share Pt Chg vs YA',
			factName: 'WM Units Value of Share Pt Chg vs YA',
			units_display_name: 'WM Units Value of Share Pt Chg vs YA',
			field: 'valueOfUnitSharePointChange',
			type: 'number',
			headerName: 'WM $ Value of Share Pt Chg vs YA',
			width: 200,
			cellFilter: "zip:'dollar'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		}, //sorting
		{
			valuebase: 'Units',
			gkey: 'WM Value of Share Pt Chg vs 2YA',
			name: 'WM Units Value of Share Pt Chg vs 2YA',
			factName: 'WM Units Value of Share Pt Chg vs 2YA',
			units_display_name: 'WM Units Value of Share Pt Chg vs 2YA',
			field: 'valueOfUnitSharePointChangeTwoYA',
			type: 'number',
			headerName: 'WM $ Value of Share Pt Chg vs 2YA',
			width: 200,
			cellFilter: "zip:'dollar'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		}, //sorting
		{
			valuebase: 'Units',
			gkey: 'WM Sales',
			name: 'WM Units',
			factName: 'WM Units Sales',
			units_display_name: 'WM Units',
			headerName: 'WM Units',
			field: 'walmartUnitSales',
			type: 'number',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Sales % Chg vs YA',
			name: 'WM Units % Chg vs YA',
			factName: 'WM Units Sales % Chg vs YA',
			units_display_name: 'WM Unit % Chg vs YA',
			headerName: 'WM $ Sales % Chg vs YA',
			field: 'walmartUnitSalesChange',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Sales % Chg vs 2YA',
			name: 'WM Units % Chg vs 2YA',
			factName: 'WM Units Sales % Chg vs 2YA',
			units_display_name: 'WM Unit % Chg vs 2YA',
			headerName: 'WM $ Sales % Chg vs 2YA',
			field: 'walmartUnitSalesChangeTwoYA',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},

		{
			valuebase: 'Other',
			gkey: 'WM % ACV',
			name: 'WM %ACV (UPC level data only)',
			factName: 'WM %ACV (UPC level data only)',
			units_display_name: 'WM %ACV (UPC level data only)',
			field: 'walmartPerACV',
			headerName: 'WM %ACV (UPC level data only)',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'RM % ACV',
			name: 'RM %ACV (UPC level data only)',
			factName: 'RM %ACV (UPC level data only)',
			units_display_name: 'RM %ACV (UPC level data only)',
			field: 'remainingMarketPerACV',
			headerName: 'RM %ACV (UPC level data only)',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},

		{
			valuebase: 'Dollars',
			gkey: 'WM Sales Actual Chg vs YA',
			name: 'WM $ Sales Actual Chg vs YA',
			factName: 'WM $ Sales Actual Chg vs YA',
			units_display_name: 'WM Units Actual Chg vs YA',
			headerName: 'WM $ Sales Actual Chg vs YA',
			field: 'walmartSalesActualChange',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Sales Actual Chg vs 2YA',
			name: 'WM $ Sales Actual Chg vs 2YA',
			factName: 'WM $ Sales Actual Chg vs 2YA',
			units_display_name: 'WM Units Actual Chg vs 2YA',
			headerName: 'WM $ Sales Actual Chg vs 2YA',
			field: 'walmartSalesActualChangeTwoYA',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Sales/TDP',
			name: 'WM $/TDP',
			factName: 'WM $/TDP',
			units_display_name: 'WM Units/TDP',
			field: 'walmartTDP',
			headerName: 'WM $/TDP',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Sales/TDP % Chg',
			name: 'WM $/TDP % Chg',
			factName: 'WM $/TDP % Chg',
			units_display_name: 'WM Units/TDP % Chg',
			field: 'walmartTDPChange',
			headerName: 'WM $/TDP % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Contribution',
			name: 'WM $ Contribution',
			factName: 'WM $ Contribution',
			units_display_name: 'WM Units Contribution',
			field: 'walmartContribution',
			headerName: 'WM $ Contribution',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Contribution Pt Chg (BPS)',
			name: 'WM $ Contribution Pt Chg (BPS)',
			factName: 'WM $ Contribution Pt Chg (BPS)',
			units_display_name: 'WM Units Contribution Pt Chg (BPS)',
			field: 'walmartContributionPointChange',
			headerName: 'WM $ Contribution Pt Chg (BPS)',
			type: 'number',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Any Promo',
			name: 'WM $ Any Promo',
			factName: 'WM Any Promo $',
			units_display_name: 'WM Any Promo Units',
			field: 'walmartAnyPromo',
			headerName: 'WM $ Any Promo',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Any Promo % Chg',
			name: 'WM $ Any Promo % Chg',
			factName: 'WM Any Promo $ % Chg',
			units_display_name: 'WM Any Promo Units % Chg',
			field: 'walmartAnyPromoPerChange',
			headerName: 'WM $ Any Promo % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM No Promo',
			name: 'WM $ No Promo',
			factName: 'WM No Promo $',
			units_display_name: 'WM No Promo Units',
			field: 'walmartNoPromo',
			headerName: 'WM $ No Promo',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM No Promo % Chg',
			name: 'WM $ No Promo % Chg',
			factName: 'WM No Promo $ % Chg',
			units_display_name: 'WM No Promo Units % Chg',
			field: 'walmartNoPromoPerChange',
			headerName: 'WM $ No Promo % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Feature and Display',
			name: 'WM $ Feature and Display',
			factName: 'WM Feature and Display $',
			units_display_name: 'WM Feature and Display Units',
			field: 'walmartFeatureAndDisplay',
			headerName: 'WM $ Feature and Display',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Feature and Display % Chg',
			name: 'WM $ Feature and Display % Chg',
			factName: 'WM Feature and Display $ % Chg',
			units_display_name: 'WM Feature and Display Units % Chg',
			field: 'walmartFeatureAndDisplayPerChange',
			headerName: 'WM $ Feature and Display % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Display w/o Feature',
			name: 'WM $ Display w/o Feature',
			factName: 'WM Display w/o Feature $',
			units_display_name: 'WM Display w/o Feature Units',
			field: 'walmartDisplayWithOutFeature',
			headerName: 'WM $ Display w/o Feature',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Display w/o Feature % Chg',
			name: 'WM $ Display w/o Feature % Chg',
			factName: 'WM Display w/o Feature $ % Chg',
			units_display_name: 'WM Display w/o Feature Units % Chg',
			field: 'walmartDisplayWithOutFeaturePerChange',
			headerName: 'WM $ Display w/o Feature % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Feature w/o Display',
			name: 'WM $ Feature w/o Display',
			factName: 'WM Feature w/o Display $',
			units_display_name: 'WM Feature w/o Display Units',
			field: 'walmartFeatureWithoutDisplay',
			headerName: 'WM $ Feature w/o Display',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Feature w/o Display % Chg',
			name: 'WM $ Feature w/o Display % Chg',
			factName: 'WM Feature w/o Display $ % Chg',
			units_display_name: 'WM Feature w/o Display Units % Chg',
			field: 'walmartFeatureWithoutDisplayPerChange',
			headerName: 'WM $ Feature w/o Display % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Price Decrease',
			name: 'WM $ Price Decrease',
			factName: 'WM Price Decrease $',
			units_display_name: 'WM Price Decrease Units',
			field: 'walmartPriceDecrease',
			headerName: 'WM $ Price Decrease',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Price Decrease % Chg',
			name: 'WM $ Price Decrease % Chg',
			factName: 'WM Price Decrease $ % Chg',
			units_display_name: 'WM Price Decrease Units % Chg',
			field: 'walmartPriceDecreasePerChange',
			headerName: 'WM $ Price Decrease % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},

		{
			valuebase: 'Units',
			gkey: 'WM Sales Actual Chg vs YA',
			name: 'WM Units Actual Chg vs YA',
			factName: 'WM Units Actual Chg vs YA',
			units_display_name: 'WM Units Actual Chg vs YA',
			headerName: 'WM $ Sales Actual Chg vs YA',
			field: 'walmartUnitSalesActualChange',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Sales Actual Chg vs 2YA',
			name: 'WM Units Actual Chg vs 2YA',
			factName: 'WM Units Actual Chg vs 2YA',
			units_display_name: 'WM Units Actual Chg vs 2YA',
			headerName: 'WM $ Sales Actual Chg vs 2YA',
			field: 'walmartUnitSalesActualChangeTwoYA',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Sales/TDP',
			name: 'WM Units/TDP',
			factName: 'WM Units/TDP',
			units_display_name: 'WM Units/TDP',
			field: 'walmartUnitTDP',
			headerName: 'WM $/TDP',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Sales/TDP % Chg',
			name: 'WM Units/TDP % Chg',
			factName: 'WM Units/TDP % Chg',
			units_display_name: 'WM Units/TDP % Chg',
			field: 'walmartUnitTDPChange',
			headerName: 'WM $/TDP % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Contribution',
			name: 'WM Units Contribution',
			factName: 'WM Units Contribution',
			units_display_name: 'WM Units Contribution',
			field: 'walmartUnitContribution',
			headerName: 'WM $ Contribution',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Contribution Pt Chg (BPS)',
			name: 'WM Units Contribution Pt Chg (BPS)',
			factName: 'WM Units Contribution Pt Chg (BPS)',
			units_display_name: 'WM Units Contribution Pt Chg (BPS)',
			field: 'walmartUnitContributionPointChange',
			headerName: 'WM $ Contribution Pt Chg (BPS)',
			type: 'number',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Any Promo',
			name: 'WM Units Any Promo',
			factName: 'WM Any Promo Units',
			units_display_name: 'WM Units Any Promo',
			field: 'walmartAnyPromoUnit',
			headerName: 'WM Any Promo $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Any Promo % Chg',
			name: 'WM Units Any Promo % Chg',
			factName: 'WM Any Promo Units % Chg',
			units_display_name: 'WM Units Any Promo % Chg',
			field: 'walmartAnyPromoUnitPerChange',
			headerName: 'WM Any Promo $ % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM No Promo',
			name: 'WM Units No Promo',
			factName: 'WM No Promo Units',
			units_display_name: 'WM Units No Promo',
			field: 'walmartNoPromoUnit',
			headerName: 'WM No Promo $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM No Promo % Chg',
			name: 'WM Units No Promo % Chg',
			factName: 'WM No Promo Units % Chg',
			units_display_name: 'WM Units No Promo % Chg',
			field: 'walmartNoPromoUnitPerChange',
			headerName: 'WM No Promo $ % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Feature and Display',
			name: 'WM Units Feature and Display',
			factName: 'WM Feature and Display Units',
			units_display_name: 'WM Units Feature and Display',
			field: 'walmartFeatureAndDisplayUnit',
			headerName: 'WM Feature and Display $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Feature and Display % Chg',
			name: 'WM Units Feature and Display % Chg',
			factName: 'WM Feature and Display Units % Chg',
			units_display_name: 'WM Units Feature and Display % Chg',
			field: 'walmartFeatureAndDisplayUnitPerChange',
			headerName: 'WM Feature and Display $ % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Display w/o Feature',
			name: 'WM Units Display w/o Feature',
			factName: 'WM Display w/o Feature Units',
			units_display_name: 'WM Units Display w/o Feature',
			field: 'walmartDisplayWithOutFeatureUnit',
			headerName: 'WM Display w/o Feature $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Display w/o Feature % Chg',
			name: 'WM Units Display w/o Feature % Chg',
			factName: 'WM Display w/o Feature Units % Chg',
			units_display_name: 'WM Units Display w/o Feature % Chg',
			field: 'walmartDisplayWithOutFeatureUnitPerChange',
			headerName: 'WM Display w/o Feature $ % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Feature w/o Display',
			name: 'WM Units Feature w/o Display',
			factName: 'WM Feature w/o Display Units',
			units_display_name: 'WM Units Feature w/o Display',
			field: 'walmartFeatureWithoutDisplayUnit',
			headerName: 'WM Feature w/o Display $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Feature w/o Display % Chg',
			name: 'WM Units Feature w/o Display % Chg',
			factName: 'WM Feature w/o Display Units % Chg',
			units_display_name: 'WM Units Feature w/o Display % Chg',
			field: 'walmartFeatureWithoutDisplayUnitPerChange',
			headerName: 'WM Feature w/o Display $ % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Price Decrease',
			name: 'WM Units Price Decrease',
			factName: 'WM Price Decrease Units',
			units_display_name: 'WM Units Price Decrease',
			field: 'walmartPriceDecreaseUnit',
			headerName: 'WM Price Decrease $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Price Decrease % Chg',
			name: 'WM Units Price Decrease % Chg',
			factName: 'WM Price Decrease Units % Chg',
			units_display_name: 'WM Units Price Decrease % Chg',
			field: 'walmartPriceDecreaseUnitPerChange',
			headerName: 'WM Price Decrease $ % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},

		{
			valuebase: 'Other',
			gkey: 'WM TDP Share (%)',
			name: 'WM TDP Share (%)',
			factName: 'WM TDP Share (%)',
			units_display_name: 'WM TDP Share (%)',
			field: 'walmartTDPShare',
			headerName: 'WM TDP Share (%)',
			type: 'number',
			width: 200,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'WM TDP Share Pt Chg (BPS)',
			name: 'WM TDP Share Pt Chg (BPS)',
			factName: 'WM TDP Share Pt Chg (BPS)',
			units_display_name: 'WM TDP Share Pt Chg (BPS)',
			field: 'walmartTDPSharePointChange',
			headerName: 'WM TDP Share Pt Chg (BPS)',
			type: 'number',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'WM Avg Unit Price',
			name: 'WM Avg Unit Price',
			factName: 'WM Avg Unit Price',
			units_display_name: 'WM Avg Unit Price',
			field: 'walmartAverageUnitPrice',
			headerName: 'WM Avg Unit Price',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar2decimal'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'WM Avg Unit Price Pt Chg',
			name: 'WM Avg Unit Price % Chg',
			factName: 'WM Avg Unit Price % Chg',
			units_display_name: 'WM Avg Unit Price % Chg',
			field: 'walmartUnitPriceChange',
			headerName: 'WM Avg Unit Price % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},

		{
			valuebase: 'Dollars',
			gkey: 'RM Sales',
			name: 'RM $ Sales',
			factName: 'RM $ Sales',
			units_display_name: 'RM Units Sales',
			headerName: 'RM $ Sales',
			field: 'remainingMarketSales',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Sales % Chg vs YA',
			name: 'RM $ Sales % Chg vs YA',
			factName: 'RM $ Sales % Chg vs YA',
			units_display_name: 'RM Units % Chg vs YA',
			headerName: 'RM $ Sales % Chg vs YA',
			field: 'remainingMarketSalesChange',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Sales % Chg vs 2YA',
			name: 'RM $ Sales % Chg vs 2YA',
			factName: 'RM $ Sales % Chg vs 2YA',
			units_display_name: 'RM Units % Chg vs 2YA',
			headerName: 'RM $ Sales % Chg vs 2YA',
			field: 'remainingMarketSalesChangeTwoYA',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Sales Actual Chg vs YA',
			name: 'RM $ Sales Actual Chg vs YA',
			factName: 'RM $ Sales Actual Chg vs YA',
			units_display_name: 'RM Units Actual Chg vs YA',
			headerName: 'RM $ Sales Actual Chg vs YA',
			field: 'remainingMarketSalesActualChange',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Sales Actual Chg vs 2YA',
			name: 'RM $ Sales Actual Chg vs 2YA',
			factName: 'RM $ Sales Actual Chg vs 2YA',
			units_display_name: 'RM Units Actual Chg vs 2YA',
			headerName: 'RM $ Sales Actual Chg vs 2YA',
			field: 'remainingMarketSalesActualChangeTwoYA',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Sales/TDP',
			name: 'RM $/TDP',
			factName: 'RM $/TDP',
			units_display_name: 'RM Units/TDP',
			field: 'remainingMarketTDP',
			headerName: 'RM $/TDP',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Sales/TDP % Chg',
			name: 'RM $/TDP % Chg',
			factName: 'RM $/TDP % Chg',
			units_display_name: 'RM Units/TDP % Chg',
			field: 'remainingMarketTDPChange',
			headerName: 'RM $/TDP % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Contribution',
			name: 'RM $ Contribution',
			factName: 'RM $ Contribution',
			units_display_name: 'RM Units Contribution',
			field: 'remainingMarketContribution',
			headerName: 'RM $ Contribution',
			type: 'number',
			width: 200,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Contribution Pt Chg (BPS)',
			name: 'RM $ Contribution Pt Chg (BPS)',
			factName: 'RM $ Contribution Pt Chg (BPS)',
			units_display_name: 'RM Units Contribution Pt Chg (BPS)',
			field: 'remainingMarketContributionPointChange',
			headerName: 'RM $ Contribution Pt Chg (BPS)',
			type: 'number',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Any Promo',
			name: 'RM $ Any Promo',
			factName: 'RM Any Promo $',
			units_display_name: 'RM Any Promo Units',
			field: 'remainingAnyPromo',
			headerName: 'RM $ Any Promo',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Any Promo % Chg',
			name: 'RM $ Any Promo % Chg',
			factName: 'RM Any Promo $ % Chg',
			units_display_name: 'RM Any Promo Units % Chg',
			field: 'remainingAnyPromoPerChange',
			headerName: 'RM $ Any Promo % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM No Promo',
			name: 'RM $ No Promo',
			factName: 'RM No Promo $',
			units_display_name: 'RM No Promo Units',
			field: 'remainingNoPromo',
			headerName: 'RM $ No Promo',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM No Promo % Chg',
			name: 'RM $ No Promo % Chg',
			factName: 'RM No Promo $ % Chg',
			units_display_name: 'RM No Promo Units % Chg',
			field: 'remainingNoPromoPerChange',
			headerName: 'RM $ No Promo % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Feature and Display',
			name: 'RM $ Feature and Display',
			factName: 'RM Feature and Display $',
			units_display_name: 'RM Feature and Display Units',
			field: 'remainingFeatureAndDisplay',
			headerName: 'RM $ Feature and Display',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Feature and Display % Chg',
			name: 'RM $ Feature and Display % Chg',
			factName: 'RM Feature and Display $ % Chg',
			units_display_name: 'RM Feature and Display Units % Chg',
			field: 'remainingFeatureAndDisplayPerChange',
			headerName: 'RM $ Feature and Display % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Display w/o Feature',
			name: 'RM $ Display w/o Feature',
			factName: 'RM Display w/o Feature $',
			units_display_name: 'RM Display w/o Feature Units',
			field: 'remainingDisplayWithOutFeature',
			headerName: 'RM $ Display w/o Feature',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Display w/o Feature % Chg',
			name: 'RM $ Display w/o Feature % Chg',
			factName: 'RM Display w/o Feature $ % Chg',
			units_display_name: 'RM Display w/o Feature Units % Chg',
			field: 'remainingDisplayWithOutFeaturePerChange',
			headerName: 'RM $ Display w/o Feature % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Feature w/o Display',
			name: 'RM $ Feature w/o Display',
			factName: 'RM Feature w/o Display $',
			units_display_name: 'RM Feature w/o Display Units',
			field: 'remainingFeatureWithoutDisplay',
			headerName: 'RM $ Feature w/o Display',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Feature w/o Display % Chg',
			name: 'RM $ Feature w/o Display % Chg',
			factName: 'RM Feature w/o Display $ % Chg',
			units_display_name: 'RM Feature w/o Display Units % Chg',
			field: 'remainingFeatureWithoutDisplayPerChange',
			headerName: 'RM $ Feature w/o Display % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Price Decrease',
			name: 'RM $ Price Decrease',
			factName: 'RM Price Decrease $',
			units_display_name: 'RM Price Decrease Units',
			field: 'remainingPriceDecrease',
			headerName: 'RM $ Price Decrease',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Price Decrease % Chg',
			name: 'RM $ Price Decrease % Chg',
			factName: 'RM Price Decrease $ % Chg',
			units_display_name: 'RM Price Decrease Units % Chg',
			field: 'remainingPriceDecreasePerChange',
			headerName: 'RM $ Price Decrease % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Sales',
			name: 'RM Units',
			factName: 'RM Units Sales',
			units_display_name: 'RM Units',
			headerName: 'RM $ Sales',
			field: 'remainingMarketUnitSales',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Sales % Chg vs YA',
			name: 'RM Units % Chg vs YA',
			factName: 'RM Units Sales % Chg vs YA',
			units_display_name: 'RM Units % Chg vs YA',
			headerName: 'RM $ Sales % Chg vs YA',
			field: 'remainingMarketUnitSalesChange',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Sales % Chg vs 2YA',
			name: 'RM Units % Chg vs 2YA',
			factName: 'RM Units Sales % Chg vs 2YA',
			units_display_name: 'RM Units % Chg vs 2YA',
			headerName: 'RM $ Sales % Chg vs 2YA',
			field: 'remainingMarketUnitSalesChangeTwoYA',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Sales Actual Chg vs YA',
			name: 'RM Units Actual Chg vs YA',
			factName: 'RM Units Actual Chg vs YA',
			units_display_name: 'RM Units Actual Chg vs YA',
			headerName: 'RM $ Sales Actual Chg vs YA',
			field: 'remainingMarketUnitSalesActualChange',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Sales Actual Chg vs 2YA',
			name: 'RM Units Actual Chg vs 2YA',
			factName: 'RM Units Actual Chg vs 2YA',
			units_display_name: 'RM Units Actual Chg vs 2YA',
			headerName: 'RM $ Sales Actual Chg vs 2YA',
			field: 'remainingMarketUnitSalesActualChangeTwoYA',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Sales/TDP',
			name: 'RM Units/TDP',
			factName: 'RM Units/TDP',
			units_display_name: 'RM Units/TDP',
			field: 'remainingMarketUnitTDP',
			headerName: 'RM $/TDP',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Sales/TDP % Chg',
			name: 'RM Units/TDP % Chg',
			factName: 'RM Units/TDP % Chg',
			units_display_name: 'RM Units/TDP % Chg',
			field: 'remainingMarketUnitTDPChange',
			headerName: 'RM $/TDP % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Contribution',
			name: 'RM Units Contribution',
			factName: 'RM Units Contribution',
			units_display_name: 'RM Units Contribution',
			field: 'remainingMarketUnitContribution',
			headerName: 'RM $ Contribution',
			type: 'number',
			width: 200,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Contribution Pt Chg (BPS)',
			name: 'RM Units Contribution Pt Chg (BPS)',
			factName: 'RM Units Contribution Pt Chg (BPS)',
			units_display_name: 'RM Units Contribution Pt Chg (BPS)',
			field: 'remainingMarketUnitContributionPointChange',
			headerName: 'RM $ Contribution Pt Chg (BPS)',
			type: 'number',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Any Promo',
			name: 'RM Units Any Promo',
			factName: 'RM Any Promo Units',
			units_display_name: 'RM Units Any Promo',
			field: 'remainingAnyPromoUnit',
			headerName: 'RM Any Promo $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Any Promo % Chg',
			name: 'RM Units Any Promo % Chg',
			factName: 'RM Any Promo Units % Chg',
			units_display_name: 'RM Units Any Promo % Chg',
			field: 'remainingAnyPromoUnitPerChange',
			headerName: 'RM Any Promo $ % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM No Promo',
			name: 'RM Units No Promo',
			factName: 'RM No Promo Units',
			units_display_name: 'RM Units No Promo',
			field: 'remainingNoPromoUnit',
			headerName: 'RM No Promo $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM No Promo % Chg',
			name: 'RM Units No Promo % Chg',
			factName: 'RM No Promo Units % Chg',
			units_display_name: 'RM Units No Promo % Chg',
			field: 'remainingNoPromoUnitPerChange',
			headerName: 'RM No Promo $ % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Feature and Display',
			name: 'RM Units Feature and Display',
			factName: 'RM Feature and Display Units',
			units_display_name: 'RM Units Feature and Display',
			field: 'remainingFeatureAndDisplayUnit',
			headerName: 'RM Feature and Display $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Feature and Display % Chg',
			name: 'RM Units Feature and Display % Chg',
			factName: 'RM Feature and Display Units % Chg',
			units_display_name: 'RM Units Feature and Display % Chg',
			field: 'remainingFeatureAndDisplayUnitPerChange',
			headerName: 'RM Feature and Display $ % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Display w/o Feature',
			name: 'RM Units Display w/o Feature',
			factName: 'RM Display w/o Feature Units',
			units_display_name: 'RM Units Display w/o Feature',
			field: 'remainingDisplayWithOutFeatureUnit',
			headerName: 'RM Display w/o Feature $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Display w/o Feature % Chg',
			name: 'RM Units Display w/o Feature % Chg',
			factName: 'RM Display w/o Feature Units % Chg',
			units_display_name: 'RM Units Display w/o Feature % Chg',
			field: 'remainingDisplayWithOutFeatureUnitPerChange',
			headerName: 'RM Display w/o Feature $ % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Feature w/o Display',
			name: 'RM Units Feature w/o Display',
			factName: 'RM Feature w/o Display Units',
			units_display_name: 'RM Units Feature w/o Display',
			field: 'remainingFeatureWithoutDisplayUnit',
			headerName: 'RM Feature w/o Display $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Feature w/o Display % Chg',
			name: 'RM Units Feature w/o Display % Chg',
			factName: 'RM Feature w/o Display % Chg',
			units_display_name: 'RM Units Feature w/o Display % Chg',
			field: 'remainingFeatureWithoutDisplayUnitPerChange',
			headerName: 'RM Feature w/o Display $ % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Price Decrease',
			name: 'RM Units Price Decrease',
			factName: 'RM Price Decrease Units',
			units_display_name: 'RM Units Price Decrease',
			field: 'remainingPriceDecreaseUnit',
			headerName: 'RM Price Decrease $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Price Decrease % Chg',
			name: 'RM Units Price Decrease % Chg',
			factName: 'RM Price Decrease Units % Chg',
			units_display_name: 'RM Units Price Decrease % Chg',
			field: 'remainingPriceDecreaseUnitPerChange',
			headerName: 'RM Price Decrease $ % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'RM TDP Share (%)',
			name: 'RM TDP Share (%)',
			factName: 'RM TDP Share (%)',
			units_display_name: 'RM TDP Share (%)',
			field: 'remainingMarketTDPShare',
			headerName: 'RM TDP Share (%)',
			type: 'number',
			width: 200,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'RM TDP Share Pt Chg (BPS)',
			name: 'RM TDP Share Pt Chg (BPS)',
			factName: 'RM TDP Share Pt Chg (BPS)',
			units_display_name: 'RM TDP Share Pt Chg (BPS)',
			field: 'remainingMarketTDPSharePointChange',
			headerName: 'RM TDP Share Pt Chg (BPS)',
			type: 'number',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'RM Avg Unit Price',
			name: 'RM Avg Unit Price',
			factName: 'RM Avg Unit Price',
			units_display_name: 'RM Avg Unit Price',
			field: 'remainingMarketAverageUnitPrice',
			headerName: 'RM Avg Unit Price',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar2decimal'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'RM Avg Unit Price Pt Chg',
			name: 'RM Avg Unit Price % Chg',
			factName: 'RM Avg Unit Price % Chg',
			units_display_name: 'RM Avg Unit Price % Chg',
			field: 'remainingMarketUnitPriceChange',
			headerName: 'RM Avg Unit Price % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'TM Sales',
			name: 'TM $ Sales',
			factName: 'TM $ Sales',
			units_display_name: 'TM Units Sales',
			headerName: 'TM $ Sales',
			field: 'totalMarketSales',
			type: 'number',
			width: 170,
			cellClass: 'special',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'TM Sales % Chg vs YA',
			name: 'TM $ Sales % Chg vs YA',
			factName: 'TM $ Sales % Chg vs YA',
			units_display_name: 'TM Units % Chg vs YA',
			headerName: 'TM $ Sales % Chg vs YA',
			field: 'totalMarketSalesPerChange',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'perc'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'TM Sales % Chg vs 2YA',
			name: 'TM $ Sales % Chg vs 2YA',
			factName: 'TM $ Sales % Chg vs 2YA',
			units_display_name: 'TM Units % Chg vs 2YA',
			headerName: 'TM $ Sales % Chg vs 2YA',
			field: 'totalMarketSalesPerChangeTwoYA',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'perc'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'TM Sales Actual Chg vs YA',
			name: 'TM $ Sales Actual Chg vs YA',
			factName: 'TM $ Sales Actual Chg vs YA',
			units_display_name: 'TM Units Actual Chg vs YA',
			headerName: 'TM $ Sales Actual Chg vs YA',
			field: 'totalMarketSalesActualChange',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'TM Sales Actual Chg vs 2YA',
			name: 'TM $ Sales Actual Chg vs 2YA',
			factName: 'TM $ Sales Actual Chg vs 2YA',
			units_display_name: 'TM Units Actual Chg vs 2YA',
			headerName: 'TM $ Sales Actual Chg vs 2YA',
			field: 'totalMarketSalesActualChangeTwoYA',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'TM Sales',
			name: 'TM Units',
			factName: 'TM Units Sales',
			units_display_name: 'TM Units',
			headerName: 'TM $ Sales',
			field: 'totalMarketUnitSales',
			type: 'number',
			width: 170,
			cellClass: 'special',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'TM Sales % Chg vs YA',
			name: 'TM Units % Chg vs YA',
			factName: 'TM Units % Chg vs YA',
			units_display_name: 'TM Units % Chg vs YA',
			headerName: 'TM $ Sales % Chg vs YA',
			field: 'totalMarketUnitSalesPerChange',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'perc'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'TM Sales % Chg vs 2YA',
			name: 'TM Units % Chg vs 2YA',
			factName: 'TM Units % Chg vs 2YA',
			units_display_name: 'TM Units % Chg vs 2YA',
			headerName: 'TM $ Sales % Chg vs 2YA',
			field: 'totalMarketUnitSalesPerChangeTwoYA',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'perc'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'TM Sales Actual Chg vs YA',
			name: 'TM Units Actual Chg vs YA',
			factName: 'TM Units Actual Chg vs YA',
			units_display_name: 'TM Units Actual Chg vs YA',
			headerName: 'TM $ Sales Actual Chg vs YA',
			field: 'totalMarketUnitSalesActualChange',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'TM Sales Actual Chg vs 2YA',
			name: 'TM Units Actual Chg vs 2YA',
			factName: 'TM Units Actual Chg vs 2YA',
			units_display_name: 'TM Units Actual Chg vs 2YA',
			headerName: 'TM $ Sales Actual Chg vs 2YA',
			field: 'totalMarketUnitSalesActualChangeTwoYA',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		}
	],

	FOUR_BY_FOUR_CELLS: [
		{ number: 1, left_wm: 'New', top_rm: 'New' },
		{ number: 2, left_wm: 'New', top_rm: 'Existing' },
		{ number: 3, left_wm: 'New', top_rm: 'Delisted' },
		{ number: 4, left_wm: 'New', top_rm: 'Not Carried' },
		{ number: 5, left_wm: '', top_rm: '' },
		{ number: 6, left_wm: 'Existing', top_rm: 'New' },
		{ number: 7, left_wm: 'Existing', top_rm: 'Existing' },
		{ number: 8, left_wm: 'Existing', top_rm: 'Delisted' },
		{ number: 9, left_wm: 'Existing', top_rm: 'Not Carried' },
		{ number: 10, left_wm: '', top_rm: '' },
		{ number: 11, left_wm: 'Delisted', top_rm: 'New' },
		{ number: 12, left_wm: 'Delisted', top_rm: 'Existing' },
		{ number: 13, left_wm: 'Delisted', top_rm: 'Delisted' },
		{ number: 14, left_wm: 'Delisted', top_rm: 'Not Carried' },
		{ number: 15, left_wm: '', top_rm: '' },
		{ number: 16, left_wm: 'Not Carried', top_rm: 'New' },
		{ number: 17, left_wm: 'Not Carried', top_rm: 'Existing' },
		{ number: 18, left_wm: 'Not Carried', top_rm: 'Delisted' },
		{ number: 19, left_wm: 'Not Carried', top_rm: 'Not Carried' },
		{ number: 20, left_wm: '', top_rm: '' },
		{ number: 21, left_wm: '', top_rm: '' },
		{ number: 22, left_wm: '', top_rm: '' },
		{ number: 23, left_wm: '', top_rm: '' },
		{ number: 24, left_wm: '', top_rm: '' },
		{ number: 25, left_wm: '', top_rm: '' }
	],

	BASE_FACT_LIST: [
		{
			valuebase: 'Other',
			gkey: 'Product',
			headerName: 'Product',
			field: 'productName',
			isSelected: true,
			pinnedLeft: true,
			enablePinning: true,
			enableColumnMoving: false,
			cellClass: 'normal',
			width: 250
		},
		{
			valuebase: 'Other',
			gkey: 'Product Description',
			headerName: 'Product Description',
			field: 'productDescription',
			isSelected: false,
			pinnedLeft: true,
			enablePinning: true,
			enableColumnMoving: false,
			cellTooltip: 'toolTip',
			cellClass: 'normal',
			width: 250
		},
		{
			valuebase: 'Other',
			gkey: 'Count of UPCs',
			headerName: 'Count of UPCs',
			field: 'upcCount',
			formatType: '',
			isSelected: true,
			pinnedLeft: true,
			enablePinning: true,
			enableColumnMoving: false,
			cellClass: 'special',
			width: 150,
			sort: {
				direction: 'ASC'
			}
		},
		{
			valuebase: 'Other',
			gkey: 'Percent of UPCs',
			headerName: 'Percent of UPCs',
			field: 'upcPercentage',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'special',
			width: 150
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Sales',
			headerName: 'WM $ Sales',
			field: 'walmartMarketDollarsTY',
			formatType: '$',
			cellFilter: "zip:'dollar'",
			isSelected: true,
			cellClass: 'special',
			width: 150
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Sales',
			headerName: 'RM $ Sales',
			field: 'remainingMarketDollarsTY',
			formatType: '$',
			cellFilter: "zip:'dollar'",
			isSelected: true,
			cellClass: 'special',
			width: 150
		},
		{
			valuebase: 'Dollars',
			gkey: 'TM Sales',
			headerName: 'TM $ Sales',
			field: 'totalMarketDollarsTY',
			formatType: '$',
			cellFilter: "zip:'dollar'",
			isSelected: true,
			cellClass: 'special',
			width: 150
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Share',
			headerName: 'WM $ Share',
			field: 'walmartDollarShare',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'special',
			width: 150
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Share Pt Chg (BPS)',
			headerName: 'WM $ Share Pt Chg (BPS)',
			field: 'walmartDollarSharePtChange',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'colorcode',
			width: 150
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Value of Share Pt Chg',
			headerName: 'WM $ Value of Share Pt Chg',
			field: 'walmartDollarValueOfSharePtChange',
			formatType: '$',
			cellFilter: "zip:'dollar'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Sales % Chg',
			headerName: 'WM $ Sales % Chg',
			field: 'walmartDollarsPercentageChange',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Sales YA',
			headerName: 'WM $ Sales YA',
			field: 'walmartMarketDollarsYA',
			formatType: '$',
			cellFilter: "zip:'dollar'",
			isSelected: false,
			cellClass: 'special',
			width: 150
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Contribution',
			headerName: 'WM $ Contribution',
			field: 'walmartMarketDollarsContribution',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150
		},
		{
			valuebase: 'Units',
			gkey: 'WM Share',
			headerName: 'WM Units Share',
			field: 'walmartUnitsShare',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'special',
			width: 150
		},
		{
			valuebase: 'Units',
			gkey: 'WM Share Pt Chg (BPS)',
			headerName: 'WM Units Share Pt Chg (BPS)',
			field: 'walmartUnitsSharePtChange',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'colorcode',
			width: 150
		},
		{
			valuebase: 'Units',
			gkey: 'WM Value of Share Pt Chg',
			headerName: 'WM Units Value of Share Pt Chg',
			field: 'walmartUnitsValueOfSharePtChange',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'colorcode',
			width: 150
		},
		{
			valuebase: 'Units',
			gkey: 'WM Sales',
			headerName: 'WM Units',
			field: 'walmartMarketUnitsTY',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			width: 150
		},
		{
			valuebase: 'Units',
			gkey: 'WM Sales % Chg',
			headerName: 'WM Units % Chg',
			field: 'walmartUnitsPercentageChange',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150
		},
		{
			valuebase: 'Units',
			gkey: 'WM Sales YA',
			headerName: 'WM Units YA',
			field: 'walmartMarketUnitsYA',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			width: 150
		},
		{
			valuebase: 'Units',
			gkey: 'WM Contribution',
			headerName: 'WM Units Contribution',
			field: 'walmartMarketUnitsContribution',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Sales % Chg',
			headerName: 'RM $ Sales % Chg',
			field: 'remainingDollarsPercentageChange',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Sales YA',
			headerName: 'RM $ Sales YA',
			field: 'remainingMarketDollarsYA',
			formatType: '$',
			cellFilter: "zip:'dollar'",
			isSelected: false,
			cellClass: 'special',
			width: 150
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Contribution',
			headerName: 'RM $ Contribution',
			field: 'remainingMarketDollarsContribution',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150
		},
		{
			valuebase: 'Units',
			gkey: 'RM Contribution',
			headerName: 'RM Units Contribution',
			field: 'remainingMarketUnitsContribution',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150
		},
		{
			valuebase: 'Units',
			gkey: 'RM Sales',
			headerName: 'RM Units',
			field: 'remainingMarketUnitsTY',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			width: 150
		},
		{
			valuebase: 'Units',
			gkey: 'RM Sales % Chg',
			headerName: 'RM Units % Chg',
			field: 'remainingUnitsPercentageChange',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150
		},
		{
			valuebase: 'Units',
			gkey: 'RM Sales YA',
			headerName: 'RM Units YA',
			field: 'remainingMarketUnitsYA',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			width: 150
		},
		{
			valuebase: 'Dollars',
			gkey: 'TM Sales % Chg',
			headerName: 'TM $ Sales % Chg',
			field: 'totalDollarsPercentageChange',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150
		},
		{
			valuebase: 'Dollars',
			gkey: 'TM Sales YA',
			headerName: 'TM $ Sales YA',
			field: 'totalMarketDollarsYA',
			formatType: '$',
			cellFilter: "zip:'dollar'",
			isSelected: false,
			cellClass: 'special',
			width: 150
		},
		{
			valuebase: 'Dollars',
			gkey: 'TM Contribution',
			headerName: 'TM $ Contribution',
			field: 'totalMarketDollarsContribution',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150
		},
		{
			valuebase: 'Units',
			gkey: 'TM Contribution',
			headerName: 'TM Units Contribution',
			field: 'totalMarketUnitsContribution',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150
		},
		{
			valuebase: 'Units',
			gkey: 'TM Sales',
			headerName: 'TM Units',
			field: 'totalMarketUnitsTY',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			width: 150
		},
		{
			valuebase: 'Units',
			gkey: 'TM Sales % Chg',
			headerName: 'TM Units % Chg',
			field: 'totalUnitsPercentageChange',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150
		},
		{
			valuebase: 'Units',
			gkey: 'TM Sales YA',
			headerName: 'TM Units YA',
			field: 'totalMarketUnitsYA',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			width: 150
		},
		{
			valuebase: 'EQ',
			gkey: 'WM Share',
			headerName: 'WM EQ Share',
			field: 'walmartEQUnitsShare',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'special',
			width: 150
		},
		{
			valuebase: 'EQ',
			gkey: 'WM Share Pt Chg (BPS)',
			headerName: 'WM EQ Share Pt Chg (BPS)',
			field: 'walmartEQUnitsSharePercentageChange',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'colorcode',
			width: 150
		},
		{
			valuebase: 'EQ',
			gkey: 'WM Value of Share Pt Chg',
			headerName: 'WM EQ Value of Share Pt Chg',
			field: 'walmartEQUnitsValueSharePercentageChange',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'colorcode',
			width: 150
		},
		{
			valuebase: 'EQ',
			gkey: 'WM Sales',
			headerName: 'WM EQ',
			field: 'walmartEQUnits',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			width: 150
		},
		{
			valuebase: 'EQ',
			gkey: 'WM Sales % Chg',
			headerName: 'WM EQ % Chg',
			field: 'walmartEQUnitsPercentageChange',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150
		},
		{
			valuebase: 'EQ',
			gkey: 'WM Sales YA',
			headerName: 'WM EQ YA',
			field: 'walmartEQUnitsYA',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			width: 150
		},
		{
			valuebase: 'EQ',
			gkey: 'WM Contribution',
			headerName: 'WM EQ Contribution',
			field: 'walmartEQUnitsContribution',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150
		},
		{
			valuebase: 'EQ',
			gkey: 'RM Contribution',
			headerName: 'RM EQ Contribution',
			field: 'remainingMarketEQUnitsContributions',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150
		},
		{
			valuebase: 'EQ',
			gkey: 'RM Sales % Chg',
			headerName: 'RM EQ % Chg',
			field: 'remainingMarketEQUnitsPercentageChange',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150
		},
		{
			valuebase: 'EQ',
			gkey: 'RM Sales YA',
			headerName: 'RM EQ YA',
			field: 'remainingMarketEQUnitsYA',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			width: 150
		},
		{
			valuebase: 'EQ',
			gkey: 'TM Contribution',
			headerName: 'TM EQ Contribution',
			field: 'totalMarketEQUnitsContribution',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150
		},
		{
			valuebase: 'EQ',
			gkey: 'TM Sales % Chg',
			headerName: 'TM EQ % Chg',
			field: 'totalMarketEQUnitsPercentageChange',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150
		},
		{
			valuebase: 'EQ',
			gkey: 'TM Sales YA',
			headerName: 'TM EQ YA',
			field: 'totalMarketEQUnitsYA',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			width: 150
		}
	],
	FACT_LIST: [
		{
			valuebase: 'EQ',
			gkey: 'WM Sales',
			headerName: 'WM EQ',
			field: 'walmartEQUnits',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			width: 150,
			name: 'WM EQ' // Added field
		},
		{
			valuebase: 'EQ',
			gkey: 'WM Share',
			headerName: 'WM EQ Share',
			field: 'walmartEQUnitsShare',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'special',
			width: 150,
			name: 'WM EQ Share' // Added field
		},
		{
			valuebase: 'EQ',
			gkey: 'WM Share Pt Chg (BPS)',
			headerName: 'WM EQ Share Pt Chg (BPS)',
			field: 'walmartEQUnitsSharePercentageChange',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'colorcode',
			width: 150,
			name: 'WM EQ Share Pt Chg (BPS)' // Added field
		},
		{
			valuebase: 'EQ',
			gkey: 'WM Value of Share Pt Chg',
			headerName: 'WM EQ Value of Share Pt Chg',
			field: 'walmartEQUnitsValueSharePercentageChange',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'colorcode',
			width: 150,
			name: 'WM EQ Value of Share Pt Chg' // Added field
		},
		{
			valuebase: 'EQ',
			gkey: 'WM Sales % Chg',
			headerName: 'WM EQ % Chg',
			field: 'walmartEQUnitsPercentageChange',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150,
			name: 'WM EQ % Chg' // Added field
		},
		{
			valuebase: 'EQ',
			gkey: 'WM Sales YA',
			headerName: 'WM EQ YA',
			field: 'walmartEQUnitsYA',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			width: 150,
			name: 'WM EQ YA' // Added field
		},
		{
			valuebase: 'EQ',
			gkey: 'WM Contribution',
			headerName: 'WM EQ Contribution',
			field: 'walmartEQUnitsContribution',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150,
			name: 'WM EQ Contribution' // Added field
		},
		{
			valuebase: 'EQ',
			gkey: 'RM Contribution',
			headerName: 'RM EQ Contribution',
			field: 'remainingMarketEQUnitsContributions',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150,
			name: 'RM EQ Contribution' // Added field
		},
		{
			valuebase: 'EQ',
			gkey: 'RM Sales',
			headerName: 'RM EQ',
			field: 'remainingMarketEQUnits',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			width: 150,
			name: 'RM EQ' // Added field
		},
		{
			valuebase: 'EQ',
			gkey: 'RM Sales % Chg',
			headerName: 'RM EQ % Chg',
			field: 'remainingMarketEQUnitsPercentageChange',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150,
			name: 'RM EQ % Chg' // Added field
		},
		{
			valuebase: 'EQ',
			gkey: 'RM Sales YA',
			headerName: 'RM EQ YA',
			field: 'remainingMarketEQUnitsYA',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			width: 150,
			name: 'RM EQ YA' // Added field
		},
		{
			valuebase: 'EQ',
			gkey: 'TM Contribution',
			headerName: 'TM EQ Contribution',
			field: 'totalMarketEQUnitsContribution',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150,
			name: 'TM EQ Contribution' // Added field
		},
		{
			valuebase: 'EQ',
			gkey: 'TM Sales',
			headerName: 'TM EQ',
			field: 'totalMarketEQUnits',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			width: 150,
			name: 'TM EQ' // Added field
		},
		{
			valuebase: 'EQ',
			gkey: 'TM Sales % Chg',
			headerName: 'TM EQ % Chg',
			field: 'totalMarketEQUnitsPercentageChange',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150,
			name: 'TM EQ % Chg' // Added field
		},
		{
			valuebase: 'EQ',
			gkey: 'TM Sales YA',
			headerName: 'TM EQ YA',
			field: 'totalMarketEQUnitsYA',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			width: 150,
			name: 'TM EQ YA' // Added field
		},
		{
			valuebase: 'Other',
			gkey: 'Count of UPCs',
			headerName: 'Count of UPCs',
			field: 'upcCount',
			formatType: '',
			isSelected: true,
			pinnedLeft: true,
			isPinned: true,
			enablePinning: true,
			enableColumnMoving: false,
			cellClass: 'special',
			width: 150,
			name: 'Count of UPCs' // Added field
		},
		{
			valuebase: 'Other',
			gkey: 'Percent of UPCs',
			headerName: 'Percent of UPCs',
			field: 'upcPercentage',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'special',
			width: 150,
			name: 'Percent of UPCs' // Added field
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Sales',
			headerName: 'WM $ Sales',
			field: 'walmartMarketDollarsTY',
			formatType: '$',
			isPinned: true,
			cellFilter: "zip:'dollar'",
			isSelected: true,
			cellClass: 'special',
			width: 150,
			name: 'WM $ Sales' // Added field
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Sales',
			headerName: 'RM $ Sales',
			field: 'remainingMarketDollarsTY',
			formatType: '$',
			isPinned: true,
			cellFilter: "zip:'dollar'",
			isSelected: true,
			cellClass: 'special',
			width: 150,
			name: 'RM $ Sales' // Added field
		},
		{
			valuebase: 'Dollars',
			gkey: 'TM Sales',
			headerName: 'TM $ Sales',
			field: 'totalMarketDollarsTY',
			formatType: '$',
			isPinned: true,
			cellFilter: "zip:'dollar'",
			isSelected: true,
			cellClass: 'special',
			width: 150,
			name: 'TM $ Sales' // Added field
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Share',
			headerName: 'WM $ Share',
			field: 'walmartDollarShare',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'special',
			width: 150,
			name: 'WM $ Share' // Added field
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Share Pt Chg (BPS)',
			headerName: 'WM $ Share Pt Chg (BPS)',
			field: 'walmartDollarSharePtChange',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'colorcode',
			width: 150,
			name: 'WM $ Share Pt Chg (BPS)' // Added field
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Value of Share Pt Chg',
			headerName: 'WM $ Value of Share Pt Chg',
			field: 'walmartDollarValueOfSharePtChange',
			formatType: '$',
			cellFilter: "zip:'dollar'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150,
			name: 'WM $ Value of Share Pt Chg' // Added field
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Sales % Chg',
			headerName: 'WM $ Sales % Chg',
			field: 'walmartDollarsPercentageChange',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150,
			name: 'WM $ Sales % Chg' // Added field
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Sales YA',
			headerName: 'WM $ Sales YA',
			field: 'walmartMarketDollarsYA',
			formatType: '$',
			cellFilter: "zip:'dollar'",
			isSelected: false,
			cellClass: 'special',
			width: 150,
			name: 'WM $ Sales YA' // Added field
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Contribution',
			headerName: 'WM $ Contribution',
			field: 'walmartMarketDollarsContribution',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150,
			name: 'WM $ Contribution' // Added field
		},
		{
			valuebase: 'Units',
			gkey: 'WM Share',
			headerName: 'WM Units Share',
			field: 'walmartUnitsShare',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'special',
			width: 150,
			name: 'WM Units Share' // Added field
		},
		{
			valuebase: 'Units',
			gkey: 'WM Share Pt Chg (BPS)',
			headerName: 'WM Units Share Pt Chg (BPS)',
			field: 'walmartUnitsSharePtChange',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'colorcode',
			width: 150,
			name: 'WM Units Share Pt Chg (BPS)' // Added field
		},
		{
			valuebase: 'Units',
			gkey: 'WM Value of Share Pt Chg',
			headerName: 'WM Units Value of Share Pt Chg',
			field: 'walmartUnitsValueOfSharePtChange',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'colorcode',
			width: 150,
			name: 'WM Units Value of Share Pt Chg' // Added field
		},
		{
			valuebase: 'Units',
			gkey: 'WM Sales',
			headerName: 'WM Units',
			field: 'walmartMarketUnitsTY',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			width: 150,
			name: 'WM Units' // Added field
		},
		{
			valuebase: 'Units',
			gkey: 'WM Sales % Chg',
			headerName: 'WM Units % Chg',
			field: 'walmartUnitsPercentageChange',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150,
			name: 'WM Units % Chg' // Added field
		},
		{
			valuebase: 'Units',
			gkey: 'WM Sales YA',
			headerName: 'WM Units YA',
			field: 'walmartMarketUnitsYA',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			width: 150,
			name: 'WM Units YA' // Added field
		},
		{
			valuebase: 'Units',
			gkey: 'WM Contribution',
			headerName: 'WM Units Contribution',
			field: 'walmartMarketUnitsContribution',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150,
			name: 'WM Units Contribution' // Added field
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Sales % Chg',
			headerName: 'RM $ Sales % Chg',
			field: 'remainingDollarsPercentageChange',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150,
			name: 'RM $ Sales % Chg' // Added field
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Sales YA',
			headerName: 'RM $ Sales YA',
			field: 'remainingMarketDollarsYA',
			formatType: '$',
			cellFilter: "zip:'dollar'",
			isSelected: false,
			cellClass: 'special',
			width: 150,
			name: 'RM $ Sales YA' // Added field
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Contribution',
			headerName: 'RM $ Contribution',
			field: 'remainingMarketDollarsContribution',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150,
			name: 'RM $ Contribution' // Added field
		},
		{
			valuebase: 'Units',
			gkey: 'RM Contribution',
			headerName: 'RM Units Contribution',
			field: 'remainingMarketUnitsContribution',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150,
			name: 'RM Units Contribution' // Added field
		},
		{
			valuebase: 'Units',
			gkey: 'RM Sales',
			headerName: 'RM Units',
			field: 'remainingMarketUnitsTY',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			width: 150,
			name: 'RM Units' // Added field
		},
		{
			valuebase: 'Units',
			gkey: 'RM Sales % Chg',
			headerName: 'RM Units % Chg',
			field: 'remainingUnitsPercentageChange',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150,
			name: 'RM Units % Chg' // Added field
		},
		{
			valuebase: 'Units',
			gkey: 'RM Sales YA',
			headerName: 'RM Units YA',
			field: 'remainingMarketUnitsYA',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			width: 150,
			name: 'RM Units YA' // Added field
		},
		{
			valuebase: 'Dollars',
			gkey: 'TM Sales % Chg',
			headerName: 'TM $ Sales % Chg',
			field: 'totalDollarsPercentageChange',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150,
			name: 'TM $ Sales % Chg' // Added field
		},
		{
			valuebase: 'Dollars',
			gkey: 'TM Sales YA',
			headerName: 'TM $ Sales YA',
			field: 'totalMarketDollarsYA',
			formatType: '$',
			cellFilter: "zip:'dollar'",
			isSelected: false,
			cellClass: 'special',
			width: 150,
			name: 'TM $ Sales YA' // Added field
		},
		{
			valuebase: 'Dollars',
			gkey: 'TM Contribution',
			headerName: 'TM $ Contribution',
			field: 'totalMarketDollarsContribution',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150,
			name: 'TM $ Contribution' // Added field
		},
		{
			valuebase: 'Units',
			gkey: 'TM Contribution',
			headerName: 'TM Units Contribution',
			field: 'totalMarketUnitsContribution',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150,
			name: 'TM Units Contribution' // Added field
		},
		{
			valuebase: 'Units',
			gkey: 'TM Sales',
			headerName: 'TM Units',
			field: 'totalMarketUnitsTY',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			width: 150,
			name: 'TM Units' // Added field
		},
		{
			valuebase: 'Units',
			gkey: 'TM Sales % Chg',
			headerName: 'TM Units % Chg',
			field: 'totalUnitsPercentageChange',
			formatType: '%',
			cellFilter: "zip:'perc'",
			isSelected: false,
			cellClass: 'colorcode',
			width: 150,
			name: 'TM Units % Chg' // Added field
		},
		{
			valuebase: 'Units',
			gkey: 'TM Sales YA',
			headerName: 'TM Units YA',
			field: 'totalMarketUnitsYA',
			formatType: '',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			width: 150,
			name: 'TM Units YA' // Added field
		}
	]
};

export const OPT_VAL = [
	{ key: 'TOTAL_BUSINESS', value: 'NA', alias_name: 'TOTAL' },
	{ key: 'MAJOR_BUSINESS', value: 'NA', alias_name: 'MAJOR' },
	{ key: 'DEPARTMENT', value: 'NA', alias_name: 'DEPT' },
	{ key: 'CATEGORY_GROUP', value: 'NA', alias_name: 'CATGRP' },
	{ key: 'CATEGORY', value: 'NA', alias_name: 'CAT' },
	{ key: 'SUBCATEGORY', value: 'NA', alias_name: 'SUBCAT' },
	{ key: 'SEGMENT', value: 'NA', alias_name: 'SEG' },
	{ key: 'BRAND_OWNER_HIGH', value: 'NA', alias_name: 'BRDOWNHGH' },
	{ key: 'BRAND_OWNER_LOW', value: 'NA', alias_name: 'BRDOWNLOW' },
	{ key: 'BRAND_HIGH', value: 'NA', alias_name: 'BRDHGH' },
	{ key: 'BRAND_LOW', value: 'NA', alias_name: 'BRDLOW' },
	{ key: 'BRAND', value: 'NA', alias_name: 'DEPT' }
];

export const TRENDED_WEEKLY_REPORT_DASHBOARD = {
	TITLE: ' TRENDED WEEKLY ',
	BASE_FACT_LIST: [
		{
			key: 'WM $ Sales',
			valuefromapi: 'walmartDollars',
			factGroup: 'Sales',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00568B',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'WM Units',
			valuefromapi: 'walmartUnits',
			factGroup: 'Sales',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00568B',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'WM $ Sales YA',
			valuefromapi: 'walmartDollarsYA',
			factGroup: 'Sales',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00AEEF',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'WM $ Sales 2YA',
			valuefromapi: 'walmartDollarsTwoYA',
			factGroup: 'Sales',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#8CD1FA',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'WM Units YA',
			valuefromapi: 'walmartUnitsYA',
			factGroup: 'Sales',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00AEEF',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'WM Units 2YA',
			valuefromapi: 'walmartUnitsTwoYA',
			factGroup: 'Sales',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#8CD1FA',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'RM $ Sales',
			valuefromapi: 'remainingDollars',
			factGroup: 'Sales',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#D26514',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'RM Units',
			valuefromapi: 'remainingUnits',
			factGroup: 'Sales',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#D26514',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'RM $ Sales YA',
			valuefromapi: 'remainingDollarsYA',
			factGroup: 'Sales',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#FFAD59',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'RM $ Sales 2YA',
			valuefromapi: 'remainingDollarsTwoYA',
			factGroup: 'Sales',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#F3C098',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'RM Units YA',
			valuefromapi: 'remainingUnitsYA',
			factGroup: 'Sales',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#FFAD59',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'RM Units 2YA',
			valuefromapi: 'remainingUnitsTwoYA',
			factGroup: 'Sales',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#F3C098',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'WM $ Sales % Chg vs YA',
			valuefromapi: 'walmartDollarsPercentageChange',
			factGroup: 'Sales % Chg',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00568B',
			color: 'hasColor',
			unitDesc: 'value%'
		},
		{
			key: 'WM $ Sales % Chg vs 2YA',
			valuefromapi: 'walmartDollarsPercentageChangeTwoYA',
			factGroup: 'Sales % Chg',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00AEEF',
			color: 'hasColor',
			unitDesc: 'value%'
		},
		{
			key: 'WM Units % Chg vs YA',
			valuefromapi: 'walmartUnitsPercentageChange',
			factGroup: 'Sales % Chg',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00568B',
			color: 'hasColor',
			unitDesc: 'value%'
		},
		{
			key: 'WM Units % Chg vs 2YA',
			valuefromapi: 'walmartUnitsPercentageChangeTwoYA',
			factGroup: 'Sales % Chg',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00AEEF',
			color: 'hasColor',
			unitDesc: 'value%'
		},
		{
			key: 'RM $ Sales % Chg vs YA',
			valuefromapi: 'remainingDollarsPercentageChange',
			factGroup: 'Sales % Chg',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#D26514',
			color: 'hasColor',
			unitDesc: 'value%'
		},
		{
			key: 'RM $ Sales % Chg vs 2YA',
			valuefromapi: 'remainingDollarsPercentageChangeTwoYA',
			factGroup: 'Sales % Chg',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#EEA165',
			color: 'hasColor',
			unitDesc: 'value%'
		},
		{
			key: 'RM Units % Chg vs YA',
			valuefromapi: 'remainingUnitsPercentageChange',
			factGroup: 'Sales % Chg',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#D26514',
			color: 'hasColor',
			unitDesc: 'value%'
		},
		{
			key: 'RM Units % Chg vs 2YA',
			valuefromapi: 'remainingUnitsPercentageChangeTwoYA',
			factGroup: 'Sales % Chg',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#EEA165',
			color: 'hasColor',
			unitDesc: 'value%'
		},
		{
			key: 'WM $ Share',
			valuefromapi: 'walmartDollarSharePercentage',
			factGroup: 'Share',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00568B',
			color: 'noColor',
			unitDesc: 'value%'
		},
		{
			key: 'WM Units Share',
			valuefromapi: 'walmartUnitsSharePercentage',
			factGroup: 'Share',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00568B',
			color: 'noColor',
			unitDesc: 'value%'
		},
		{
			key: 'WM $ Share YA',
			valuefromapi: 'walmartDollarSharePercentageYA',
			factGroup: 'Share',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00AEEF',
			color: 'noColor',
			unitDesc: 'value%'
		},
		{
			key: 'WM $ Share 2YA',
			valuefromapi: 'walmartDollarSharePercentageTwoYA',
			factGroup: 'Share',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#8CD1FA',
			color: 'noColor',
			unitDesc: 'value%'
		},
		{
			key: 'WM Units Share YA',
			valuefromapi: 'walmartUnitsSharePercentageYA',
			factGroup: 'Share',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00AEEF',
			color: 'noColor',
			unitDesc: 'value%'
		},
		{
			key: 'WM Units Share 2YA',
			valuefromapi: 'walmartUnitsSharePercentageTwoYA',
			factGroup: 'Share',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#8CD1FA',
			color: 'noColor',
			unitDesc: 'value%'
		},
		{
			key: 'WM $ Share Pt Chg vs YA',
			valuefromapi: 'walmartDollarSharePointChange',
			factGroup: 'Share Pt Chg',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00568B',
			color: 'hasColor',
			unitDesc: 'value'
		},
		{
			key: 'WM $ Share Pt Chg vs 2YA',
			valuefromapi: 'walmartDollarSharePointChangeTwoYA',
			factGroup: 'Share Pt Chg',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00AEEF',
			color: 'hasColor',
			unitDesc: 'value'
		},
		{
			key: 'WM Units Share Pt Chg vs YA',
			valuefromapi: 'walmartUnitsSharePointChange',
			factGroup: 'Share Pt Chg',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00568B',
			color: 'hasColor',
			unitDesc: 'value'
		},
		{
			key: 'WM Units Share Pt Chg vs 2YA',
			valuefromapi: 'walmartUnitsSharePointChangeTwoYA',
			factGroup: 'Share Pt Chg',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00AEEF',
			color: 'hasColor',
			unitDesc: 'value'
		},
		{
			key: 'WM $ Value of Share Pt Chg vs YA',
			valuefromapi: 'walmartDollarValueOfShareChange',
			factGroup: 'Value of Share Pt Chg',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00568B',
			color: 'hasColor',
			unitDesc: '$value'
		},
		{
			key: 'WM $ Value of Share Pt Chg vs 2YA',
			valuefromapi: 'walmartDollarValueOfShareChangeTwoYA',
			factGroup: 'Value of Share Pt Chg',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00AEEF',
			color: 'hasColor',
			unitDesc: '$value'
		},
		{
			key: 'WM Units Value of Share Pt Chg vs YA',
			valuefromapi: 'walmartUnitsValueOfShareChange',
			factGroup: 'Value of Share Pt Chg',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00568B',
			color: 'hasColor',
			unitDesc: 'value'
		},
		{
			key: 'WM Units Value of Share Pt Chg vs 2YA',
			valuefromapi: 'walmartUnitsValueOfShareChangeTwoYA',
			factGroup: 'Value of Share Pt Chg',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00AEEF',
			color: 'hasColor',
			unitDesc: 'value'
		},
		{
			key: 'WM $ Any Promo',
			valuefromapi: 'walmartDollarAnyPromo',
			factGroup: 'Any Promo',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00568B',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'WM Units Any Promo',
			valuefromapi: 'walmartUnitsAnyPromo',
			factGroup: 'Any Promo',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00568B',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'WM $ Any Promo YA',
			valuefromapi: 'walmartDollarAnyPromoYA',
			factGroup: 'Any Promo',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00AEEF',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'WM Units Any Promo YA',
			valuefromapi: 'walmartUnitsAnyPromoYA',
			factGroup: 'Any Promo',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00AEEF',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'RM $ Any Promo',
			valuefromapi: 'remainingDollarAnyPromo',
			factGroup: 'Any Promo',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#D26514',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'RM Units Any Promo',
			valuefromapi: 'remainingUnitsAnyPromo',
			factGroup: 'Any Promo',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#D26514',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'RM $ Any Promo YA',
			valuefromapi: 'remainingDollarAnyPromoYA',
			factGroup: 'Any Promo',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#FFAD59',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'RM Units Any Promo YA',
			valuefromapi: 'remainingUnitsAnyPromoYA',
			factGroup: 'Any Promo',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#FFAD59',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'WM $ No Promo',
			valuefromapi: 'walmartDollarNoPromo',
			factGroup: 'No Promo',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00568B',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'WM Units No Promo',
			valuefromapi: 'walmartUnitsNoPromo',
			factGroup: 'No Promo',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00568B',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'WM $ No Promo YA',
			valuefromapi: 'walmartDollarNoPromoYA',
			factGroup: 'No Promo',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00AEEF',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'WM Units No Promo YA',
			valuefromapi: 'walmartUnitsNoPromoYA',
			factGroup: 'No Promo',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00AEEF',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'RM $ No Promo',
			valuefromapi: 'remainingDollarNoPromo',
			factGroup: 'No Promo',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#D26514',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'RM Units No Promo',
			valuefromapi: 'remainingUnitsNoPromo',
			factGroup: 'No Promo',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#D26514',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'RM $ No Promo YA',
			valuefromapi: 'remainingDollarNoPromoYA',
			factGroup: 'No Promo',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#FFAD59',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'RM Units No Promo YA',
			valuefromapi: 'remainingUnitsNoPromoYA',
			factGroup: 'No Promo',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#FFAD59',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'WM $ Feature and Display',
			valuefromapi: 'walmartDollarFeatureAndDisplay',
			factGroup: 'Feature and Display',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00568B',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'WM Units Feature and Display',
			valuefromapi: 'walmartUnitsFeatureAndDisplay',
			factGroup: 'Feature and Display',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00568B',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'WM $ Feature and Display YA',
			valuefromapi: 'walmartDollarFeatureAndDisplayYA',
			factGroup: 'Feature and Display',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00AEEF',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'WM Units Feature and Display YA',
			valuefromapi: 'walmartUnitsFeatureAndDisplayYA',
			factGroup: 'Feature and Display',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00AEEF',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'RM $ Feature and Display',
			valuefromapi: 'remainingDollarFeatureAndDisplay',
			factGroup: 'Feature and Display',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#D26514',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'RM Units Feature and Display',
			valuefromapi: 'remainingUnitsFeatureAndDisplay',
			factGroup: 'Feature and Display',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#D26514',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'RM $ Feature and Display YA',
			valuefromapi: 'remainingDollarFeatureAndDisplayYA',
			factGroup: 'Feature and Display',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#FFAD59',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'RM Units Feature and Display YA',
			valuefromapi: 'remainingUnitsFeatureAndDisplayYA',
			factGroup: 'Feature and Display',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#FFAD59',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'WM $ Display w/o Feature',
			valuefromapi: 'walmartDollarDisplayWithOutFeature',
			factGroup: 'Display w/o Feature',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00568B',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'WM Units Display w/o Feature',
			valuefromapi: 'walmartUnitsDisplayWithOutFeature',
			factGroup: 'Display w/o Feature',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00568B',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'WM $ Display w/o Feature YA',
			valuefromapi: 'walmartDollarDisplayWithOutFeatureYA',
			factGroup: 'Display w/o Feature',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00AEEF',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'WM Units Display w/o Feature YA',
			valuefromapi: 'walmartUnitsDisplayWithOutFeatureYA',
			factGroup: 'Display w/o Feature',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00AEEF',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'RM $ Display w/o Feature',
			valuefromapi: 'remainingDollarDisplayWithOutFeature',
			factGroup: 'Display w/o Feature',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#D26514',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'RM Units Display w/o Feature',
			valuefromapi: 'remainingUnitsDisplayWithOutFeature',
			factGroup: 'Display w/o Feature',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#D26514',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'RM $ Display w/o Feature YA',
			valuefromapi: 'remainingDollarDisplayWithOutFeatureYA',
			factGroup: 'Display w/o Feature',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#FFAD59',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'RM Units Display w/o Feature YA',
			valuefromapi: 'remainingUnitsDisplayWithOutFeatureYA',
			factGroup: 'Display w/o Feature',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#FFAD59',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'WM $ Feature w/o Display',
			valuefromapi: 'walmartDollarFeatureWithoutDisplay',
			factGroup: 'Feature w/o Display',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00568B',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'WM Units Feature w/o Display',
			valuefromapi: 'walmartUnitsFeatureWithoutDisplay',
			factGroup: 'Feature w/o Display',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00568B',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'WM $ Feature w/o Display YA',
			valuefromapi: 'walmartDollarFeatureWithoutDisplayYA',
			factGroup: 'Feature w/o Display',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00AEEF',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'WM Units Feature w/o Display YA',
			valuefromapi: 'walmartUnitsFeatureWithoutDisplayYA',
			factGroup: 'Feature w/o Display',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00AEEF',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'RM $ Feature w/o Display',
			valuefromapi: 'remainingDollarFeatureWithoutDisplay',
			factGroup: 'Feature w/o Display',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#D26514',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'RM Units Feature w/o Display',
			valuefromapi: 'remainingUnitsFeatureWithoutDisplay',
			factGroup: 'Feature w/o Display',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#D26514',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'RM $ Feature w/o Display YA',
			valuefromapi: 'remainingDollarFeatureWithoutDisplayYA',
			factGroup: 'Feature w/o Display',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#FFAD59',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'RM Units Feature w/o Display YA',
			valuefromapi: 'remainingUnitsFeatureWithoutDisplayYA',
			factGroup: 'Feature w/o Display',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#FFAD59',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'WM $ Price Decrease',
			valuefromapi: 'walmartDollarPriceDecrease',
			factGroup: 'Price Decrease',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00568B',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'WM Units Price Decrease',
			valuefromapi: 'walmartUnitsPriceDecrease',
			factGroup: 'Price Decrease',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00568B',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'WM $ Price Decrease YA',
			valuefromapi: 'walmartDollarPriceDecreaseYA',
			factGroup: 'Price Decrease',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00AEEF',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'WM Units Price Decrease YA',
			valuefromapi: 'walmartUnitsPriceDecreaseYA',
			factGroup: 'Price Decrease',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#00AEEF',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'RM $ Price Decrease',
			valuefromapi: 'remainingDollarPriceDecrease',
			factGroup: 'Price Decrease',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#D26514',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'RM Units Price Decrease',
			valuefromapi: 'remainingUnitsPriceDecrease',
			factGroup: 'Price Decrease',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#D26514',
			color: 'noColor',
			unitDesc: 'value'
		},
		{
			key: 'RM $ Price Decrease YA',
			valuefromapi: 'remainingDollarPriceDecreaseYA',
			factGroup: 'Price Decrease',
			valuebase: 'dollar',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#FFAD59',
			color: 'noColor',
			unitDesc: '$value'
		},
		{
			key: 'RM Units Price Decrease YA',
			valuefromapi: 'remainingUnitsPriceDecreaseYA',
			factGroup: 'Price Decrease',
			valuebase: 'units',
			formatType: '',
			isSelected: false,
			cellClass: 'normal',
			colorcode: '#FFAD59',
			color: 'noColor',
			unitDesc: 'value'
		}
	]
};

export const CDT_DASH = {
	DASHBOARD_TITLE: ' CONSUMER DECISION TREE',
	OPT_VAL: [
		{ key: 'DEPARTMENT', value: 'NA' },
		{ key: 'CATEGORY_GROUP', value: 'NA' },
		{ key: 'CATEGORY', value: 'NA' },
		{ key: 'SUBCATEGORY', value: 'NA' },
		{ key: 'SEGMENT', value: 'NA' },
		{ key: 'BRAND_OWNER_HIGH', value: 'NA' },
		{ key: 'BRAND_OWNER_LOW', value: 'NA' },
		{ key: 'BRAND_HIGH', value: 'NA' },
		{ key: 'BRAND_LOW', value: 'NA' },
		{ key: 'TOTAL_BUSINESS', value: 'NA' },
		{ key: 'MAJOR_BUSINESS', value: 'NA' },
		{ key: 'BRAND', value: 'NA' }
	],
	TABLE_COLS: [
		{
			valuebase: 'Other',
			gkey: 'Products',
			name: 'Products',
			units_display_name: 'Products',
			headerName: 'Products',
			field: 'productName',
			cellTooltip: 'toolTip',
			width: 200,
			pinnedLeft: true,
			enablePinning: true,
			cellClass: 'gridProductName',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'Product Description',
			name: 'Product Description',
			units_display_name: 'Product Description',
			headerName: 'Product Description',
			field: 'productDescription',
			cellTooltip: 'toolTipProdDesc',
			width: 200,
			pinnedLeft: true,
			enablePinning: true,
			cellClass: 'special special-header-left ',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Share',
			name: 'WM $ Share',
			units_display_name: 'WM Units Share',
			headerName: 'WM $ Share',
			field: 'marketShare',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Share Pt Chg (BPS)',
			name: 'WM $ Share Pt Chg (BPS)',
			units_display_name: 'WM Units Share Pt Chg (BPS)',
			field: 'sharePtChange',
			type: 'number',
			headerName: 'WM $ Share Pt Chg (BPS)',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Value of Share Pt Chg',
			name: 'WM $ Value of Share Pt Chg',
			units_display_name: 'WM Units Value of Share Pt Chg',
			field: 'valueOfSharePointChange',
			type: 'number',
			headerName: 'WM $ Value of Share Pt Chg',
			width: 200,
			cellFilter: "zip:'dollar'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Sales',
			name: 'WM $ Sales',
			units_display_name: 'WM Units Sales',
			headerName: 'WM $ Sales',
			field: 'walmartSales',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false,
			sort: {
				direction: 'desc'
			}
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Sales % Chg',
			name: 'WM $ Sales % Chg',
			units_display_name: 'WM Units Sales % Chg',
			headerName: 'WM $ Sales % Chg',
			field: 'walmartSalesChange',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Sales Actual Chg',
			name: 'WM $ Sales Actual Chg',
			units_display_name: 'WM Units Actual Chg',
			headerName: 'WM $ Sales Actual Chg',
			field: 'walmartSalesActualChange',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Sales/TDP',
			name: 'WM $/TDP',
			units_display_name: 'WM Units/TDP',
			field: 'walmartTDP',
			headerName: 'WM $/TDP',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM Sales/TDP % Chg',
			name: 'WM $/TDP % Chg',
			units_display_name: 'WM Units/TDP % Chg',
			field: 'walmartTDPChange',
			headerName: 'WM $/TDP % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM % Promo Sales',
			name: 'WM % Promo $',
			units_display_name: 'WM % Promo Units',
			field: 'walmartPromo',
			headerName: 'WM % Promo $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'WM % Promo Sales Pt Chg (BPS)',
			name: 'WM % Promo $ Pt Chg (BPS)',
			units_display_name: 'WM % Promo Units Pt Chg (BPS)',
			field: 'walmartPromoPointChange',
			headerName: 'WM % Promo $ Pt Chg (BPS)',
			type: 'number',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Share',
			name: 'WM $ Share',
			units_display_name: 'WM Units Share',
			headerName: 'WM $ Share',
			field: 'marketShare',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Share Pt Chg (BPS)',
			name: 'WM $ Share Pt Chg (BPS)',
			units_display_name: 'WM Units Share Pt Chg (BPS)',
			field: 'sharePtChange',
			type: 'number',
			headerName: 'WM $ Share Pt Chg (BPS)',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Value of Share Pt Chg',
			name: 'WM $ Value of Share Pt Chg',
			units_display_name: 'WM Units Value of Share Pt Chg',
			field: 'valueOfSharePointChange',
			type: 'number',
			headerName: 'WM $ Value of Share Pt Chg',
			width: 200,
			cellFilter: "zip:'dollar'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Sales',
			name: 'WM $ Sales',
			units_display_name: 'WM Units Sales',
			headerName: 'WM $ Sales',
			field: 'walmartSales',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false,
			sort: {
				direction: 'desc'
			}
		},
		{
			valuebase: 'Units',
			gkey: 'WM Sales % Chg',
			name: 'WM $ Sales % Chg',
			units_display_name: 'WM Units Sales % Chg',
			headerName: 'WM $ Sales % Chg',
			field: 'walmartSalesChange',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Sales Actual Chg',
			name: 'WM $ Sales Actual Chg',
			units_display_name: 'WM Units Actual Chg',
			headerName: 'WM $ Sales Actual Chg',
			field: 'walmartSalesActualChange',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Sales/TDP',
			name: 'WM $/TDP',
			units_display_name: 'WM Units/TDP',
			field: 'walmartTDP',
			headerName: 'WM $/TDP',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM Sales/TDP % Chg',
			name: 'WM $/TDP % Chg',
			units_display_name: 'WM Units/TDP % Chg',
			field: 'walmartTDPChange',
			headerName: 'WM $/TDP % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM % Promo Sales',
			name: 'WM % Promo $',
			units_display_name: 'WM % Promo Units',
			field: 'walmartPromo',
			headerName: 'WM % Promo $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'WM % Promo Sales Pt Chg (BPS)',
			name: 'WM % Promo $ Pt Chg (BPS)',
			units_display_name: 'WM % Promo Units Pt Chg (BPS)',
			field: 'walmartPromoPointChange',
			headerName: 'WM % Promo $ Pt Chg (BPS)',
			type: 'number',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'WM %ACV (UPC level data only)',
			name: 'WM %ACV (UPC level data only)',
			units_display_name: 'WM %ACV (UPC level data only)',
			field: 'walmartPerACV',
			headerName: 'WM %ACV (UPC level data only)',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'WM %ACV Pt Chg (UPC level data only)',
			name: 'WM %ACV Pt Chg (UPC level data only)',
			units_display_name: 'WM %ACV Pt Chg (UPC level data only)',
			field: 'walmartPerAcvPtChg',
			headerName: 'WM %ACV Pt Chg (UPC level data only)',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'WM Avg Unit Price',
			name: 'WM Avg Unit Price',
			units_display_name: 'WM Avg Unit Price',
			field: 'walmartAverageUnitPrice',
			headerName: 'WM Avg Unit Price',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar2decimal'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'WM Avg Unit Price % Chg',
			name: 'WM Avg Unit Price % Chg',
			units_display_name: 'WM Avg Unit Price % Chg',
			field: 'walmartUnitPriceChange',
			headerName: 'WM Avg Unit Price % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'WM TDP Share (%)',
			name: 'WM TDP Share (%)',
			units_display_name: 'WM TDP Share (%)',
			field: 'walmartTDPShare',
			headerName: 'WM TDP Share (%)',
			type: 'number',
			width: 200,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'WM TDP Share Pt Chg (BPS)',
			name: 'WM TDP Share Pt Chg (BPS)',
			units_display_name: 'WM TDP Share Pt Chg (BPS)',
			field: 'walmartTDPSharePointChange',
			headerName: 'WM TDP Share Pt Chg (BPS)',
			type: 'number',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Sales',
			name: 'RM $ Sales',
			units_display_name: 'RM Units Sales',
			headerName: 'RM $ Sales',
			field: 'remainingMarketSales',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Sales % Chg',
			name: 'RM $ Sales % Chg',
			units_display_name: 'RM Units Sales % Chg',
			headerName: 'RM $ Sales % Chg',
			field: 'remainingMarketSalesChange',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Sales Actual Chg',
			name: 'RM $ Sales Actual Chg',
			units_display_name: 'RM Units Actual Chg',
			headerName: 'RM $ Sales Actual Chg',
			field: 'remainingMarketSalesActualChange',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Sales/TDP',
			name: 'RM $/TDP',
			units_display_name: 'RM Units/TDP',
			field: 'remainingMarketTDP',
			headerName: 'RM $/TDP',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM Sales/TDP % Chg',
			name: 'RM $/TDP % Chg',
			units_display_name: 'RM Units/TDP % Chg',
			field: 'remainingMarketTDPChange',
			headerName: 'RM $/TDP % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM % Promo Sales',
			name: 'RM % Promo $',
			units_display_name: 'RM % Promo Units',
			field: 'remainingMarketPromo',
			headerName: 'RM % Promo $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Dollars',
			gkey: 'RM % Promo Sales Pt Chg (BPS)',
			name: 'RM % Promo $ Pt Chg (BPS)',
			units_display_name: 'RM % Promo Units Pt Chg (BPS)',
			field: 'remainingMarketPromoPointChange',
			headerName: 'RM % Promo $ Pt Chg (BPS)',
			type: 'number',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Sales',
			name: 'RM $ Sales',
			units_display_name: 'RM Units Sales',
			headerName: 'RM $ Sales',
			field: 'remainingMarketSales',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Sales % Chg',
			name: 'RM $ Sales % Chg',
			units_display_name: 'RM Units Sales % Chg',
			headerName: 'RM $ Sales % Chg',
			field: 'remainingMarketSalesChange',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Sales Actual Chg',
			name: 'RM $ Sales Actual Chg',
			units_display_name: 'RM Units Actual Chg',
			headerName: 'RM $ Sales Actual Chg',
			field: 'remainingMarketSalesActualChange',
			type: 'number',
			width: 170,
			cellClass: 'gridFromNumber',
			cellFilter: "zip:'dollar'",
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Sales/TDP',
			name: 'RM $/TDP',
			units_display_name: 'RM Units/TDP',
			field: 'remainingMarketTDP',
			headerName: 'RM $/TDP',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM Sales/TDP % Chg',
			name: 'RM $/TDP % Chg',
			units_display_name: 'RM Units/TDP % Chg',
			field: 'remainingMarketTDPChange',
			headerName: 'RM $/TDP % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM % Promo Sales',
			name: 'RM % Promo $',
			units_display_name: 'RM % Promo Units',
			field: 'remainingMarketPromo',
			headerName: 'RM % Promo $',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Units',
			gkey: 'RM % Promo Sales Pt Chg (BPS)',
			name: 'RM % Promo $ Pt Chg (BPS)',
			units_display_name: 'RM % Promo Units Pt Chg (BPS)',
			field: 'remainingMarketPromoPointChange',
			headerName: 'RM % Promo $ Pt Chg (BPS)',
			type: 'number',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'RM %ACV (UPC level data only)',
			name: 'RM %ACV (UPC level data only)',
			units_display_name: 'RM %ACV (UPC level data only)',
			field: 'remainingMarketPerACV',
			headerName: 'RM %ACV (UPC level data only)',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'RM %ACV Pt Chg (UPC level data only)',
			name: 'RM %ACV Pt Chg (UPC level data only)',
			units_display_name: 'RM %ACV Pt Chg (UPC level data only)',
			field: 'remainingMarketPerAcvPtChg',
			headerName: 'RM %ACV Pt Chg (UPC level data only)',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'RM Avg Unit Price',
			name: 'RM Avg Unit Price',
			units_display_name: 'RM Avg Unit Price',
			field: 'remainingMarketAverageUnitPrice',
			headerName: 'RM Avg Unit Price',
			type: 'number',
			width: 170,
			cellFilter: "zip:'dollar2decimal'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'RM Avg Unit Price % Chg',
			name: 'RM Avg Unit Price % Chg',
			units_display_name: 'RM Avg Unit Price % Chg',
			field: 'remainingMarketUnitPriceChange',
			headerName: 'RM Avg Unit Price % Chg',
			type: 'number',
			width: 170,
			cellFilter: "zip:'perc'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'RM TDP Share (%)',
			name: 'RM TDP Share (%)',
			units_display_name: 'RM TDP Share (%)',
			field: 'remainingMarketTDPShare',
			headerName: 'RM TDP Share (%)',
			type: 'number',
			width: 200,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		},
		{
			valuebase: 'Other',
			gkey: 'RM TDP Share Pt Chg (BPS)',
			name: 'RM TDP Share Pt Chg (BPS)',
			units_display_name: 'RM TDP Share Pt Chg (BPS)',
			field: 'remainingMarketTDPSharePointChange',
			headerName: 'RM TDP Share Pt Chg (BPS)',
			type: 'number',
			width: 170,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'gridFromNumber',
			headerTooltip: 'headTootTip',
			isSelected: true,
			isPinned: false
		}
	],
	LEVEL_NAMES: {
		DEPARTMENT: 'Department',
		CATEGORY_GROUP: 'Category group',
		CATEGORY: 'Category',
		SUBCATEGORY: 'Sub category',
		SEGMENT: 'Segment',
		BRAND_OWNER_HIGH: 'Brand Owner High',
		BRAND_OWNER_LOW: 'Brand Owner',
		BRAND_HIGH: 'Brand High',
		BRAND_LOW: 'Brand Low',
		TOTAL_BUSINESS: 'TOTAL BUSINESS',
		MAJOR_BUSINESS: 'MAJOR BUSINESS'
	},
	PERIOD_END_DATE: 'Period End Date: ',
	EXPORT_MODAL_NAME: 'CONSUMER DECISION TREE'
};

//concierge start

export const CONCIERGE_HOMEPAGE_TILES = {
	LANDING_PAGE: [
		{
			heading: 'Entitlements',
			subquestions: [
				{
					text: 'Create Users',
					id: '8',
					image: 'assets/img/tile-create-users.png',
					alttext: '',
					navlink: '/concierge/createUsers',
					title: 'User Creation'
				},
				{
					text: 'Enable Content to Existing Users',
					id: '9',
					image: 'assets/img/tile-enable-content-to-existing-users.png',
					alttext: '',
					navlink: '/concierge/editEntitlements',
					title: 'Enable Content to the Existing Users'
				},
				{
					text: 'Bulk User Approve',
					id: '10',
					image: 'assets/img/tile-bulk-user-approve.png',
					alttext: '',
					navlink: 'bulkUserApprove',
					title: ''
				},
				{
					text: 'Users & Entitlements Summary',
					id: '10',
					image: 'assets/img/tile-request-status.png',
					alttext: '',
					navlink: '/concierge/requestStatus',
					title: 'Users & Entitlements Summary'
				}
			]
		},
		{
			heading: 'Communications',
			subquestions: [
				{
					text: 'Push Notifications to the App',
					id: '11',
					image: 'assets/img/tile-push-notifications-to-the-app.png',
					alttext: '',
					navlink: '/concierge/pushNotifications',
					title: 'Push Notifications to the App'
				},
				{
					text: 'Email to all Users',
					id: '12',
					image: 'assets/img/tile-email-to-all-users.png',
					alttext: '',
					navlink: '',
					title: ''
				},
				{
					text: 'Training Material',
					id: '12.1',
					image: 'assets/img/tile-training.png',
					alttext: '',
					navlink: '/concierge/training-library',
					title: 'Training Library'
				},
				{
					text: 'User Notifications',
					id: '12.2',
					image: 'assets/img/tile-training.png',
					alttext: '',
					navlink: '/concierge/enhancementSummary',
					title: 'User Notifications'
				}
			]
		},
		{
			heading: 'Stats',
			subquestions: [
				{
					text: 'Usage Reports',
					id: '13',
					image: 'assets/img/tile-usage-report.png',
					alttext: '',
					navlink: '/concierge/usageStats',
					title: 'Usage Reports'
				}
			]
		},
		{
			heading: 'Report a Problem',
			subquestions: [
				{
					text: 'Urgent Scalations',
					id: '14',
					image: 'assets/img/tile-urgent-escalations.png',
					alttext: '',
					navlink: '',
					title: ''
				}
			]
		}
	]
};
