import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AssortmentApiService } from '../service/assortment-api.service';
import { ASSORTMENT_DASHBOARD } from 'src/app/constants/dashboard-ui.constant';
import * as _ from 'lodash';
import { GridService } from 'src/app/shared/components/grid/grid-service/grid.service';
import { getFactMap } from '../assortment-dashboard.component';
import { UtilityService } from '../../services/utility.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'crf-ca-assortment-wmvs-rm-inner-table',
	templateUrl: './assortment-wmvs-rm-inner-table.component.html',
	styleUrls: ['./assortment-wmvs-rm-inner-table.component.scss']
})
export class AssortmentWMvsRMInnerTableComponent implements OnInit {
	@Input() tabsList: { key: string; value: string }[] = [];
	@Input() pinnedTopRowData;
	@Output() colDefsChanged = new EventEmitter<{
		fields: string[];
		headers: string[];
		modifiedColDefs: any[];
	}>();
	@Input() resetColDefs: EventEmitter<void>;

	rowData: any;
	activeTab: any = { key: '', value: '' };
	defaultColumns: any = [];
	defaultFacts: any;

	colDefs = [
		{ field: 'productName', headerName: 'Category', pinned: true, width: 250, cellClass: 'main' }
	];
	resData: any;
	walmartSide: any;
	templevel: any;
	rmSide: any;
	headerName: string = 'Count of UPCs';
	innerpayload: any;
	sort: string | undefined;
	facts: any = [];
	isLoading: boolean;
	resArray: any = [];

	qid: number = 9;

	selected_metrics: any = this.defaultColumns;
	colHeaders: string[] = [];

	generatePayload(): any {
		let payload = {
			totalBusiness: this.apiService.payload?.totalBusiness ?? '',
			majorBusiness: this.apiService.payload?.majorBusiness ?? '',
			department: this.apiService.payload?.department ?? '',
			categoryGroup: this.apiService.payload?.categoryGroup ?? '',
			category: this.apiService.payload?.category ?? '',
			subCategory: this.apiService.payload?.subCategory ?? '',
			segment: this.apiService.payload?.segment ?? '',
			brandOwnerHigh: this.apiService.payload?.brandOwnerHigh ?? '',
			brandOwnerLow: this.apiService.payload?.brandOwnerLow ?? '',
			brandHigh: this.apiService.payload?.brandHigh ?? '',
			brandFamily: this.apiService.payload?.brandFamily ?? '',
			brandLow: this.apiService.payload?.brandLow ?? '',
			market: this.apiService.payload?.market ?? [],
			period: this.apiService.payload?.period ?? '',
			level: this.apiService.payload.level ?? '',
			cyPeriod: this.apiService.payload?.cyPeriod ?? '',
			yaPeriod: this.apiService.payload?.yaPeriod ?? '',
			wmMarket: this.apiService.payload?.wmMarket ?? [],
			rmMarket: this.apiService.payload?.rmMarket ?? [],
			quadrant: this.apiService.payload?.quadrant ?? '',
			baseFact: this.apiService.payload?.baseFact ?? ''
		};
		return payload;
	}

	public readonly unSubscribeAll: Subject<void> = new Subject<void>();

	constructor(
		public apiService: AssortmentApiService,
		public gridColumnService: GridService,
		public utilityService: UtilityService
	) {}

	ngOnInit(): void {
		this.utilityService
			.getDefaultSelectors(this.qid)
			.pipe(takeUntil(this.unSubscribeAll))
			.subscribe({
				next: defaultSelectors => {
					if (defaultSelectors && defaultSelectors.facts) {
						this.defaultFacts = defaultSelectors.facts.split(', ').map(fact => fact.trim());
						this.selected_metrics = this.defaultFacts;
						this.defaultColumns = this.defaultFacts;
					} else {
						console.warn('Default selectors facts are null or undefined.');
						this.defaultFacts = 'Count of UPCs, WM $ Sales, RM $ Sales, TM $ Sales';
						this.selected_metrics = [];
						this.defaultColumns = ['Count of UPCs', 'WM $ Sales', 'RM $ Sales', 'TM $ Sales'];
					}

					this.continueInitialization();
				},
				error: error => {
					console.error('Error fetching default selectors:', error);
					this.continueInitialization();
				}
			});
	}

	resetColumns() {
		let modifiedColDefs = [this.colDefs[0], ...this.defaultColumns];
		modifiedColDefs = this.returnModifiedColumns(modifiedColDefs);
		this.continueInitialization();
	}

	continueInitialization(): void {
		this.innerpayload = this.generatePayload();
		if (!this.pinnedTopRowData) {
			this.pinnedTopRowData = [];
		}

		if (this.pinnedTopRowData.length === 0) {
			this.pinnedTopRowData.push({ productName: 'Total' });
		} else {
			this.pinnedTopRowData[0].productName = 'Total';
		}
		if (!this.tabsList || this.tabsList.length === 0) {
			// Handle the case where tabsList is undefined or empty
			console.error('tabsList is undefined or empty');
			return;
		}

		this.activeTab = {
			key: this.tabsList[0].key,
			value: this.tabsList[0].value
		};
		this.tabData(this.activeTab);
		this.updateSortAction();

		let factsMap = getFactMap(ASSORTMENT_DASHBOARD.FACT_LIST);
		this.facts = Array.from(factsMap.values());

		this.apiService.factUpdated$.subscribe({
			next: res => {
				this.resArray = res;
				this.populateColDefs();
			}
		});

		this.initializeSelectedMetricsAndHeaders();
		this.headerName = 'Count of UPCs';
		this.sort = null;
	}

	showInDetailData() {
		if (!this.templevel) {
			this.templevel = '';
		}
		if (!this.innerpayload) {
			this.innerpayload = { level: '' };
		}
		if (!this.innerpayload.level) {
			this.innerpayload.level = '';
		}
		this.apiService.isInnerTable = !this.apiService.isInnerTable;
		getFactMap;
		this.innerpayload.level = this.templevel;
		this.apiService.getAssortmentInfo;
	}

	tabData(tab) {
		if (!this.colDefs || this.colDefs.length === 0) {
			this.colDefs = [
				{
					field: 'productName',
					headerName: tab.value,
					pinned: true,
					width: 250,
					cellClass: 'main'
				}
			];
		}
		this.templevel = tab.key;
		this.innerpayload = this.generatePayload();

		this.innerpayload.level = this.templevel;

		this.colDefs = this.returnColumns(this.colDefs, this.defaultColumns);

		this.apiService.getTabData(this.innerpayload).subscribe(response => {
			if (
				response &&
				response.responseData &&
				response.responseData.byLevelData &&
				response.responseData.byLevelData[this.innerpayload.quadrant]
			) {
				this.rowData = response.responseData.byLevelData[this.innerpayload.quadrant];
				this.walmartSide = this.rowData.length > 0 ? this.rowData[0].walmartUPCStatus : undefined;
				this.rmSide =
					this.rowData.length > 0 ? this.rowData[0].remainingMarketUPCStatus : undefined;
			}
		});
	}

	loadWmRmUPCData(tab: { key: string; value: string }): void {
		this.isLoading = true;
		// this.colDefs = [];
		this.rowData = [];
		this.tabData(tab);

		setTimeout(() => {
			this.activeTab = tab;
			this.isLoading = false;
		}, 2500);
	}

	tabIsActive(tab): boolean {
		return this.activeTab && this.activeTab.key === tab.key;
	}

	updateSortAction() {
		this.gridColumnService.sortColumnSubject$.subscribe(res => {
			this.headerName = res.headerName;
			this.sort = res.sortOrder;
		});
	}

	returnColumns(allColumn, defaultColumns) {
		let filteredCol = [];

		defaultColumns.forEach(element => {
			let filtered = ASSORTMENT_DASHBOARD.FACT_LIST.find(column => column.headerName === element);
			if (filtered) {
				const isAlreadyPresent = allColumn.some(col => col.headerName === filtered.headerName);

				if (!isAlreadyPresent) {
					filteredCol.push({ ...filtered });
				}
			}
		});

		return [
			...allColumn,
			...filteredCol.map(col =>
				_.pick(col, ['field', 'headerName', 'pinned', 'cellFilter', 'cellClass'])
			)
		];
	}

	returnModifiedColumns(modifiedColDefs) {
		let filteredCol = [];
		modifiedColDefs.forEach(element => {
			let filtered = ASSORTMENT_DASHBOARD.FACT_LIST.find(column => column.headerName === element);
			if (filtered) {
				filteredCol.push({ ...filtered });
			}
		});

		return [
			...modifiedColDefs,
			...filteredCol.map(col =>
				_.pick(col, ['field', 'headerName', 'pinned', 'cellFilter', 'cellClass'])
			)
		];
	}

	populateColDefs() {
		let checkedValues = {};
		let vb = '';
		this.resArray.forEach(item => {
			if (item.isCheckedDollar) {
				vb = 'Dollars';
				if (!checkedValues[vb]) {
					checkedValues[vb] = [];
				}
				checkedValues[vb].push(item.value);
			}
			if (item.isCheckedUnit) {
				vb = 'Units';
				if (!checkedValues[vb]) {
					checkedValues[vb] = [];
				}
				checkedValues[vb].push(item.value);
			}
			if (item.isCheckedOther) {
				vb = 'Other';
				if (!checkedValues[vb]) {
					checkedValues[vb] = [];
				}
				checkedValues[vb].push(item.value);
			}
			if (item.isCheckedEQ) {
				vb = 'EQ';
				if (!checkedValues[vb]) {
					checkedValues[vb] = [];
				}
				checkedValues[vb].push(item.value);
			}
		});

		let fields: Record<string, string[]> = {};
		Object.entries(checkedValues).forEach(([valueBase, checkedList]) => {
			if (!fields[valueBase]) {
				fields[valueBase] = [];
			}
			Object.entries(checkedList).forEach(([index, value]) => {
				let gKeyField = null;
				this.facts.forEach(mainRow => {
					if (mainRow) {
						Object.values(mainRow).forEach((cell: any) => {
							if (cell && cell.gkey === value) {
								if (cell.valuebase === valueBase) {
									fields[valueBase].push(cell.field);
								}
							}
						});
					}
				});
			});
		});

		let newColDefs = [];
		Object.entries(fields).forEach(([valueBase, fieldList]) => {
			fieldList.forEach(field => {
				let matchingObject = this.facts.find(
					row => row && row.some(cell => cell && cell.field === field)
				);

				if (matchingObject) {
					let matchingCell = matchingObject.find(cell => cell && cell.field === field);
					if (matchingCell) {
						let headerName = matchingCell.headerName || field;
						let colDef = {
							field: field,
							headerName: headerName,
							cellFilter: matchingCell.cellFilter,
							cellClass: matchingCell.cellClass,
							pinned: false,
							width: 250
						};

						newColDefs.push(colDef);
					}
				}
			});
		});

		let modifiedColDefs = [this.colDefs[0], ...newColDefs];
		modifiedColDefs = this.returnModifiedColumns(modifiedColDefs);
		this.colDefs = modifiedColDefs;

		this.selected_metrics = modifiedColDefs.map(colDef => colDef.field);
		this.colHeaders = modifiedColDefs.map(colDef => colDef.headerName);
		this.colDefsChanged.emit({
			fields: this.selected_metrics,
			headers: this.colHeaders,
			modifiedColDefs: this.colDefs
		});
	}

	initializeSelectedMetricsAndHeaders() {
		this.selected_metrics = this.colDefs.map(colDef => colDef.field);
		this.colHeaders = this.colDefs.map(colDef => colDef.headerName);
		this.colDefsChanged.emit({
			fields: this.selected_metrics,
			headers: this.colHeaders,
			modifiedColDefs: this.colDefs
		});
	}
}
