import { Component } from '@angular/core';
import { CONCIERGE_HOMEPAGE_TILES } from 'src/app/constants/dashboard-ui.constant';

@Component({
	selector: 'crf-ca-concierge-content',
	templateUrl: './concierge-content.component.html',
	styleUrl: './concierge-content.component.scss'
})
export class ConciergeContentComponent {
	tilesData = CONCIERGE_HOMEPAGE_TILES.LANDING_PAGE;
}
