import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { NlsnDataStoreService } from 'src/app/core/service/store/nlsn-data-store.service';
import {
	BASE_URL,
	OVT_UI_URL_DASHBOARD_SELECTION,
	PANEL_DASHBOARD
} from 'src/environments/env.config.constants';
import { KEY } from 'src/app/constants/data.constant';
import { NlsnGlobalsContextExtract } from 'src/app/core/service/auth/nlsn-globals.service';

@Injectable({
	providedIn: 'root'
})
export class PanelApiService {
	defaultSelectors: any;

	constructor(
		private http: HttpClient,
		private nlsnDataStoreService: NlsnDataStoreService,
		private nlsnGlobalsContextExtract: NlsnGlobalsContextExtract
	) {}

	getAllPanelMarkets(): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			OVT_UI_URL_DASHBOARD_SELECTION.GET_ALL_PANEL_MARKET_NAMES;
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		return this.http.get<any>(pingURL, headers).pipe(
			map(result => {
				return result.responseData;
			})
		);
	}

	getAllPanelPeriods(): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			OVT_UI_URL_DASHBOARD_SELECTION.GET_ALL_PANEL_PERIODS;
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		return this.http.get<any>(pingURL, headers).pipe(
			map(result => {
				return result.responseData;
			})
		);
	}

	getPanelDashboardInfo(reqObj) {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] + PANEL_DASHBOARD.GET_INFO;
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		return this.http.post<any>(pingURL, reqObj, headers).pipe(
			map(result => {
				return result.responseData;
			})
		);
	}
}
