import { Component, OnInit } from '@angular/core';
import { TopbarUtilityService } from 'src/app/core/service/topbar/topbar-utility.service';

@Component({
	selector: 'crf-ca-concierge-header',
	templateUrl: './concierge-header.component.html',
	styleUrl: './concierge-header.component.scss'
})
export class ConciergeHeaderComponent implements OnInit {
	pageTitle: any;
	constructor(private topBarUtility: TopbarUtilityService) {}

	ngOnInit(): void {
		this.pageTitle = this.topBarUtility.getConciergePageTitle();
	}
}
