export const BASE_FACT_LIST = [
	// WM Market Share
	[
		{
			gkey: 'WM Market Share',
			name: '{period}WM $ Market Share',
			displayName: '{period}WM $ Share',
			key: 'WM $ Share',
			field: 'dollarMarketShare_{period}Week',
			valuebase: 'dollar',
			isSelected: true,
			isPinned: true,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Market Share',
			name: '{period}WM Units Market Share',
			displayName: '{period}WM Units Share',
			key: 'WM Units Share',
			field: 'unitsMarketShare_{period}Week',
			valuebase: 'units',
			isSelected: true,
			isPinned: false,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Market Share',
			name: '{period}WM EQ Units Market Share',
			displayName: '{period}WM EQ Units Share',
			key: 'WM EQ Units Share',
			field: 'eqUnitsMarketShare_{period}Week',
			valuebase: 'EQ',
			isSelected: false,
			isPinned: false,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		}
	],
	// WM Share Pt Chg (BPS) vs YA
	[
		{
			gkey: 'WM Share Pt Chg (BPS) vs YA',
			name: '{period}WM $ Share Pt Chg (BPS) vs YA',
			displayName: '{period}WM $ Share Pt Chg (BPS) vs YA',
			key: 'WM $ Share Pt Chg (BPS) vs YA',
			field: 'wmDollarSharePTChgBpsYA_{period}Week',
			valuebase: 'dollar',
			isSelected: true,
			formatType: '',
			isPinned: true,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Share Pt Chg (BPS) vs YA',
			name: '{period}WM Units Share Pt Chg (BPS) vs YA',
			displayName: '{period}WM Units Share Pt Chg (BPS) vs YA',
			key: 'WM Units Share Pt Chg (BPS) vs YA',
			field: 'wmUnitsSharePTChgBpsYA_{period}Week',
			valuebase: 'units',
			isSelected: true,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Share Pt Chg (BPS) vs YA',
			name: '{period}WM EQ Units Share Pt Chg (BPS) vs YA',
			displayName: '{period}WM EQ Units Share Pt Chg (BPS) vs YA',
			key: 'WM EQ Units Share Pt Chg (BPS) vs YA',
			field: 'wmEqUnitsSharePTChgBpsYA_{period}Week',
			valuebase: 'EQ',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		}
	],
	// WM Share Pt Chg (BPS) vs 2YA
	[
		{
			gkey: 'WM Share Pt Chg (BPS) vs 2YA',
			name: '{period}WM $ Share Pt Chg (BPS) vs 2YA',
			displayName: '{period}WM $ Share Pt Chg (BPS) vs 2YA',
			key: 'WM $ Share Pt Chg (BPS) vs 2YA',
			field: 'wmDollarSharePTChgBpsTwoYA_{period}Week',
			valuebase: 'dollar',
			isSelected: true,
			formatType: '',
			isPinned: true,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Share Pt Chg (BPS) vs 2YA',
			name: '{period}WM Units Share Pt Chg (BPS) vs 2YA',
			displayName: '{period}WM Units Share Pt Chg (BPS) vs 2YA',
			key: 'WM Units Share Pt Chg (BPS) vs 2YA',
			field: 'wmUnitsSharePTChgBpsTwoYA_{period}Week',
			valuebase: 'units',
			isSelected: true,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Share Pt Chg (BPS) vs 2YA',
			name: '{period}WM EQ Units Share Pt Chg (BPS) vs 2YA',
			displayName: '{period}WM EQ Units Share Pt Chg (BPS) vs 2YA',
			key: 'WM EQ Units Share Pt Chg (BPS) vs 2YA',
			field: 'wmEqUnitsSharePTChgBpsTwoYA_{period}Week',
			valuebase: 'EQ',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		}
	],
	// WM Value of Share Pt Chg vs YA
	[
		{
			gkey: 'WM Value of Share Pt Chg vs YA',
			name: '{period} $ Value of Share Pt Chg vs YA',
			displayName: '{period}WM $ Value of Share Pt Chg vs YA',
			key: 'WM $ Value of Share Pt Chg vs YA',
			field: 'dollarValueOfSharePTChgYA_{period}Week',
			valuebase: 'dollar',
			isSelected: false,
			cellFilter: "zip:'dollar'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Value of Share Pt Chg vs YA',
			name: '{period}Units Value of Share Pt Chg vs YA',
			displayName: '{period}WM Units Value of Share Pt Chg vs YA',
			key: 'WM Units Value of Share Pt Chg vs YA',
			field: 'unitsValueOfSharePTChgYA_{period}Week',
			valuebase: 'units',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Value of Share Pt Chg vs YA',
			name: '{period}EQ Units Value of Share Pt Chg vs YA',
			displayName: '{period}WM EQ Units Value of Share Pt Chg vs YA',
			key: 'WM EQ Units Value of Share Pt Chg vs YA',
			field: 'eqUnitsValueOfSharePTChgYA_{period}Week',
			valuebase: 'EQ',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		}
	],
	// WM Value of Share Pt Chg vs 2YA
	[
		{
			gkey: 'WM Value of Share Pt Chg vs 2YA',
			name: '{period} $ Value of Share Pt Chg vs 2YA',
			displayName: '{period}WM $ Value of Share Pt Chg vs 2YA',
			key: 'WM $ Value of Share Pt Chg vs 2YA',
			field: 'dollarValueOfSharePTChgTwoYA_{period}Week',
			valuebase: 'dollar',
			isSelected: false,
			cellFilter: "zip:'dollar'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Value of Share Pt Chg vs 2YA',
			name: '{period}Units Value of Share Pt Chg vs 2YA',
			displayName: '{period}WM Units Value of Share Pt Chg vs 2YA',
			key: 'WM Units Value of Share Pt Chg vs 2YA',
			field: 'unitsValueOfSharePTChgTwoYA_{period}Week',
			valuebase: 'units',
			isSelected: true,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Value of Share Pt Chg vs 2YA',
			name: '{period}EQ Units Value of Share Pt Chg vs 2YA',
			displayName: '{period}WM EQ Units Value of Share Pt Chg vs 2YA',
			key: 'WM EQ Units Value of Share Pt Chg vs 2YA',
			field: 'eqUnitsValueOfSharePTChgTwoYA_{period}Week',
			valuebase: 'EQ',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		}
	],
	// WM Sales
	[
		{
			gkey: 'WM Sales',
			name: '{period}WM $ Sales',
			displayName: '{period}WM $ Sales',
			key: 'WM $ Sales',
			field: 'dollarWalmartSales_{period}Week',
			valuebase: 'dollar',
			isSelected: false,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Sales',
			name: '{period}WM Units Sales',
			displayName: '{period}WM Units',
			key: 'WM Units',
			field: 'unitsWalmartSales_{period}Week',
			valuebase: 'units',
			isSelected: true,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Sales',
			name: '{period}WM EQ Units Sales',
			displayName: '{period}WM EQ Units',
			key: 'WM EQ Units',
			field: 'eqUnitsWalmartSales_{period}Week',
			valuebase: 'EQ',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		}
	],
	// WM Sales % Chg vs YA
	[
		{
			gkey: 'WM Sales % Chg vs YA',
			name: '{period}WM $ Sales % Chg vs YA',
			displayName: '{period}WM $ Sales % Chg vs YA',
			key: 'WM $ Sales % Chg vs YA',
			field: 'wmDollarSalesPctChgYA_{period}Week',
			valuebase: 'dollar',
			isSelected: false,
			cellFilter: "zip:'perc'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Sales % Chg vs YA',
			name: '{period}WM Unit Sales % Chg vs YA',
			displayName: '{period}WM Units % Chg vs YA',
			key: 'WM Units % Chg vs YA',
			field: 'wmUnitSalesPctChgYA_{period}Week',
			valuebase: 'units',
			isSelected: true,
			cellFilter: "zip:'perc'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Sales % Chg vs YA',
			name: '{period}WM EQ Unit Sales % Chg vs YA',
			displayName: '{period}WM EQ Units % Chg vs YA',
			key: 'WM EQ Units % Chg vs YA',
			field: 'wmEqUnitsalesPctChgYA_{period}Week',
			valuebase: 'EQ',
			isSelected: false,
			cellFilter: "zip:'perc'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		}
	],
	// WM Sales % Chg vs 2YA
	[
		{
			gkey: 'WM Sales % Chg vs 2YA',
			name: '{period}WM $ Sales % Chg vs 2YA',
			displayName: '{period}WM $ Sales % Chg vs 2YA',
			key: 'WM $ Sales % Chg vs 2YA',
			field: 'wmDollarSalesPctChgTwoYA_{period}Week',
			valuebase: 'dollar',
			isSelected: false,
			cellFilter: "zip:'perc'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Sales % Chg vs 2YA',
			name: '{period}WM Unit Sales % Chg vs 2YA',
			displayName: '{period}WM Units % Chg vs 2YA',
			key: 'WM Units % Chg vs 2YA',
			field: 'wmUnitSalesPctChgTwoYA_{period}Week',
			valuebase: 'units',
			isSelected: true,
			cellFilter: "zip:'perc'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Sales % Chg vs 2YA',
			name: '{period}WM EQ Unit Sales % Chg vs 2YA',
			displayName: '{period}WM EQ Units % Chg vs 2YA',
			key: 'WM EQ Units % Chg vs 2YA',
			field: 'wmEqUnitsalesPctChgTwoYA_{period}Week',
			valuebase: 'EQ',
			isSelected: false,
			cellFilter: "zip:'perc'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		}
	],
	// WM Sales Actual Chg vs YA
	[
		{
			gkey: 'WM Sales Actual Chg vs YA',
			name: '{period}WM $ Sales Actual Chg vs YA',
			displayName: '{period}WM $ Sales Actual Chg vs YA',
			key: 'WM $ Sales Actual Chg vs YA',
			field: 'wmDollarSalesActualChgYA_{period}Week',
			valuebase: 'dollar',
			isSelected: false,
			cellFilter: "zip:'dollar'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Sales Actual Chg vs YA',
			name: '{period}WM Unit Sales Actual Chg vs YA',
			displayName: '{period}WM Units Actual Chg vs YA',
			key: 'WM Units Actual Chg vs YA',
			field: 'wmUnitSalesActualChgYA_{period}Week',
			valuebase: 'units',
			isSelected: true,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Sales Actual Chg vs YA',
			name: '{period}WM EQ Unit Sales Actual Chg vs YA',
			displayName: '{period}WM EQ Units Actual Chg vs YA',
			key: 'WM EQ Units Actual Chg vs YA',
			field: 'wmEqUnitsalesActualChgYA_{period}Week',
			valuebase: 'EQ',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		}
	],
	// WM Sales Actual Chg vs 2YA
	[
		{
			gkey: 'WM Sales Actual Chg vs 2YA',
			name: '{period}WM $ Sales Actual Chg vs 2YA',
			displayName: '{period}WM $ Sales Actual Chg vs 2YA',
			key: 'WM $ Sales Actual Chg vs 2YA',
			field: 'wmDollarSalesActualChgTwoYA_{period}Week',
			valuebase: 'dollar',
			isSelected: false,
			cellFilter: "zip:'dollar'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Sales Actual Chg vs 2YA',
			name: '{period}WM Unit Sales Actual Chg vs 2YA',
			displayName: '{period}WM Units Actual Chg vs 2YA',
			key: 'WM Units Actual Chg vs 2YA',
			field: 'wmUnitSalesActualChgTwoYA_{period}Week',
			valuebase: 'units',
			isSelected: true,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Sales Actual Chg vs 2YA',
			name: '{period}WM EQ Unit Sales Actual Chg vs 2YA',
			displayName: '{period}WM EQ Units Actual Chg vs 2YA',
			key: 'WM EQ Units Actual Chg vs 2YA',
			field: 'wmEqUnitsalesActualChgTwoYA_{period}Week',
			valuebase: 'EQ',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		}
	],
	// WM Contribution to Total
	[
		{
			gkey: 'WM Contribution to Total',
			name: '{period}WM $ Contribution to Total',
			displayName: '{period} WM $ Contribution',
			key: 'WM $ Contribution',
			field: 'dollarContributionToTotal_{period}Week',
			valuebase: 'dollar',
			isSelected: false,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Contribution to Total',
			name: '{period}WM Units Contribution to Total',
			displayName: '{period}WM Units Contribution',
			key: 'WM Units Contribution',
			field: 'unitsContributionToTotal_{period}Week',
			valuebase: 'units',
			isSelected: true,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Contribution to Total',
			name: '{period}WM EQ Units Contribution to Total',
			displayName: '{period}WM EQ Units Contribution',
			key: 'WM EQ Units Contribution',
			field: 'eqUnitsContributionToTotal_{period}Week',
			valuebase: 'EQ',
			isSelected: false,
			cellFilter: "zip:'perc'",
			cellClass: 'special',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		}
	],
	// WM Fair Share Opportunity
	[
		{
			gkey: 'WM Fair Share Opportunity',
			name: '{period}$ Fair Share Opportunity',
			displayName: '{period} WM $ Fair Share Opportunity',
			key: 'WM $ Fair Share Opportunity',
			field: 'dollarFairShareOpportunity_{period}Week',
			valuebase: 'dollar',
			isSelected: false,
			cellClass: 'showColor',
			cellFilter: "zip:'dollar'",
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Fair Share Opportunity',
			name: '{period}Units Fair Share Opportunity',
			displayName: '{period}WM Units Fair Share Opportunity',
			key: 'WM Units Fair Share Opportunity',
			field: 'unitsFairShareOpportunity_{period}Week',
			valuebase: 'units',
			isSelected: true,
			cellClass: 'showColor',
			cellFilter: "zip:'roundednumber'",
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Fair Share Opportunity',
			name: '{period}EQ Units Fair Share Opportunity',
			displayName: '{period}WM EQ Units Fair Share Opportunity',
			key: 'WM EQ Units Fair Share Opportunity',
			field: 'eqUnitsFairShareOpportunity_{period}Week',
			valuebase: 'EQ',
			isSelected: false,
			cellClass: 'showColor',
			cellFilter: "zip:'roundednumber'",
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		}
	],
	// RM Sales
	[
		{
			gkey: 'RM Sales',
			name: '{period}RM $ Sales',
			displayName: '{period}RM $ Sales',
			key: 'RM $ Sales',
			field: 'dollarRemainingSales_{period}Week',
			valuebase: 'dollar',
			isSelected: false,
			cellFilter: "zip:'dollar'",
			cellClass: 'special',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'RM Sales',
			name: '{period}RM Units Sales',
			displayName: '{period}RM Units',
			key: 'RM Units',
			field: 'unitsRemainingSales_{period}Week',
			valuebase: 'units',
			isSelected: true,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'RM Sales',
			name: '{period}RM EQ Units Sales',
			displayName: '{period}RM EQ Units',
			key: 'RM EQ Units',
			field: 'eqUnitsRemainingSales_{period}Week',
			valuebase: 'EQ',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'special',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		}
	],
	// Sales % Chg vs YA
	[
		{
			gkey: 'RM Sales % Chg vs YA',
			name: '{period}RM $ Sales % Chg vs YA',
			displayName: '{period}RM $ Sales % Chg vs YA',
			key: 'RM $ Sales % Chg vs YA',
			field: 'rmDollarSalesPctChgYA_{period}Week',
			valuebase: 'dollar',
			isSelected: false,
			cellFilter: "zip:'perc'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'RM Sales % Chg vs YA',
			name: '{period}RM Unit Sales % Chg vs YA',
			displayName: '{period}RM Units % Chg vs YA',
			key: 'RM Units % Chg vs YA',
			field: 'rmUnitSalesPctChgYA_{period}Week',
			valuebase: 'units',
			isSelected: true,
			cellFilter: "zip:'perc'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'RM Sales % Chg vs YA',
			name: '{period}RM EQ Unit Sales % Chg vs YA',
			displayName: '{period}RM EQ Units % Chg vs YA',
			key: 'RM EQ Units % Chg vs YA',
			field: 'rmEqUnitsalesPctChgYA_{period}Week',
			valuebase: 'EQ',
			isSelected: false,
			cellFilter: "zip:'perc'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		}
	],
	// Sales % Chg vs 2YA
	[
		{
			gkey: 'RM Sales % Chg vs 2YA',
			name: '{period}RM $ Sales % Chg vs 2YA',
			displayName: '{period}RM $ Sales % Chg vs 2YA',
			key: 'RM $ Sales % Chg vs 2YA',
			field: 'rmDollarSalesPctChgTwoYA_{period}Week',
			valuebase: 'dollar',
			isSelected: false,
			cellFilter: "zip:'perc'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'RM Sales % Chg vs 2YA',
			name: '{period}RM Unit Sales % Chg vs 2YA',
			displayName: '{period}RM Units % Chg vs 2YA',
			key: 'RM Units % Chg vs 2YA',
			field: 'rmUnitSalesPctChgTwoYA_{period}Week',
			valuebase: 'units',
			isSelected: true,
			cellFilter: "zip:'perc'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'RM Sales % Chg vs 2YA',
			name: '{period}RM EQ Sales % Chg vs 2YA',
			displayName: '{period}RM EQ Units % Chg vs 2YA',
			key: 'RM EQ Units % Chg vs 2YA',
			field: 'rmEqUnitsalesPctChgTwoYA_{period}Week',
			valuebase: 'EQ',
			isSelected: false,
			cellFilter: "zip:'perc'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		}
	],
	// Sales Actual Chg vs YA
	[
		{
			gkey: 'RM Sales Actual Chg vs YA',
			name: '{period}RM $ Sales Actual Chg vs YA',
			displayName: '{period}RM $ Sales Actual Chg vs YA',
			key: 'RM $ Sales Actual Chg vs YA',
			field: 'rmDollarSalesActualChgYA_{period}Week',
			valuebase: 'dollar',
			isSelected: false,
			cellFilter: "zip:'dollar'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'RM Sales Actual Chg vs YA',
			name: '{period}RM Unit Sales Actual Chg vs YA',
			displayName: '{period}RM Units Actual Chg vs YA',
			key: 'RM Units Actual Chg vs YA',
			field: 'rmUnitSalesActualChgYA_{period}Week',
			valuebase: 'units',
			isSelected: true,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'RM Sales Actual Chg vs YA',
			name: '{period}RM EQ Unit Sales Actual Chg vs YA',
			displayName: '{period}RM EQ Units Actual Chg vs YA',
			key: 'RM EQ Units Actual Chg vs YA',
			field: 'rmEqUnitsalesActualChgYA_{period}Week',
			valuebase: 'EQ',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		}
	],
	//Sales Actual Chg vs 2YA
	[
		{
			gkey: 'RM Sales Actual Chg vs 2YA',
			name: '{period}RM $ Sales Actual Chg vs 2YA',
			displayName: '{period}RM $ Sales Actual Chg vs 2YA',
			key: 'RM $ Sales Actual Chg vs 2YA',
			field: 'rmDollarSalesActualChgTwoYA_{period}Week',
			valuebase: 'dollar',
			isSelected: false,
			cellFilter: "zip:'dollar'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'RM Sales Actual Chg vs 2YA',
			name: '{period}RM Unit Sales Actual Chg vs 2YA',
			displayName: '{period}RM Units Actual Chg vs 2YA',
			key: 'RM Units Actual Chg vs 2YA',
			field: 'rmUnitSalesActualChgTwoYA_{period}Week',
			valuebase: 'units',
			isSelected: true,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'RM Sales Actual Chg vs 2YA',
			name: '{period}RM EQ Unit Sales Actual Chg vs 2YA',
			displayName: '{period}RM EQ Units Actual Chg vs 2YA',
			key: 'RM EQ Units Actual Chg vs 2YA',
			field: 'rmEqUnitsalesActualChgTwoYA{period}Week',
			valuebase: 'EQ',
			isSelected: false,
			cellFilter: "zip:'roundednumber'",
			cellClass: 'showColor',
			isPeriodHide: false,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		}
	]
];

export const PERIOD_LIST = [
	{
		key: '4weeks',
		gkey: 4,
		value: 'Last_4_Weeks',
		isSelected: true,
		name: '4 Week'
	},
	{
		key: '13weeks',
		gkey: 13,
		value: 'Last_13_Weeks',
		isSelected: true,
		name: '13 Week'
	},
	{
		key: '52weeks',
		gkey: 52,
		value: 'Last_52_Weeks',
		isSelected: true,
		name: '52 Week'
	}
];

export const MEASURES = [
	{
		key: 'DOLLAR',
		value: '$'
	},
	{
		key: 'UNITS',
		value: 'Units'
	},
	{
		key: 'EQ',
		value: 'EQ'
	}
];

export const FACTLOCALPROMPTVALUES = {
	DOLLAR: [
		{
			key: 'wmDollarSharePTChgBpsYA',
			value: 'WM $ Share Pt Chg (BPS) vs YA'
		},
		{
			key: 'wmDollarSharePTChgBpsTwoYA',
			value: 'WM $ Share Pt Chg (BPS) vs 2YA'
		}
	],
	UNITS: [
		{
			key: 'wmUnitsSharePTChgBpsYA',
			value: 'WM Units Share Pt Chg (BPS) vs YA'
		},
		{
			key: 'wmUnitsSharePTChgBpsTwoYA',
			value: 'WM Units Share Pt Chg (BPS) vs 2YA'
		}
	],
	EQ: [
		{
			key: 'wmEqUnitsSharePTChgBpsYA',
			value: 'WM EQ Units Share Pt Chg (BPS) vs YA'
		},
		{
			key: 'wmEqUnitsSharePTChgBpsTwoYA',
			value: 'WM EQ Units Share Pt Chg (BPS) vs 2YA'
		}
	]
};
